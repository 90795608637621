import * as baseUrlActions from '../actions/baseUrl';
import { handleActions } from "redux-actions";

const initialState =
{
    signInLoading: false,
};

function signInLoading(state, { payload })
{
    state.signInLoading = payload;
    return { ...state };
}

export default handleActions({
    [baseUrlActions.signInLoading]: signInLoading,
}, initialState);
