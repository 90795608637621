export const permittedCategories = (permitCatType) =>
{
    const permittedCategories  = [];
    const categories           = window.__categories;

    categories.filter((category) =>
    {
        if((permitCatType === "ASSEMBLY") || (permitCatType === "PART"  && category.type !== "ASSEMBLY" ))
        {
            permittedCategories.push(category.name);
        }
    });
    return permittedCategories;
}

export const assemblyCategories = () =>
{
    const categories = window.__categories;

    let assemblyTypeCategories      = filterCategories(categories, "ASSEMBLY")
    let mechanicalTypeCategories    = filterCategories(categories, "MECHANICAL")
    let electricalTypeCategories    = filterCategories(categories, "ELECTRICAL")
    let documentTypeCategories      = filterCategories(categories, "DOCUMENT")
    let softwareTypeCategories      = filterCategories(categories, "SOFTWARE")

    return assemblyTypeCategories.concat(
        mechanicalTypeCategories,
        electricalTypeCategories,
        documentTypeCategories,
        softwareTypeCategories
    );
}

export const partCategories     = () =>
{

    const categories              = window.__categories;
    const categoriesForAssembly   = categories.filter((category) =>
                                    {
                                        if(category.type !== "ASSEMBLY")
                                        {
                                            return category;
                                        }
                                    });
    return categoriesForAssembly;
}

export const findByName = (name) =>
{
    const categories    = window.__categories;
    const category      = categories.filter((category) =>
                        {
                            if(category.name === name)
                            {
                                return category;
                            }
                        });
    return category[0] ? category[0] : null;
}

const filterCategories = (categories, categoryType) =>
{
    let filteredcategories = categories.filter((category) =>
    {
        if(category.type === categoryType)
        {
            return category;
        }
    });

    setCategoryGroupHeading(filteredcategories, categoryType)
    return filteredcategories
}

const setCategoryGroupHeading = (categorGroup, categoryHeading) =>
{
    if (categorGroup.length > 0)
    {
        categorGroup.unshift({name:"", type:"HEADING"});
        categorGroup.unshift({name:`- - ${categoryHeading} - -`, type:"HEADING"});
        categorGroup.unshift({name:"", type:"HEADING"});
    }
}
