import './index.scss';
import React from 'react';
import { Link } from 'react-router-dom';

const RouterLink = (props) => {
    let className = `ui-link ${props.className}`;
    if (props.skipUILink && props.skipUILink === true) {
        className = props.className;
    }
    const markup = (
        <Link to={props.to} className={className} target={props.target} onClick={props.onClick}>
            {props.children}
        </Link>
    );
    return markup;
};

export default RouterLink;
