import                               './index.scss';
import React, { Component }     from 'react';
import InlineIcon               from '../icon/inline-icon.jsx';
import BulkUpdateStatusIcon from '../../../assets/icons/bulk-update-status';

const LeftSideBarHeader = ((props) =>
{
    let {isPullPartBtnDisabled, togglePullRequestModal, isWarningModalVisible, bulkStatusUpdateBtn, showPullRequestModal, toggleUpdateStatusModal} = props;
    return (
                <div className='top-tag-bar'>
                    <ul>
                        <li className={`pull-part-btn `}>
                            <button
                                disabled={isPullPartBtnDisabled}
                                onClick={togglePullRequestModal}
                                className={`${showPullRequestModal ? 'is-modal-visible' : isPullPartBtnDisabled || isWarningModalVisible ? 'disabled' : ''}`}
                            >
                                # Pull Part Number
                            </button>
                        </li>
                        <li className={`update-status-btn `}>
                            <InlineIcon className={bulkStatusUpdateBtn.class}>
                                <BulkUpdateStatusIcon/>
                            </InlineIcon>
                            <button
                                disabled={bulkStatusUpdateBtn.class === 'disabled' ? true : false}
                                onClick={toggleUpdateStatusModal}
                                className={bulkStatusUpdateBtn.class}
                            >
                                 Update Status
                            </button>
                        </li>
                    </ul>
                </div>
           );
});

export default LeftSideBarHeader;
