import { put, call, takeLatest, cps, all, cancelled, select }         from 'redux-saga/effects';
import { getComponent,
         updateComponentMetadata,
         getCategories,
         sendPullRequest,
         NameExistsBulk,
         getComponentsInBulk,
         updateComponentsInBulk,
         calculateMass
        }                                                             from '../services/componentService';
import { authRefreshtokenOnshapeService }                             from '../services/authenticationService';
import * as componentActions                                          from '../actions/component'
import * as oauthActions                                              from '../actions/oauth'
import { getInputs, getComponentDuro, getActualComponentDuro, getQueryParams}         from './selectors/component-selectors'
import validations, { validateField, schemas, normalizeValue }        from '../modules/validations'
import * as documentActions                                           from '../actions/document';

export function* componentSaga({payload})
{
  try
  {
    let queryParams = yield select(getQueryParams);
    if(payload.successCb)
    {
        yield cps(getComponent, payload, queryParams, (error, response) => {
            if(error)
            {
                payload.successCb(error);
            }
            else
            {
                payload.successCb(null, response);
            }
        });
    }
    else
    {
        yield put(componentActions.resetState());
        yield put(componentActions.isLoading(true));
        if(payload.isRedirecting)
        {
            yield put(documentActions.setDocumentLoading(true));
        }
        const response = yield cps(getComponent, payload, queryParams);
        if (response.error === "invalid_token" && response.error_description.indexOf("Invalid access token") !== -1 )
        {
          yield put(oauthActions.getOnshapeRefreshToken(componentActions.getComponent().type));
        }
        else
        {
            if (response.error && response.error !== "invalid_token")
            {
                yield put(componentActions.componentError(response))
            }
            else
            {
                if(payload.isRedirecting && response && response.onShapeData && response.onShapeData.thumbnail) 
                {
                    yield put(documentActions.updateDocumentImage(response.onShapeData.thumbnail));
                }
                yield put(componentActions.componentSucess(response))
            }
        }
    }

  }
  catch(error)
  {
    // it instructs the middleware to dispatch an action to the Store
        yield put(componentActions.getDataError(error))
  }
}

export function* updateComponent({ payload })
{

    try
    {
        yield put(componentActions.isLoading(true));
        // const response = yield cps(updateComponentMetadata, elementId, partId, items);
        yield cps(updateComponentMetadata, payload, (err, response) =>
        {
            if(err)
            {
                payload.successCb(err.data);
            }
            else
            {
                payload.successCb();
            }
        });
        // yield cps(updateComponentMetadata, payload);

    }
    catch(error)
    {
    // it instructs the middleware to dispatch an action to the Store
        yield put(componentActions.getDataError(error))
    }
}

export function* updateEditFormInputState({ payload })
{
  try
  {
    let ComponentDuro = yield select(getComponentDuro)
    let inputs        = yield select(getInputs)
    let statusesWithIntegerRevision    = ["DESIGN", "PROTOTYPE"];
    let statusesWithNonIntegerRevision = ["PRODUCTION", "OBSOLETE"];
    let actualComponentDuro            = yield select(getActualComponentDuro);
    if(ComponentDuro)
    {
      switch(payload.name)
      {
        case 'eid':
          validateField(inputs.eid, validations.component.eid, payload.value)
          ComponentDuro.eid  = payload.value
        break

        case 'revision':

          validateField(inputs.revision, validations.component.revision, payload.value.toUpperCase(), {status: inputs.status.value})
          ComponentDuro.revision  = payload.value.toUpperCase();
        break

        case "spec" :
          let input   = inputs.specs[payload.index]
          let categoryObject  = inputs.category.value && inputs.category.value ? schemas.categories.findByName2(inputs.category.value, window.__categories) : []
          validateField(input, validations.component.specs.value, payload.value, {categoryObject: categoryObject, key: input.key})
          ComponentDuro.specs[payload.index].value = payload.value
        break

        case "status" :
            let value              = payload.value;
            inputs.status.value    = value;
            if  (
            statusesWithIntegerRevision.includes(value) &&
            statusesWithIntegerRevision.includes(ComponentDuro.status) &&
            inputs.category.value === ComponentDuro.category ||
            statusesWithNonIntegerRevision.includes(value) &&
            statusesWithNonIntegerRevision.includes(ComponentDuro.status) &&
            inputs.category.value === ComponentDuro.category
            )
            {
                inputs.revision.value = actualComponentDuro.revision;
            }
            else
            {
                inputs.revision.value = validations.component.revision.normalize({status: inputs.status.value}, inputs.revision.value).revision;
            }
            validateField(inputs.revision, validations.component.revision, inputs.revision.value, {status: inputs.status.value});
            ComponentDuro.revision = inputs.revision.value;
            ComponentDuro.status   = value;
        break

        case "description" :
          input                     = inputs.description
          validateField(input, validations.component.description, payload.value)
          ComponentDuro.description = payload.value
        break

        case "procurement" :
          input                     = inputs.procurement;
          ComponentDuro.procurement = payload.value;
        break
      }
      yield put(componentActions.updateEditComponentFormFields({ComponentDuro}));
    }
  }
  catch(error)
  {
    alert(error)
  }
}

export function* setCategories()
{
    try
    {
        yield cps(getCategories, (error, response) =>
        {
            if(response)
            {
                response.sort(function(catA, catB)
                {
                    if(isNaN(catB.code.toLowerCase())) return 0;
                    if(catA.name < catB.name) { return -1; }
                    if(catA.name > catB.name) { return 1; }
                    return 0;
                })
                window.__categories = response;
            }
        });
    }
    catch(error)
    {
        alert(error);
    }
}

export function* createPullRequest({ payload })
{
    try
    {
        yield cps(sendPullRequest, payload, (error, response) =>
        {
            payload.successCb(error || response);
        });
    }
    catch(error)
    {
        cb(error);
    }
}

export function* isNameExistsBulk({ payload })
{
    try
    {
        yield cps(NameExistsBulk, payload, (error, response) =>
        {
            payload.successCb(response);
        });
    }
    catch(error)
    {
        cb(error);
    }
}

export function* getCmpsInBulk({ payload })
{
    try
    {
        yield cps(getComponentsInBulk, payload, (error, response) =>
        {
            if(error)
            {
                payload.successCb(error);
            }
            else
            {
                payload.successCb(null, response);
            }
        });
    }
    catch(error)
    {
        cb(error);
    }
}

export function* updateCmpsInBulk({ payload })
{
    try
    {
        let queryParams = yield select(getQueryParams);
        payload.workspaceId = queryParams.workspaceId;
        yield cps(updateComponentsInBulk, payload, (error, response) =>
        {
            if(error)
            {
                payload.successCb(error);
            }
            else
            {
                payload.successCb(null, response);
            }
        });
    }
    catch(error)
    {
        cb(error);
    }
}

export function* getMassValue({ payload })
{
    try
    {
        if(!payload.workspaceId)
        {
            let queryParams = yield select(getQueryParams);
            payload.workspaceId = queryParams.workspaceId;
        }
        yield cps(calculateMass, payload, (error, response) =>
        {
            if(error)
            {
                payload.successCb(error);
            }
            else
            {
                payload.successCb(null, response);
            }
        });
    }
    catch(error)
    {
        cb(error);
    }
}

export default function* watchAuthProcess()
{
  yield takeLatest(componentActions.getComponent().type, componentSaga);
  yield takeLatest(componentActions.updateComponentMetadata().type, updateComponent);
  yield takeLatest(componentActions.updateEditFormInputState().type, updateEditFormInputState);
  yield takeLatest(componentActions.setCategories().type, setCategories);
  yield takeLatest(componentActions.createPullRequest().type, createPullRequest);
  yield takeLatest(componentActions.isNameExistsBulk().type, isNameExistsBulk);
  yield takeLatest(componentActions.getCmpsInBulk().type, getCmpsInBulk);
  yield takeLatest(componentActions.updateCmpsInBulk().type, updateCmpsInBulk);
  yield takeLatest(componentActions.getMassValue().type, getMassValue);
}
