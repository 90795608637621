import React, { Component }     from 'react';
import {connect}                from 'react-redux';
import ItemAssembly             from '../item-assembly/index.jsx';
import                               './index.scss';
import * as DURO_ACTIONS        from '../../../actions/duro';
import * as componentActions    from '../../../actions/component';
import AssembliesDropDown       from '../assemblies-dropdown';
import { generateKey, getWorkSpaceId, filterAssembliesData, extractConfiguration, extractCpn, getConfigurationString, getParsedItemFromSessionStorage, setItemInSessionStorage} from '../../../helpers/components-helper';
import * as assemblyActions     from '../../../actions/assemblies';
import RefreshAssemblyButton    from '../refresh-assembly-button';
import WarningModalBox          from "../warning-modal-box/index.jsx";
import ModalBox                 from "../modal-box/index.js";
import InlineIcon               from '../icon/inline-icon.jsx';
import BeingSyncedIconSrc       from '../../../assets/icons/being-synced-icon';
import SyncedIconSrc            from '../../../assets/icons/synced-icon';
import WarningIconSrc           from '../../../assets/icons/warning-icon';
import ReactTooltip             from 'react-tooltip';
import * as documentActions     from '../../../actions/document';
import * as duroActions         from '../../../actions/duro';
import Utils from '../../../utils';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { consts } from '../../../utils/consts';

class ItemsList extends Component
{
     constructor(props, context)
     {
        super(props, context);
        this.createListForAssembly        = this.createListForAssembly.bind(this);
        this.selectAssembly               = this.selectAssembly.bind(this);
        this.unsetSelectedTextForAssemblyList = this.unsetSelectedTextForAssemblyList.bind(this);
        this.setAssemblyRefreshFlags      = this.setAssemblyRefreshFlags.bind(this);
        this.afterRefreshAssembly         = this.afterRefreshAssembly.bind(this);
        this.hideRefreshModal             = this.hideRefreshModal.bind(this);
        this.hideWebhookModal             = this.hideWebhookModal.bind(this);
        this.afterRefreshDocument         = this.afterRefreshDocument.bind(this);
        this.getAssemblyDefination        = this.getAssemblyDefination.bind(this);
        this.handleNewUpdate              = this.handleNewUpdate.bind(this);
        this.getTotalCount                = this.getTotalCount.bind(this);
        this.getPartsInPartStudio         = this.getPartsInPartStudio.bind(this);
        this.setMyState                   = this.setMyState.bind(this);
        this.getInitialElementId          = this.getInitialElementId.bind(this);
        this.setPendingUpdated            = this.setPendingUpdated.bind(this);
        this.setDocumentAssemblies        = this.setDocumentAssemblies.bind(this);
        this.bindPusherEvents             = this.bindPusherEvents.bind(this);
        this.unbindPusherEvents           = this.unbindPusherEvents.bind(this);
        this.state =
        {
            parts                       : this.props.duroReducer.parts || [],
            assemblies                  : this.props.duroReducer.assemblies || [],
            selectedTextForAssemblyList : {value: '', configurationString: ''},
            refreshAssembly             : false,
            refreshDocument             : false,
            refreshButtonClass          : '',
            refreshModal                : { headerText: 'Checking Status', bodyText: 'Please wait...', visibility: false, displayBtn: false },
            assemblyInstances           : null,
            subAssemblies               : null,
            rootAssembly                : null,
            parts                       : null,
            elementId                   : this.getInitialElementId(),
            mainAssembly                : null,
            listItems                   : [],
            assemblyInnerLoading        : false,
            bulkUpdate                  : false,
            totalCount                  : 0,
            partStudio                  : null,
            shouldUpdateDropDown        : false,
            isRefreshing                : false,
            fetchingProperties          : [],
            pendingUpdates              : false,
            checkForPullPart            : false,
            syncingElements             : {},
        };
        this.pusher = this.props.duroReducer.pusher;
    }

    // this method will be called in constructor to set the initial state of the main element Id
    getInitialElementId()
    {
      let {duroReducer}      = this.props;
      let lastOpenedAssembly = getParsedItemFromSessionStorage('lastOpenedAssembly');
      let assemblies         = duroReducer.assemblies;
      let queryParams        = duroReducer.queryParams;
      //check if element to be loaded is available on Onshape or not
      let exists =  assemblies && lastOpenedAssembly && assemblies.find(assembly => assembly.id === lastOpenedAssembly.elementId);
      if(queryParams && lastOpenedAssembly && queryParams.documentId === lastOpenedAssembly.documentId && exists)
      {
        return lastOpenedAssembly.elementId;
      }
      else
      {
        return assemblies && assemblies[0].id || null;
      }
    }

    shouldComponentUpdate(nextProps)
    {
        if(this.props.rightSidebarOnShapeData !== nextProps.rightSidebarOnShapeData)
        {
            return false;
        }
        return true;
    }

    bindPusherEvents() {
        // get the channel
        if (!this.pusher) return;
        const channel = this.pusher.channel(consts.pusher.channelName);
        if (!channel) return;

        // bind the sync start event
        channel.bind(consts.pusher.events.syncStart, ({documentId, elementId}) => {
            const itemKey = Utils.serializeElementKey({documentId, elementId});
            this.setState(({syncingElements}) => ({
                syncingElements: {
                    ...syncingElements,
                    [itemKey]: true,
                },
            }));
        });

        // bind the sync end event
        channel.bind(consts.pusher.events.syncEnd, ({documentId, elementId}) => {
            const itemKey = Utils.serializeElementKey({documentId, elementId});
            this.setState(({syncingElements}) => ({
                syncingElements: {
                    ...syncingElements,
                    [itemKey]: false,
                },
            }));
        });

        // bind the webhook registration success event
        channel.bind(consts.pusher.events.webhookRegistered, message => {
            this.props.dispatch(duroActions.setWebhookModal({
                icon: <SyncedIconSrc/>,
                visibility: true,
                headerText: "Onshape Connection Restored",
                bodyText: "The connection to Onshape has been restored. Releases will be synced now.",
                displayBtn: true,
             }));
        });

        // bind the webhook registration failed event
        channel.bind(consts.pusher.events.webhookRegistrationFailed, message => {
            this.props.dispatch(duroActions.setWebhookModal({
                icon: <WarningIconSrc/>,
                visibility: true,
                headerText: "Onshape Connection Broken",
                bodyText: Utils.getWebhookModalBodyText(message.cause),
                displayBtn: true,
             }));
        });
    }

    unbindPusherEvents() {
        // get the channel
        if (!this.pusher) return;
        const channel = this.pusher.channel(consts.pusher.channelName);
        if (!channel) return;

        // unbind sync events
        channel.unbind(consts.pusher.events.syncStart);
        channel.unbind(consts.pusher.events.syncEnd);

        // unbind webhook event
        channel.unbind(consts.pusher.events.webhookRegistrationFailed);
    }

    componentDidMount()
    {
        this.bindPusherEvents();

        let {elementId}        = this.state;
        let lastOpenedAssembly = getParsedItemFromSessionStorage('lastOpenedAssembly');
        const searchParams     = this.props.duroReducer.queryParams;
        let type               = 'ASSEMBLY';
        let configuration      = null;
        let configurationString= null;
        let assemblies         = this.props.duroReducer && this.props.duroReducer.assemblies;
        if(assemblies)
        {
            for(let assembly of assemblies)
            {
                if(lastOpenedAssembly && assembly.id === lastOpenedAssembly.elementId)
                {
                    if(assembly.configurations)
                    {
                        let configurationToBeExtracted = lastOpenedAssembly.configuration || 'Default';
                        configuration       = configurationToBeExtracted;
                        configurationString = this.extractConfigurationString(assembly.configurations, configurationToBeExtracted);
                    }
                    type      = assembly.type;
                    elementId = assembly.id;
                }
                else if(assembly.id === elementId)
                {
                    let configurations = assembly.configurations;
                    if(configurations && configurations.length > 0)
                    {
                        let defaultConfiguration = extractConfiguration(configurations, 'Default');
                        if(!defaultConfiguration)
                        {
                            configuration = configurations[0].configuration;
                        }
                        else
                        {
                            configuration = defaultConfiguration.configuration;
                        }
                        configurationString = this.extractConfigurationString(assembly.configurations, configuration);
                    }
                    type      = assembly.type;
                    elementId = assembly.id;
                }
            }
        }
        lastOpenedAssembly = {elementId, documentId: searchParams.documentId, type, configuration, configurationString};
        setItemInSessionStorage('lastOpenedAssembly', lastOpenedAssembly);
        if(type.toLowerCase() === 'assembly')
        {
            this.getAssemblyDefination(elementId, false, null, configurationString);
        }
        else if(type.replace(/ /g, '').toLowerCase() === 'partstudio')
        {
            this.getPartsInPartStudio(lastOpenedAssembly, false, searchParams);
        }
    }

    componentWillUnmount() {
        this.unbindPusherEvents();
    }

    extractConfigurationString(configurations, configurationName)
    {
        let configurationToBeExtracted = configurationName;
        let extractedConfig            = extractConfiguration(configurations, configurationToBeExtracted);
        return extractedConfig ? extractedConfig.configurationString : '';
    }

    getPartsInPartStudio(lastOpenedAssembly, shouldResyncBom=false, searchParams=null,  cb=null)
    {
        if(!searchParams)
        {
            searchParams = this.props.duroReducer.queryParams;
        }
        if(!cb)
        {
            cb = this.setMyState;
        }
        this.props.dispatch(assemblyActions.getPartStudio({ partStudioId: lastOpenedAssembly.elementId , shouldResyncBom, workspaceId: searchParams.workspaceId, documentId: searchParams.documentId, successCb: cb}));
    }

    getAssemblyDefination(elementId, shouldResyncBom=false, cb=null, configurationString)
    {
        let {dispatch}   = this.props;
        if(!cb){
            cb = this.setMyState;
        }
        dispatch(DURO_ACTIONS.getAssemblyDefination({elementId, successCb: cb, shouldResyncBom, configurationString}));
    }


    needsToBeUpdated(prevList, currentList)
    {
        for(let item of currentList)
        {
            let exists =  prevList.find(assembly => assembly.name === item.name);
            if(!exists)
            {
                return true;
            }
        }

        for(let item of prevList)
        {
            let exists =  currentList.find(assembly => assembly.name === item.name);
            if(!exists)
            {
                return true;
            }
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState)
    {
        let {refreshModal, selectedTextForAssemblyList, shouldUpdateDropDown, assemblies, refreshAssembly} = this.state;
        let {duroReducer}  = this.props;
        let previousList   = this.createDropDownList(duroReducer.assemblies);
        let currentList    = this.createDropDownList(prevProps.duroReducer.assemblies);
        let needsToBeUpdated = this.needsToBeUpdated(previousList, currentList);
        if(refreshModal.visibility && selectedTextForAssemblyList.value && prevState.selectedTextForAssemblyList.value !== selectedTextForAssemblyList.value)
        {
             refreshModal = this.setRefreshModalState(true, true);
            this.setState({refreshModal, refreshAssembly: false, refreshDocument: false, listItems: this.createDropDownList(assemblies)});
        }
        else if(needsToBeUpdated)
        {
            this.setState({listItems: this.createDropDownList(duroReducer.assemblies), refreshAssembly: false, refreshDocument: false, assemblies: duroReducer.assemblies});
        }
        else if(shouldUpdateDropDown)
        {
            this.setState({listItems: this.createDropDownList(assemblies), refreshAssembly: false, refreshDocument: false, assemblies, shouldUpdateDropDown: false, shouldUseState: true});
        }
    }

    setMyState = (error, response, shouldResyncBom, configurationName = null) =>
    {
        let {assemblyInstances, subAssemblies, rootAssembly, parts, mainAssembly, selectedTextForAssemblyList, listItems,elementId, refreshModal, totalCount, partStudio, shouldUseState} = this.state;
        let {assemblies} = this.props.duroReducer;
        if(shouldUseState)
        {
            assemblies = this.state.assemblies;
        }
        if(response)
        {
            if (response.hasOwnProperty('isSyncing')) {
                const { documentId } = this.props.duroReducer.queryParams;
                const { elementId } = this.state;
                const itemKey = Utils.serializeElementKey({documentId, elementId});

                this.setState(({syncingElements}) => ({
                    syncingElements: {
                        ...syncingElements,
                        [itemKey]: response.isSyncing,
                    },
                }));
            }
            if(response.partStudioData)
            {
                let parts   = response.partStudioData;
                for(let item of parts)
                {
                    item.type = 'Part';
                    elementId = item.elementId;
                }
                partStudio   = parts;
                mainAssembly = null;
                let selectedAssembly        =  assemblies.find(assembly => assembly.id === elementId);

                //in-case if the current selected assembly is not found out of the assemblies that we have state, we need to load the first element.
                if(!selectedAssembly)
                {
                  selectedAssembly = assemblies[0];
                }

                for(let part of parts)
                {
                    part.configuration = part.fullConfiguration;
                }
                selectedTextForAssemblyList = {value: selectedAssembly.name, configurationString: ''};
            }
            else
            {

                assemblyInstances = response.rootAssembly.instances;
                subAssemblies     = response.subAssemblies;
                rootAssembly      = response.rootAssembly;
                parts             = response.parts;
                totalCount        = response.totalCount;
                let selectedAssembly   =  assemblies.find(assembly => assembly.id === rootAssembly.elementId);
                if(!selectedAssembly)
                {
                    selectedAssembly   =  assemblies.find(assembly => assembly.id === elementId);
                }
                if(selectedAssembly.configurations)
                {
                    let lastOpenedAssembly = getParsedItemFromSessionStorage('lastOpenedAssembly');
                    let lastOpenedConfiguration = configurationName || lastOpenedAssembly && lastOpenedAssembly.configuration && lastOpenedAssembly.configuration.toLowerCase() || 'default';
                    selectedAssembly       =  selectedAssembly.configurations.find(config => config.configuration.toLowerCase() === lastOpenedConfiguration);
                }
                const mainAssemblyObject = { name: selectedAssembly.name, elementId: selectedAssembly.id, type: 'Assembly', documentId: rootAssembly.documentId, componentDuro: rootAssembly.componentDuro, instances: rootAssembly.instances, configurationString: rootAssembly.fullConfiguration, configuration: rootAssembly.fullConfiguration };
                mainAssembly = this.createListForAssembly(mainAssemblyObject, assemblyInstances, subAssemblies);
                selectedTextForAssemblyList = {value: mainAssembly.name, configurationString: mainAssemblyObject.configurationString};
                partStudio = null;
            }
            let result = [];
            listItems = this.createDropDownList(assemblies);
            // if(selectedTextForAssemblyList.value === '')
            // {
            //     selectedTextForAssemblyList.value = mainAssembly.name;
            // }
            refreshModal = this.setRefreshModalState(refreshModal.visibility, true);
            localStorage.setItem('refreshAssembly', true);
            this.setState({assemblyInstances, subAssemblies, rootAssembly, parts, elementId, mainAssembly, selectedTextForAssemblyList, listItems, assemblyInnerLoading: false, refreshModal, refreshAssembly: false, refreshDocument: false, totalCount, partStudio, shouldUseState: false, assemblies}, () => {
                localStorage.setItem('refreshAssembly', false);
            });
        }
    }

    filterArrays(instances, subAssemblies)
    {
        let state = this.state;
        let parts = [];
        let assembly = [];
        if(instances && instances.length > 0)
        {
            instances.forEach((ins) =>
            {
                let isSuppressedOrExcluded = ((ins.excludeFromBom && ins.excludeFromBom.value) || ins.suppressed);
                if(ins.type === "Part" && !isSuppressedOrExcluded && ins.partId)
                {
                    parts.push(ins);
                }
                else if(ins.type === "Assembly" && !isSuppressedOrExcluded && ins.status !== 'DeletedElement')
                {
                    assembly.push(ins);
                }
                ins.configurationString = getConfigurationString(ins);
            });
        }
        return {assembly, parts};
    }

    selectAssembly(item)
    {
        ReactTooltip.hide();
        let {selectedTextForAssemblyList, assemblies} = this.state;
        let lastOpenedAssembly = getParsedItemFromSessionStorage('lastOpenedAssembly');
        let hasClickedOnSameElement = lastOpenedAssembly && lastOpenedAssembly.configuration && item.configuration && lastOpenedAssembly.configuration === item.configuration && item.id === lastOpenedAssembly.elementId;
        if(lastOpenedAssembly && item.id === lastOpenedAssembly.elementId && !item.configuration || hasClickedOnSameElement)
        {
            return;
        }
        const {dispatch, duroReducer} = this.props;
        const searchParams            = duroReducer.queryParams;
        let type       = 'ASSEMBLY';
        if(assemblies)
        {
            for(let assembly of assemblies)
            {
                if(assembly.id === item.id)
                {
                    if(item.configurationString && assembly.configurations)
                    {
                        for(let config of assembly.configurations)
                        {
                            if(item.configurationString === config.configurationString)
                            {
                                type = config.type;
                            }
                        }
                    }
                    else
                    {
                        type = assembly.elementType;
                    }
                }
            }
        }
        lastOpenedAssembly = {elementId: item.id, documentId: searchParams.documentId, type, configuration: item.configuration, configurationString: item.configurationString};
        setItemInSessionStorage('lastOpenedAssembly', lastOpenedAssembly);
        this.setState({selectedTextForAssemblyList: {value: item.name, configurationString: item.configurationString}, assemblyInnerLoading: true, shouldUseState: true, elementId: item.id}, () =>
        {
            const successCb = (err, response) =>
            {
                if(response)
                {
                    dispatch(componentActions.resetState());
                    if(response.onShapeData && response.onShapeData.thumbnail)
                    {
                      dispatch(documentActions.updateDocumentImage(response.onShapeData.thumbnail));
                    }
                    dispatch(componentActions.componentSucess(response));
                }
            };

            if(item.type.toLowerCase() === 'assembly')
            {
                dispatch(DURO_ACTIONS.getAssemblyDefination({ elementId: item.id, successCb: this.setMyState, setInnerLoading: true, configurationString: item.configurationString}));
                let payload = { elementId: item.id, isRedirecting: true, documentId: searchParams.documentId, workspaceId: searchParams.workspaceId, configurationString: item.configurationString, successCb};
                dispatch(assemblyActions.getAssemblyMetaData(payload));
            }
            else if(item.type.toLowerCase() === 'part studio')
            {
                this.getPartsInPartStudio(lastOpenedAssembly, false, searchParams);
            }
        });
    }

    setPartStudioAsSelected = (error, response) =>
    {
        if(response)
        {
            for(let item of response)
            {
                item.type = 'Part';
            }
            this.setState({assemblyInnerLoading: false, partStudio: response, mainAssembly: null});
        }
    }

    createListForAssembly(mainAssembly, assemblyInstances, subAssemblies)
    {
        const filteredArrays = this.filterArrays(assemblyInstances, subAssemblies);
        mainAssembly.nestedChildren = filteredArrays.parts.concat(filteredArrays.assembly);
        let cpnWithoutVariant = Utils.getCpnWithoutVariant(extractCpn(mainAssembly));
        let parent = {cpn: cpnWithoutVariant, configurationString: mainAssembly.configurationString, elementId: mainAssembly.elementId, documentId: mainAssembly.documentId};
        mainAssembly.nestedChildren.forEach((child) =>
        {
            child.parent = parent;
        });
        mainAssembly.listClass = "open";
        mainAssembly.iconClass = "open";
        mainAssembly.isChecked = false;
        return mainAssembly;
    }

    unsetSelectedTextForAssemblyList(assemblies)
    {
        this.setState({ selectedTextForAssemblyList: {value: '', configurationString: ''}, assemblies});
    }

    setAssemblyRefreshFlags(value)
    {
        ReactTooltip.hide();
        let {refreshModal, refreshDocument} = this.state;
        if(value)
        {
            refreshModal   = {headerText: 'Refresh in progress.', bodyText: 'Please wait...', visibility: true, displayBtn: false};
        }
        this.setState({refreshDocument: true, refreshModal, refreshButtonClass: 'disabled', isRefreshing: true, checkForPullPart: true, bulkUpdate: false});
    }

    setPendingUpdated(value){
        this.setState({pendingUpdates: value})
    }

    afterRefreshAssembly(list)
    {
        let {refreshModal, refreshDocument, assemblies, mainAssembly} = this.state;
        let _this = this;

        if(!refreshDocument)
        {
            refreshModal = this.setRefreshModalState(true, true);
        }

        let stateObj = {refreshAssembly: false, refreshDocument: false, refreshModal, mainAssembly: list, listItems: assemblies};

        if(!list)
        {
          stateObj.partStudio   = null;
          stateObj.refreshModal = {headerText: 'No Assemblies or Part Studios Found.', bodyText: 'Please add an Assembly or Part Studio in Onshape and then refresh the app.', visibility: true, displayBtn: true};
        }

        this.setState(stateObj, () => {
          _this.setDocumentAssemblies(assemblies);
        });
    }

    setDocumentAssemblies(assemblies)
    {
      if(assemblies && assemblies.length > 0)
      {
        this.props.dispatch(assemblyActions.documentAssembliesSuccess(assemblies));
      }
    }

    afterRefreshDocument(response, assemblyExist)
    {
        let {refreshAssembly, refreshDocument, assemblies} = this.state;
        assemblies = filterAssembliesData(response);
        if(!assemblyExist)
        {
            let _this = this;
            this.setState({refreshDocument: false, isRefreshing: false, assemblies}, () => {
                if(response && response.length)
                {
                  _this.props.dispatch(assemblyActions.documentAssembliesSuccess(response));
                }
                else
                {
                    //executing after refresh assembly to update the state of the refresh modal
                    this.afterRefreshAssembly(null);
                    _this.props.dispatch(componentActions.resetState());
                    _this.props.dispatch(documentActions.updateDocumentImage());
                }
            });
        }
        else
        {
            this.setState({refreshAssembly: true, refreshDocument: false, assemblies, listItems: this.createDropDownList(assemblies), shouldUpdateDropDown: true, isRefreshing: false, fetchingProperties: []});
        }
    }

    hideRefreshModal()
    {
        let {refreshModal}      = this.state;
        refreshModal.visibility = false;
        this.setState({refreshModal, refreshButtonClass: '', pendingUpdates: false, checkForPullPart: false});
    }

    hideWebhookModal()
    {
        this.props.dispatch(duroActions.setWebhookModal({ visibility: false }));
    }

    handleNewUpdate(check)
    {
        if(check === false) {
            this.setState({bulkUpdate: false})
            return;
        }
        else if(!this.state.checkForPullPart){
            this.setState({bulkUpdate: true})
        }
        else this.setState({bulkUpdate: false})
    }

    getTotalCount()
    {
        return this.state.totalCount;
    }

    createDropDownList(listItems)
    {
        let result = [];
        for(let item of listItems)
        {
            let exist = result.find((obj) => obj.name === item.name);
            if(!exist)
            {
                if(item.configurations)
                {
                    for(let config of item.configurations)
                    {
                        exist = result.find((obj) => obj.configurationString === config.configurationString);
                        if(!exist)
                        {
                            result.push(config);
                        }
                    }
                }
                else
                {
                    result.push(item);
                }
            }
        }
        return result;
    }

    assignConfigurationString(assemblies, mainAssembly)
    {
        if(assemblies && mainAssembly)
        {
            let selectedAssembly =  assemblies.find(assembly => assembly.id === mainAssembly.elementId && assembly.configurationString === mainAssembly.configurationString);
            if(selectedAssembly && selectedAssembly.configurationString)
            {
                mainAssembly.configurationString = selectedAssembly.configurationString;
            }
            else if(mainAssembly.type !== 'Part')
            {
                mainAssembly.configurationString = '';
            }
            if(mainAssembly.instances)
            {
                for(let instance of mainAssembly.instances)
                {
                    this.assignConfigurationString(assemblies, instance, true);
                }
            }
        }
    }

    setRefreshModalState(visibility=false ,displayBtn){
        return {headerText: 'Sync Process Initiated', bodyText: 'The latest changes have been pulled from Onshape and are in the process of being synced with Duro.', visibility , displayBtn};
    }

    render()
    {
        let {selectedTextForAssemblyList, refreshAssembly, refreshDocument, refreshModal, refreshButtonClass, assemblyInstances, mainAssembly, listItems, assemblyInnerLoading, partStudio, subAssemblies, elementId, parts, bulkUpdate, isRefreshing, fetchingProperties, pendingUpdates} =  this.state;
        const {icon: webhookModalIcon, visibility: webhookModalVisibility, ...webhookModal} = this.props.webhookModal;
        this.assignConfigurationString(listItems, mainAssembly);
        const {documentId} = this.props.duroReducer.queryParams;
        const isSyncing = this.state.syncingElements[Utils.serializeElementKey({documentId, elementId})];

        let markup =
            <div className="item-assembly-view" id="items-list-view">
                <div className="nav-list side-nav-holder">
                        {
                            (mainAssembly || partStudio) &&
                            (
                                <React.Fragment>
                                    {
                                      listItems && listItems.length > 0 &&
                                    <div>
                                        <div className={`custom-dropdown ${!isSyncing && "custom-dropdown-margin"}`}>
                                            <RefreshAssemblyButton
                                                pendingUpdates={pendingUpdates}
                                                setPendingUpdated={this.setPendingUpdated}
                                                dispatch={this.props.dispatch}
                                                setAssemblyRefreshFlags={this.setAssemblyRefreshFlags}
                                                refreshModal={refreshModal}
                                                refreshButtonClass={refreshButtonClass}
                                                queryParams={this.props.duroReducer.queryParams}
                                                handleNewUpdate={this.handleNewUpdate}
                                                listItems={parts}
                                                pusher={this.pusher}
                                                getAssemblyDefination={this.getAssemblyDefination}
                                                getTotalCount={this.getTotalCount}
                                                getPartsInPartStudio={this.getPartsInPartStudio}
                                            />

                                            <AssembliesDropDown
                                                dispatch={this.props.dispatch}
                                                history={this.props.history}
                                                key={generateKey()}
                                                listItems={listItems}
                                                selectAssembly={this.selectAssembly}
                                                selectedTextForAssemblyList={selectedTextForAssemblyList}
                                                queryParams={this.props.duroReducer.queryParams}
                                                unsetSelectedTextForAssemblyList={this.unsetSelectedTextForAssemblyList}
                                                mainAssemblyId={elementId}
                                                refreshDocument={refreshDocument}
                                                afterRefreshDocument={this.afterRefreshDocument}
                                                setMyState={this.setMyState}
                                            />
                                        </div>
                                        {isSyncing &&
                                            <SkeletonTheme color="#2A2A36" highlightColor="#3CD1BF">
                                                <Skeleton delay={0.9} width={324} height={7} />
                                            </SkeletonTheme>
                                        }
                                    </div>
                                    }
                                    <ItemAssembly
                                        setAssemblyRefreshFlags={this.setAssemblyRefreshFlags}
                                        mainAssembly={mainAssembly}
                                        refreshAssembly={refreshAssembly}
                                        afterRefreshAssembly={this.afterRefreshAssembly}
                                        refreshModal={refreshModal}
                                        parts={parts}
                                        instances={assemblyInstances}
                                        subAssemblies={subAssemblies}
                                        queryParams={this.props.duroReducer.queryParams}
                                        refreshDocument={refreshDocument}
                                        assemblyInnerLoading={assemblyInnerLoading}
                                        getAssemblyDefination={this.getAssemblyDefination}
                                        bulkUpdate={bulkUpdate}
                                        partStudio={partStudio}
                                        getPartsInPartStudio={this.getPartsInPartStudio}
                                        assemblies={listItems}
                                    />
                                </React.Fragment>
                            )
                        }
                </div>
                {
                    refreshModal.visibility &&
                    <ModalBox modal={refreshModal} onClick={this.hideRefreshModal}/>
                }
                {
                    webhookModalVisibility &&
                    <ModalBox modal={webhookModal} onClick={this.hideWebhookModal} icon={webhookModalIcon}/>
                }
            </div>
        return markup;
    }
}

const mapStateToProps = ({duroReducer}) => ({
    webhookModal: duroReducer.webhookModal
});

export default connect(mapStateToProps)(ItemsList);
