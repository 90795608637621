import React from 'react';

const PartStudioIcon = () => (
    <svg
        width="55px"
        height="53px"
        viewBox="0 0 55 53"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="PART-STUDIO-DARK"
                transform="translate(-3.000000, -2.000000)"
                fill="#888888"
                fillRule="nonzero"
            >
                <g id="Group-2" transform="translate(3.000000, 2.000000)">
                    <g id="Group" transform="translate(26.000000, 0.000000)">
                        <polygon id="Line" points="0.985054348 2.53308824 0.985054348 0.844362745 21.3428442 0.844362745 21.3428442 2.53308824"></polygon>
                        <polygon id="Line-3" points="0.985054348 0.844362745 3.22222222 0.844362745 3.22222222 52.9143627 0.985054348 52.9143627"></polygon>
                        <polygon id="Line-4" points="1.25030302 44.8612064 2.6666473 43.8727166 11.8604879 51.7534356 10.4674107 52.9475448"></polygon>
                        <path d="M12.4773551,11.8210784 L12.4773551,51.2246732 L26.9248188,51.2246732 L26.9248188,11.8210784 L12.4773551,11.8210784 Z M10.5072464,10.1323529 L28.8949275,10.1323529 L28.8949275,52.9133987 L10.5072464,52.9133987 L10.5072464,10.1323529 Z" id="Rectangle-2"></path>
                        <path d="M20.0258545,2.14495455 L19.4649371,0.872041693 L21.3001462,0.872041693 L28.906144,10.1265909 L28.906144,11.5022065 C28.1531143,11.5506969 27.6677934,11.4505877 27.4501812,11.2018791 L20.0258545,2.14495455 Z" id="Line-2"></path>
                        <path d="M1.22877975,2.24474225 C0.870533163,1.89379416 0.912021198,1.36035734 1.32144587,1.05327777 C1.73087054,0.74619819 2.35319105,0.781760645 2.71143764,1.13270873 L11.9052782,10.1392447 C12.2635248,10.4901928 12.2220368,11.0236296 11.8126121,11.3307092 C11.4031874,11.6377887 10.7808669,11.6022263 10.4226203,11.2512782 L1.22877975,2.24474225 Z" id="Line-2"></path>
                    </g>
                    <g id="Group" transform="translate(0.000000, 13.000000)">
                        <polygon id="Line" points="0.985054348 2.56578947 0.985054348 0.855263158 21.3428442 0.855263158 21.3428442 2.56578947"></polygon>
                        <polygon id="Line-3" points="0.985054348 0.855263158 2.95516304 0.855263158 2.95516304 33.0701754 0.985054348 33.0701754"></polygon>
                        <polygon id="Line-4" points="1.08851734 33.0845641 2.29244828 32.1317627 10.4896583 37.9515419 10.8208094 39.9814546"></polygon>
                        <path d="M12.4773551,10.8333333 L12.4773551,38.2017544 L26.9248188,38.2017544 L26.9248188,10.8333333 L12.4773551,10.8333333 Z M10.5072464,9.12280702 L28.8949275,9.12280702 L28.8949275,39.9122807 L10.5072464,39.9122807 L10.5072464,9.12280702 Z" id="Rectangle-2"></path>
                        <path d="M20.0258545,2.01156288 L19.4649371,0.855263158 L21.3001462,0.855263158 L28.906144,9.2619915 L28.906144,10.5115853 C28.1531143,10.5556334 27.6677934,10.4646953 27.4501812,10.2387712 L20.0258545,2.01156288 Z" id="Line-2"></path>
                        <polygon id="Line-2" points="1.22877975 1.13337017 3.4876546 1.13337017 11.9052782 9.12978773 10.4226203 10.2561772"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default PartStudioIcon;
