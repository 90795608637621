import React, { Component }       from 'react';
import InlineIcon                           from '../../icon/inline-icon.jsx';
import PartIconSrc                          from '../../../../assets/icons/part-icon-light.jsx';
import PartConfigurationIconSrc             from '../../../../assets/icons/part-configurations-icon-light.jsx';
import * as componentActions                from '../../../../actions/component';
import {isAlreadyFetching, updateFetchingPropertiesArray, getPropertyObj, getConfigurationString, getComponentDisplayName} from '../../../../helpers/components-helper';

class Part extends Component
{
    constructor(props, context)
    {
        super(props);
        this.state = {
            item: this.props.item,
            refreshAssembly: false,
            isRefreshing: false
        }
    }

    getMetaData()
    {
        let {workspaceId, dispatch, fetchingProperties} = this.props;
        let {item} = this.state;
        let {fullConfiguration, elementId, documentId, partId, documentVersion, configuration, isStandardContent} = item;
        const successCb = (err, res) =>
        {
            if(err)
            {

            }
            else
            {
                let nameProperty = null;
                let excludeFromBom = null;
                let cpn = '';
                let category = '';
                let duroId = '';
                let lastModified = '';
                if(res.onShapeData && res.onShapeData.properties)
                {
                    nameProperty              = getComponentDisplayName(res.onShapeData);
                    let excludFromBomProperty = getPropertyObj(res.onShapeData.properties, 'Exclude from BOM');
                    excludeFromBom         = excludFromBomProperty ? excludFromBomProperty.value : false;
                    item.href             = res.onShapeData.href;
                }

                if(res.componentDuro)
                {
                    cpn = res.componentDuro.cpn;
                    category = res.componentDuro.category;
                    duroId = res.componentDuro._id;
                    lastModified = res.componentDuro.lastModified;
                }

                if(item.properties)
                {
                    item.properties.cpn = {value: cpn};
                    item.properties.category = {value: category};
                    item.properties.duroId = {value: duroId};
                    item.properties.lastModified = {value: lastModified};
                }
                else
                {
                    item.properties = {cpn: {value: cpn}, category: {value: category}, duroId: {value: duroId}, lastModified: {value: lastModified}};
                }

                if(nameProperty && item.name !== nameProperty.value)
                {
                    item.name = nameProperty && nameProperty.value;
                }
                item.excludeFromBom = excludeFromBom;
            }
            this.setState({item, isRefreshing: false});
        }
        this.setState({isRefreshing: true}, () =>
        {
            const payload = {partId, elementId, documentVersion, documentId, workspaceId, successCb, configurationString: getConfigurationString(item), ignoreMassProperties: true, populateConfigurationName: true, isStandardContent};
            dispatch(componentActions.getComponent(payload));
        });
    }

    componentDidUpdate(prevProps, prevState)
    {
        let isRefreshingAssembly = localStorage.getItem('refreshAssembly');
        if(isRefreshingAssembly === 'true' && !prevState.refreshAssembly)
        {
            this.setState({refreshAssembly: true});
        }
        if(!prevState.refreshAssembly && this.state.refreshAssembly)
        {
            this.getMetaData();
            this.setState({refreshAssembly: false});
        }
    }

    componentDidMount()
    {
        this.getMetaData();
    }

    render()
    {
        const {addLinkedDocumentIcon, toggleItemChecked, getTreeItem, bulkUpdate, fetchingProperties, workspaceId, showPullRequestModal} = this.props;
        let {item, isRefreshing} = this.state;
        const disableCheckbox = bulkUpdate || item.documentVersion && !item.isStandardContent || isRefreshing || item.excludeFromBom ? true : false;
        const isNonDefaultConfiguration = item.fullConfiguration && item.fullConfiguration.toLowerCase() !== 'default';
        const isConfigured = item.hasOwnProperty('isConfigured') ? item.isConfigured : isNonDefaultConfiguration && !item.isStandardContent;

        return (
                <div className='assembly-ul-list'>
                    <div className='assembly-holder'>
                        <label className={`checkbox-holder ${disableCheckbox ? 'pointer-events-none' : ''}`}>
                            <input
                                type='checkbox'
                                onChange={() => toggleItemChecked(item, !item.isChecked)}
                                checked={disableCheckbox ? '' : item.isChecked ? item.isChecked : ''}
                            />
                            <span className={`checkmark ${disableCheckbox ? 'disabled' : ''}`}></span>
                        </label>
                        <InlineIcon className={'component-type-icon'}>
                            {isConfigured ? <PartConfigurationIconSrc/> : <PartIconSrc/>}
                        </InlineIcon>
                        {getTreeItem(item, isRefreshing)}
                        <span>
                            {addLinkedDocumentIcon(item)}
                        </span>
                    </div>
                </div>
            );
    }
}

export default Part;
