import Config                    from '../../../config';
import React    from 'react';

export const getPropertyObj = (array, toFind) => {
    let property = array.find((obj) => obj.name == toFind);
    let value = property ? property.value : null;
    return { value, propertyId: property && property.propertyId, name: property && property.name };
};

export const generateKey = () =>
{
    return (1 + Math.random() * (100000 - 1) + '').replace('.', new Date().getTime());
}

export const isDescendant = (parent, child) =>
{
    var node = child.parentNode

    while(node !== null && node !== undefined)
    {
        if(node === parent) return true

        node = node.parentNode
    }

    return false
}

export const setJwToken = (value) =>
{
    localStorage.setItem("jwtoken", value);
    if(!value)
    {
        removeItemFromSessionStorage('lastOpenedAssembly');
    }
};

export const createLinkUrl = (path, queryParams) => {
    let clientBaseUrl = getLocalStorageItem("clientBaseUrl");
    clientBaseUrl     = clientBaseUrl && clientBaseUrl !== "undefined" && Config.ENV !== "local" ? clientBaseUrl : null;
    let response      = clientBaseUrl ? `${clientBaseUrl}${path}` : `${Config.CLIENT_ORIGIN}${path}`;
    if(queryParams)
    {
      response = `${response}?${queryParams}`;
    }
    return response;
};

export const checkToolTipAppearance = (e, tooltipText, elementClass='') =>
{
    let element = e.target;
    if (elementClass && !element.classList.contains(elementClass))
    {
        element = element.closest(`. ${elementClass}`);
        // return;
    }
    element.dataset.tip = "";
    if (element.offsetWidth < element.scrollWidth)
    {
        element.dataset.tip = tooltipText;
    }
    else
    {
        element.dataset.tip = "";
    }
}

export const getImageOfSpecificSize = (thumbnails, size) =>
{
    if(!thumbnails) return;
    const sizes    = thumbnails.sizes ? thumbnails.sizes : thumbnails;
    const reqImage = sizes.find((item) => item.size === size);
    return reqImage;
}

export const getToolTipText = (status, validations) =>
{
    let modelName = 'component';
    let payload   = { status, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType };
    let revValue  = validations[modelName].revision.getAllowedRevisionValues(payload);
    return revValue;
}

export const capitalizeString = (string='') =>
{
    string = string.toLowerCase();
    return string.replace(/(^|\s)\S/g, l => l.toUpperCase());
}

export const encodeSpecialCharacters = (key) =>
{
    return encodeURIComponent(encodeURIComponent(encodeURIComponent(key)));
}

export const getWorkSpaceId = (workspaceId, queryParams) =>
{
    let arr = ['undefined', 'null'];
    if(!workspaceId || arr.indexOf(workspaceId) > -1)
    {
        workspaceId = queryParams.workspaceId;
    }
    return workspaceId;
}

export const getComponentName = (component, options = {}) =>
{
    if(component && component.name)
    {
        let name = component.name.value ? component.name.value : component.name;
        let lastIndex = name.indexOf('<');
        if(name.indexOf('<') >= 0)
        {
            name = name.substring(0, lastIndex);
            name.trim();
        }

        if(options.setQuantity && component.quantity)
        {
            component.quantityText = `${component.quantity > 1 ? `(x${component.quantity})` : ''}`;
        }
        return name;
    }
}

export const sortItemsAlphabetically =  (listItems) =>
{
    if(listItems.length > 0)
    {
        listItems.sort((a, b) =>
        {
            if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
        });
    }
    return listItems;
}

export const filterAssembliesData = (list) =>
{
    let assemblies  = [];
    let partstudios = [];
    for (let item of list)
    {
        if(item.elementType === 'ASSEMBLY')
        {
            assemblies.push(item);
        }
        else if(item.elementType === 'PARTSTUDIO')
        {
           partstudios.push(item);
        }
    }
    assemblies  = sortItemsAlphabetically(assemblies);
    partstudios = sortItemsAlphabetically(partstudios);
    for(let part of partstudios)
    {
        assemblies.push(part);
    }
    return assemblies;
}

export const getLinkedDocumentHref = (item) =>
{
    let urlHost = item.href ? item.href.split("api/metadata/d") : "";
    let urlPath = urlHost[1] ? urlHost[1].split("/p/"): "";
    let href = urlHost && urlPath ? `${urlHost[0]}documents${urlPath[0]}` : "";

    return href;
}

export const getComponentOnshapeId = (cmp) =>
{
    let type = cmp.type;
    if(type && type.toLowerCase() === 'part')
    {
        return cmp.partId;
    }
    else if(type && type.toLowerCase() === 'assembly')
    {
        return cmp.elementId;
    }
}

export const getStatusesList = (currentStatus) =>
{
    let designDisabled, prototypeDisabled, productionDisabled, obsoleteDisabled;
    switch(currentStatus.toUpperCase())
    {
        case "PROTOTYPE" :
        {
            designDisabled     = true;
            prototypeDisabled  = false;
            productionDisabled = false;
            obsoleteDisabled   = false;
            break;
        }

        case "PRODUCTION" :
        {
            designDisabled     = true;
            prototypeDisabled  = true;
            productionDisabled = false;
            obsoleteDisabled   = false;
            break;
        }

        case "OBSOLETE" :
        {
            designDisabled     = true;
            prototypeDisabled  = true;
            productionDisabled = true;
            obsoleteDisabled   = false;
            break;
        }

        case "DESIGN" :
        default :
        {
            designDisabled     = false;
            prototypeDisabled  = false;
            productionDisabled = false;
            obsoleteDisabled   = false;
        }
    }
    let list =
    [
        {
            value: "DESIGN",
            displayName: "DESIGN",
            disabled: designDisabled,
            rank: 0
        },
        {
            value: "PROTOTYPE",
            displayName: "PROTOTYPE",
            disabled: prototypeDisabled,
            rank: 1

        },
        {
            value: "PRODUCTION",
            displayName: "PRODUCTION",
            disabled: productionDisabled,
            rank: 2

        },
        {
            value: "OBSOLETE",
            displayName: "OBSOLETE",
            disabled: obsoleteDisabled,
            rank: 3
        }
    ];
    return list;
}

export const toOptions = (list, includeChooseStatusOption=false) =>
{
    if(!Array.isArray(list)) return;
    if (includeChooseStatusOption)
    {
        let ChooseStatusOption = [{ value: "Choose Status", displayName: "Choose Status" }];
        list = [...ChooseStatusOption, ...list];
    }
    let options = [];
    let name = "";
    list.forEach((item, i) =>
    {
        let value = item.value || item.name;
        name = item.displayName || item.name;
        if (item.disabled) value = '';
        options[i] = <option key={i} value={value} disabled={item.disabled}>{name}</option>
    });

    return options;
}


export const getStatusRank = (status) =>
{
    let list = getStatusesList("DESIGN");
    let rank = 0;
    switch(status)
    {

        case "DESIGN" :
        {
            rank = list[0].rank;
            break;
        }

        case "PROTOTYPE" :
        {
            rank = list[1].rank;
            break;
        }
        case "PRODUCTION" :
        {
            rank = list[2].rank;
            break;
        }
        case "OBSOLETE" :
        {
            rank = list[3].rank;
            break;
        }
        default :
        {
            rank = list[0].rank;
        }
    }
    return rank;
}

export const isDefaultRevisionType = () =>
{
    return (window.__revSchemeType === "DEFAULT" || window.__libraryType === "PERSONAL");
}

export const extractConfiguration = (configurations = [], configurationName) =>
{
    if(!configurationName) return null;
    return configurations.find(config => config.configuration.toLowerCase() === configurationName.toLowerCase());
}

export const extractCpn = (component) =>
{
    if(!component) return null;
    let { componentDuro, properties } = component;
    if(componentDuro)
    {
        return componentDuro.cpn;
    }
    else if(properties && properties.cpn)
    {
        return properties.cpn.value;
    }
    return null;
}

export const isAlreadyFetching = (fetchingProperties, item) =>
{
    let {configurationString, elementId, documentId, partId, displayOrder} = item;
    let isSame               =  fetchingProperties.find((cmp) => cmp.configurationString === configurationString && cmp.elementId === elementId && cmp.documentId === documentId && cmp.displayOrder === displayOrder);
    if(item.partId)
    {
        isSame = isSame && item.partId === partId;
    }
    if(!isSame)
    {
        fetchingProperties.push(item);
        return false;
    }
    return true;
}

export const updateFetchingPropertiesArray = (fetchingProperties, item) =>
{
    let {configurationString, elementId, documentId, partId, displayOrder} = item;
    fetchingProperties.some((currentItem, index) =>
    {
        let elementToBeSplitted = currentItem.elementId === elementId && currentItem.documentId === documentId && currentItem.configurationString === configurationString && currentItem.displayOrder === displayOrder;
        if(item.partId)
        {
            elementToBeSplitted = elementToBeSplitted && item.partId === partId;
        }
        if(elementToBeSplitted)
        {
            fetchingProperties.splice(index, 1);
            return true;
        }
    });
}

export const getConfigurationString = (component) =>
{
    if(!component) return;
    let configurationString       = component.configuration ? component.configuration : component.fullConfiguration;;
    component.configurationString = configurationString;
    return configurationString;
};


export const filterElements = (elements = []) =>
{
    return elements.find(element => element.type && (element.type.toLowerCase() === "part" || element.type.toLowerCase() === "assembly"));
}

export const getParsedItemFromSessionStorage = (key) =>
{
  let value = sessionStorage.getItem(key);
  return value ? JSON.parse(value): '';
}

export const setItemInSessionStorage = (key, value) =>
{
  sessionStorage.setItem(key, JSON.stringify(value));
}

export const removeItemFromSessionStorage = (key) =>
{
  sessionStorage.removeItem(key);
}

export const setLocalStorageItem = (key, value) =>
{
    localStorage.setItem(key, value);
}

export const getLocalStorageItem = (key) =>
{
    return localStorage.getItem(key);
}

export const removeLocalStorageItem = (key) =>
{
    localStorage.removeItem(key);
}

export const getComponentDisplayName = (component) =>
{
    if(!component || !component.properties) return '';
    return component.isStandardContent ? getPropertyObj(component.properties, 'Description') : getPropertyObj(component.properties, 'Name');
}
