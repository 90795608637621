import React, { Component } from 'react';

import { RingLoader } from 'react-spinners';
import { spinnerStyles, sizeUnit, size, color } from '../../../helpers/spinnerStyles';

class AuthCodeRequest extends Component {
    constructor(props) {
        super();
        this.state = { ...props };
    }

    componentDidMount()
    {
        if (window.location.search !== '')
        {
            const urlParams = new URLSearchParams(window.location.search);
            const signedToken = urlParams.get('signedToken');
            if (signedToken) {
                localStorage.setItem('jwtoken', signedToken)
                localStorage.setItem('loadMain', false)
                window.opener.location = this.state.authURL
                self.close()
            } else {
                localStorage.setItem('queryParams', window.location.search);
            }
        }
        window.location = this.state.authURL;
    }

    render() {
        return (
            <div className="middle">
                <div className="loader-holder">
                    <RingLoader
                        css={spinnerStyles}
                        sizeUnit={sizeUnit}
                        size={size}
                        color={color}
                        loading={this.state.mainLoading}
                    />
                </div>
            </div>
        );
    }
}

export default AuthCodeRequest;
