import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="BULK-SELECTION-0" transform="translate(-1411.000000, -169.000000)" fillRule="nonzero" stroke="#FFFFFF" strokeWidth="1.5">
            <g id="Group-2" transform="translate(1381.000000, -2.000000)">
                <g id="NAV" transform="translate(0.000000, 25.000000)">
                    <g transform="translate(0.000000, 1.000000)" id="Libraray-Icon">
                        <g transform="translate(30.000000, 144.000000)">
                            <path d="M0.606410256,9.37404991 L14.9537756,9.37404991" id="Line-7"></path>
                            <path d="M6.05,5.67514881 L14.9537756,5.67514881" id="Line-7"></path>
                            <path d="M11.55,1.97624771 L14.9537756,1.97624771" id="Line-7"></path>
                            <polyline id="Line-7" points="0.606410256 13.6713027 10.9816273 13.6713027 14.9682915 13.6713027"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon;
