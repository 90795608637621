import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="17px" height="15px" viewBox="0 0 17 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="11-MAKE-SELECTION" transform="translate(-711.000000, -207.000000)">
            <g id="MODAL" transform="translate(523.000000, 52.000000)">
                <g id="Group-5" transform="translate(39.000000, 64.000000)">
                    <g id="TABLE" transform="translate(0.000000, 45.000000)">
                        <g id="CONTENT" transform="translate(49.000000, 13.000000)">
                            <g id="NAME">
                                <g id="WARNING" transform="translate(99.000000, 31.000000)">
                                    <g id="baseline-warning-24px">
                                        <ellipse id="Oval-2" fill="#18171D" fillRule="nonzero" cx="9.5" cy="10.0588235" rx="1.67647059" ry="5.58823529"></ellipse>
                                        <polygon id="Shape" points="0 0 19 0 19 19 0 19"></polygon>
                                        <path d="M1.11764706,16.7647059 L17.8823529,16.7647059 L9.5,2.23529412 L1.11764706,16.7647059 Z M10.2620321,14.4705882 L8.73796791,14.4705882 L8.73796791,12.9411765 L10.2620321,12.9411765 L10.2620321,14.4705882 Z M10.2620321,11.4117647 L8.73796791,11.4117647 L8.73796791,8.35294118 L10.2620321,8.35294118 L10.2620321,11.4117647 Z" id="Shape" fill="#F5A64F" fillRule="nonzero"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon;
