import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <circle id="path-1" cx="10.5" cy="10.5" r="10.5"></circle>
        <filter x="-33.3%" y="-23.8%" width="166.7%" height="166.7%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-New-Collapsed" transform="translate(-1251.000000, -179.000000)">
            <g id="Side-Tab-Right" transform="translate(1255.000000, 75.000000)">
                <g id="Hamburger" transform="translate(0.000000, 106.000000)">
                    <g id="Group-2">
                        <g id="Oval" fillRule="nonzero">
                            <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                            <use fill="#3CD1B5" xlinkHref="#path-1"></use>
                        </g>
                        <path d="M4.5,7.5 L16.5,7.5" id="Line-2" stroke="#353540" strokeWidth="1.5" fill="#D8D8D8" fillRule="nonzero" strokeLinecap="round"></path>
                        <path d="M4.5,14.5 L16.5,14.5" id="Line-2" stroke="#353540" strokeWidth="1.5" fill="#D8D8D8" fillRule="nonzero" strokeLinecap="round"></path>
                        <path d="M4.5,11 L16.5,11" id="Line-2" stroke="#353540" strokeWidth="1.5" fill="#D8D8D8" fillRule="nonzero" strokeLinecap="round"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

}

export default SvgIcon;
