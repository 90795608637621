import React, { Component } from 'react';
import * as componentActions from '../../../../actions/component';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { checkToolTipAppearance } from '../../../../helpers/components-helper';
import InlineIcon from '../../../ui/icon/inline-icon';
import AlertIcon from '../../../../assets/icons/alert-error';

class MassField extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.state = {
                        massObj: this.props.massObj,
                        isCalculatingMass: false
                    };
    }

    componentDidMount()
    {
        let {massObj} = this.state;
        let {view, dispatch, onShapeComp, queryParams, componentDuro} = this.props;
        if(view === 'view')
        {
            const successCb = (err, response) => {
                if(err)
                {

                }
                else
                {
                    massObj.value = response;
                }
                this.setState({isCalculatingMass: false, massObj});
            };

            this.setState({isCalculatingMass: true} , () => {
                let {partId, elementId, documentVersion, documentId, workspaceId, configurationString, isStandardContent} = onShapeComp;
                const payload = {partId, elementId, documentVersion, documentId, workspaceId, successCb, configurationString, linkDocumentId: queryParams.documentId, isStandardContent, onShapeCompanyId: queryParams.companyId, componentDuro};
                dispatch(componentActions.getMassValue(payload));
            });
        }
    }

    render()
    {
        let {innerClass} = this.props;
        let {massObj, isCalculatingMass} = this.state;
        const massStatus = this.props.componentDuro && this.props.componentDuro.massStatus;
        return <React.Fragment>
                    <span className='label'>MASS (g)</span>
                    {
                        isCalculatingMass ?
                        <span className='is-calculating-mass'>
                            <SkeletonTheme color="#2a2a36" highlightColor="#726E6E">
                               <Skeleton delay={0.9} width={50} height={7}/>
                            </SkeletonTheme>
                        </span>
                        :
                        <span>
                            <span
                                data-tip=''
                                onMouseOver={(e) => checkToolTipAppearance(e, massObj ? massObj.value : '')}
                                className={`'truncate-text' ${innerClass}`}>
                                    {massObj && massObj.value ? massObj.value : ''}
                            </span>
                            <span style={{marginLeft:10}}>
                                {massStatus === 'ERROR' &&
                                <InlineIcon 
                                    tooltip="Missing mass values for one or more children"
                                    data-for="part-edit-page"
                                >
                                    <AlertIcon />
                                </InlineIcon>
                                }
                            </span>
                        </span>
                    }
                </React.Fragment>;
    }
}

export default MassField;
