// Recuder specify how the application's state changes in response to actions sent to the store.

import { handleActions } from 'redux-actions';
import * as assemblyActions from '../actions/assemblies';
import { filterAssembliesData } from '../helpers/components-helper';

const initialState = {
  "assemblies": [],
  "assemblyLoading": true,
  "elementId" : ''
};

function documentAssembliesSuccess(state, { payload })
{
    state.assemblies      = filterAssembliesData(payload);
    state.elementId       = state.assemblies.length > 0 ? state.assemblies[0].id : state.assemblyLoading = false;
    return {...state}
}

function documentAssembliesError(state, { payload }) {
    payload.assemblyLoading = false;
    return { ...state, ...payload };
}

function setAssemblyLoading(state, { payload }) {
    state.assemblyLoading = payload;
    return { ...state};
}

// This is similar like switch statement to handle actions and return new state
export default handleActions(
    {
        [assemblyActions.documentAssembliesSuccess]: documentAssembliesSuccess,
        [assemblyActions.documentAssembliesError]: documentAssembliesError,
        [assemblyActions.assemblyLoading]: setAssemblyLoading,
    },
    initialState,
);
