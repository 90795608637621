import                                                      './index.scss';
import React, { Component }                            from 'react';
import { connect }                                     from 'react-redux';
import { RingLoader }                                  from 'react-spinners';
import * as documentActions                            from '../../actions/document';
import ShowDocument                                    from '../ui/show-document/index.jsx';
import { spinnerStyles, sizeUnit, size, docColor }     from '../../helpers/spinnerStyles';
import { getImageOfSpecificSize, getParsedItemFromSessionStorage } from '../../helpers/components-helper';
import * as assemblyActions                            from '../../actions/assemblies';
import * as componentActions                           from '../../actions/component';

class Document extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            assemblies: this.props.assemblyReducer.assemblies
        };
    }

    componentDidUpdate()
    {
        if(this.state.assemblies.length !== this.props.assemblyReducer.assemblies.length)
        {
            let lastOpenedAssembly    = getParsedItemFromSessionStorage('lastOpenedAssembly');
            const searchParams        = this.props.queryParams;

            const successCb = (err, response) =>
            {
                if(response)
                {
                    this.props.dispatch(componentActions.resetState());
                    if(response.onShapeData && response.onShapeData.thumbnail)
                    {
                      this.props.dispatch(documentActions.updateDocumentImage(response.onShapeData.thumbnail));
                    }
                    this.props.dispatch(componentActions.componentSucess(response));
                }
            };
            if(lastOpenedAssembly && lastOpenedAssembly.elementId && searchParams.documentId === lastOpenedAssembly.documentId && lastOpenedAssembly.type && lastOpenedAssembly.type.toLowerCase() === 'assembly')
            {
                let payload = { elementId: lastOpenedAssembly.elementId, documentId: searchParams.documentId, workspaceId: searchParams.workspaceId, configurationString: lastOpenedAssembly.configurationString, successCb};
                this.props.dispatch(assemblyActions.getAssemblyMetaData(payload));
            }
            else
            {
                this.props.dispatch(documentActions.getDocument());
            }
            this.setState({assemblies: this.props.assemblyReducer.assemblies});
        }
    }

    render() {
        const data  = this.props.documentReducer;
        const {document} = data;
        const image  = document && document.thumbnail ?  getImageOfSpecificSize(document.thumbnail, '600x340') : null;
        return (
            <div className="document">
                {data.documentLoading ? (
                    <div className="loader-holder">
                        <RingLoader
                            css={spinnerStyles}
                            sizeUnit={sizeUnit}
                            size={size}
                            color={docColor}
                            loading={data.documentLoading}
                        />
                    </div>
                ) : (
                    <ShowDocument href={image && image.href ? image.href : null} />
                )}
            </div>
        );
    }
}

const mapStateToProps = ({documentReducer, oauthReducer, assemblyReducer }) => ({
    queryParams     : oauthReducer.queryParams,
    documentReducer,
    assemblyReducer
});

export default connect(mapStateToProps)(Document);
