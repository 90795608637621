import React from 'react';

const SvgIcon = () => (
    <svg
        className="open-link-icon"
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs />
        <g className="open-link-icon-g" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="arrow-area" transform="translate(-274.000000, -143.000000)">
                <g id="TOOL-link-TIP" transform="translate(56.000000, 117.000000)">
                    <g transform="translate(218.000000, 26.000000)">
                        <g>
                            <polygon
                                fillRule="nonzero"
                                points="10 0 5.47523105 0 5.47523105 0.905730129 8.45378928
                                        0.905730129 3 6.35951941 3.64048059 7 9.09426987
                                        1.54621072 9.09426987 4.52476895 10 4.52476895"
                            />
                            <polygon
                                fillRule="nonzero"
                                points="10 7.37360754 9.00028563 7.37360754 9.00028563 9.00028563
                                0.999714367 9.00028563 0.999714367 0.999714367 2.62782062
                                0.999714367 2.62782062 0 0 0 0 10 10 10"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default SvgIcon;
