import './index.scss';
import React, { Component } from 'react';

class ShowDocuments extends Component {
    render() {
        return (
            <div className='image-div'>
                <img className='document-image' src={this.props.href} alt="" />
            </div>
        );
    }
}

export default ShowDocuments;
