import serviceCalls             from "./serviceCalls";
import { encodeSpecialCharacters } from '../helpers/components-helper';

export const getComponent = (payload, queryParams, cb) =>
{
    let {elementId, partId, configurationString, populateConfigurationName, ignoreMassProperties, documentId, workspaceId, documentVersion, isStandardContent} = payload;

    if(!partId.includes('/'))
    {
        partId = encodeSpecialCharacters(partId);
    }

    let path = `/onshape/getComponent?documentID=${documentId}&workspaceID=${workspaceId}&elementId=${elementId}&partId=${partId}&isPartIdEncoded=true&ignoreMassProperties=${ignoreMassProperties}`;

    if(populateConfigurationName)
    {
        path = `${path}&populateConfigurationName=${populateConfigurationName}`
    }

    if(documentVersion)
    {
        path = `${path}&documentVersion=${documentVersion}&linkDocumentId=${queryParams.documentId}`;
        if(isStandardContent)
        {
          path = `${path}&isStandardContent=${isStandardContent}&onShapeCompanyId=${queryParams.companyId}`;
        }
    }

    if(configurationString)
    {
        path = `${path}&configuration=${configurationString}`;
    }

    serviceCalls.get(path).then((response) =>
    {
        cb(null, response.data);
    }).catch((error) =>
    {
        cb(error);
    });
};

export const updateComponentMetadata = (payload, cb) =>
{
    // let searchParams = getQueryVariable(localStorage.getItem("queryParams"));
    // let items = { items: item }
    // let data = { elementId: elementId, partId: partId, items: items, documentID: searchParams.documentId , workspaceID: searchParams.workspaceId }
    let path = "/onshape/update";
    serviceCalls.post(path, payload ,(err, res) =>
    {
        if (err)
        {
            cb(err);
        }
        else
        {
            cb(null, res.data);
        }
    });
};

export const getCategories = (cb) =>
{
    let path = "/onshape/getCategoriesList";
    serviceCalls.get(path).then((response) =>
    {
        cb(null, response.data);
    }).catch((error) =>
    {
        cb(error);
    });
};

export const sendPullRequest = (payload, cb) =>
{
    let path = "/onshape/pullrequest";
    serviceCalls.post(path, payload ,(err, res) =>
    {
        if (err)
        {
            cb(err.data);
        }
        else
        {
            cb(null, res.data);
        }
    });
}

export const NameExistsBulk = (payload, cb) =>
{
    let path = "/onshape/name/exists/bulk";
    serviceCalls.post(path, payload ,(err, res) =>
    {
        if (err)
        {
            cb(err);
        }
        else
        {
            cb(null, res.data);
        }
    });
}

export const getComponentsInBulk = (payload, cb) =>
{
    let path = "/onshape/components/get/bulk";
    serviceCalls.post(path, payload ,(err, res) =>
    {
        if (err)
        {
            cb(err);
        }
        else
        {
            cb(null, res.data);
        }
    });
}

export const updateComponentsInBulk = (payload, cb) =>
{
    let path = "/onshape/components/bulk/update";
    serviceCalls.post(path, payload ,(err, res) =>
    {
        if (err)
        {
            cb(err);
        }
        else
        {
            cb(null, res.data);
        }
    });
}

export const calculateMass = (payload, cb) =>
{
    let path = "/onshape/getMassValue";
    serviceCalls.post(path, payload ,(err, res) =>
    {
        if (err)
        {
            cb(err);
        }
        else
        {
            if(res && res.data)
            {
                cb(null, res.data);
            }
            else
            {
                cb(null, 0);
            }
        }
    });

}

export const syncComponentThumbnail = (payload, cb) => {
    const path = "/onshape/components/syncComponentThumbnail";
    serviceCalls.post(path, payload ,(err, res) => {
        if (err) cb(err);
        else cb(null, res.data);
    });
}
