import React from "react";

const PartConfigurationsIconDark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="32"
      viewBox="0 0 35 32"
    >
      <path
        fill="none"
        stroke="#888"
        strokeWidth="0.525"
        d="M33.049 8.918H25.65v13.645H12.59v7.863h20.459z"
      ></path>
      <path
        fill="none"
        stroke="#9b9b9b"
        strokeWidth="0.525"
        d="M18.992 15.21l6.45 7.085"
      ></path>
      <path
        fill="none"
        stroke="#888"
        strokeWidth="0.525"
        d="M18.74 3.322l6.779 5.771"
      ></path>
      <path
        fill="none"
        stroke="#9b9b9b"
        strokeWidth="0.525"
        d="M6.395 16.241l6.457 6.578"
      ></path>
      <path
        fill="none"
        stroke="#888"
        strokeWidth="0.525"
        d="M27.75 3.148l5.299 5.241v22.037H12.193l-5.898-5.199V15.72h12.73V3.147z"
      ></path>
      <path fill="#e1e1e1" d="M1.049.525H19.41V13.64H1.049V.525z"></path>
      <path
        fill="#3cd1b5"
        d="M19.41 3.667v.546H1.049v-.546H19.41zm0-3.142v.546H1.049V.525H19.41zm0 6.284v.546H1.049v-.546H19.41zm0 3.142v.546H1.049v-.546H19.41zm0 3.142v.546H1.049v-.546H19.41z"
      ></path>
      <path
        fill="#3cd1b5"
        d="M.525.525h.525V13.64H.525V.525zm3.672 0h.525V13.64h-.525V.525zm3.672 0h.525V13.64h-.525V.525zm3.672 0h.525V13.64h-.525V.525zm3.672 0h.525V13.64h-.525V.525zm3.672 0h.525V13.64h-.525V.525z"
      ></path>
    </svg>
  );
}

export default PartConfigurationsIconDark;

