import { createAction } from 'redux-actions';

// AUTH ACTION CREATORS
export const getOnshapeToken = createAction('GET_ONSHAPE_TOKEN');
export const onshapeTokenSuccess = createAction('ONSHAPE_TOKEN_SUCCESS');
export const onshapeTokenError = createAction('ONSHAPE_TOKEN_ERROR');
export const getOnshapeRefreshToken = createAction('GET_ONSHAPE_REFRESH_TOKEN');
export const setQueryParams = createAction('SET_QUERY_PARAMS');
export const getIsOnshapeCompanyAdmin = createAction('GET_IS_ONSHAPE_COMPANY_ADMIN');
export const setIsOnshapeCompanyAdmin = createAction('SET_IS_ONSHAPE_COMPANY_ADMIN');
