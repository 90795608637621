// Recuder specify how the application's state changes in response to actions sent to the store.

import { handleActions } from 'redux-actions';
import * as duroActions from "../actions/duro";

const initialState = {
    assemblyDefinition: {},
    duroLoading: false,
    assemblyInnerLoading: false,
    webhookModal: {
        headerText: '',
        bodyText: '',
        visibility: false,
        displayBtn: true,
        icon: null,
    }
};

function duroLoading(state, { payload }) {
    state.duroLoading = payload;
    state.assemblyInnerLoading = true;
    return { ...state };
}

function getDataSuccess(state, { payload })
{
    state.subAssemblies = payload.subAssemblies;
    state.rootAssembly  = payload.rootAssembly;
    state.assemblyInstances = payload.rootAssembly.instances;
    state.parts = payload.parts;
    state.assemblyInnerLoading = false;
    state.duroLoading = false;
    return { ...state };
}

function getDataError(state, { payload }) {
    payload.duroLoading = false;
    return { ...state, ...payload };
}

function setWebhookModal(state, { payload }) {
    return { ...state, webhookModal: { ...state.webhookModal, ...payload } };
}

function resetState(state = initialState)
{
    return {...state}
}

// This is similar like switch statement to handle actions and return new state
export default handleActions(
    {
        [duroActions.getDataSuccess]: getDataSuccess,
        [duroActions.getDataError]: getDataError,
        [duroActions.duroLoading]: duroLoading,
        [duroActions.setWebhookModal]: setWebhookModal
    },
    initialState,
);
