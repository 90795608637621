import React from 'react';

const SvgIcon = () => (
    <svg
        width="57px"
        height="53px"
        viewBox="0 0 57 53"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                className="component-type-icon"
                id="ASSEMBLY-DARK-(1)"
                transform="translate(1.000000, 1.000000)"
                stroke="#18171D"
            >
                <path d="M12,35.4504364 L12,50.4791838" id="Path-7" />
                <path d="M36,11 L55,11" id="Path-6" />
                <polygon id="Path" points="43.6019891 0 55 11.0319424 55 51 12.2867147 51 0 39.1078961 0 0" />
                <g id="Group" fill="#F4F4F4" fillOpacity="0.25" fillRule="nonzero">
                    <path
                        d="M0.6436738,0.4325203 L11.9336439,11 L37.7653266,11
                    C37.8205551,11 37.8653266,10.9552285 37.8653266,10.9
                    C37.8653266,10.8754559 37.8563001,10.851769 37.8399659,
                    10.8334494 L28.1805431,0 L0.8145139,0 C0.67644271,
                    0 0.5645139,0.11192881 0.5645139,0.25 C0.5645139,0.3191714
                    0.59317306,0.38525131 0.6436738,0.4325203 Z"
                        id="Path-2"
                    />
                    <polygon id="Path-3" points="12 11 37.9733938 11 37.9733938 35.5027068 12 35.5027068" />
                    <polygon
                        id="Rectangle"
                        points="0.05167655 0.07282615 0.05167655 25.2725277
                        11.9267681 35.6224101 12.0602478 11.2808179"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default SvgIcon;
