import * as userActions             from '../actions/user';
import { handleActions }            from "redux-actions";

const initialState =
{
    user: null
};

function setCurrentUser(state, { payload })
{
    state.user = payload;
    window.__revSchemeType = payload.company.revSchemeType;
    window.__libraryType   = payload.activeLibrary.type;
    window.__nonIntelligent = payload.company.nonIntelligent;
    window.__isIntelligentCpnScheme = payload.company.isIntelligentCpnScheme;
    window.__cpnSchemeType = payload.company.cpnScheme && payload.company.cpnScheme.activeScheme;

    const {settings} = payload.company;
    window.__isNotRevisionManagedEnabled = settings.isNotRevisionManagedEnabled;
    window.__isApplicabilityFieldEnabled = settings.isApplicabilityFieldEnabled;
    return {...state}
}


export default handleActions({
    [userActions.setCurrentUser]: setCurrentUser
}, initialState);
