import                                               './index.scss';
import React, { Component }                     from 'react';
import ReactTooltip                             from 'react-tooltip';
import HeaderIconSrc                            from '../../../assets/icons/pull-request-header-icon';
import InlineIcon                               from '../icon/inline-icon.jsx';
import BulkCategoryIconSrc                      from '../../../assets/icons/bulk-category-icon';
import * as componentActions                    from '../../../actions/component';
import TableRow                                 from './table-row/index.jsx';
import {
            generateKey,
            getPropertyObj,
            getImageOfSpecificSize,
            getComponentName
        }                                       from '../../../helpers/components-helper';
import {
            assemblyCategories,
            partCategories,
            findByName,
            permittedCategories
        }                                       from '../../../modules/categories';
import ScrollArea                               from "react-scrollbar";
import Utils                                    from '../../../utils';
import ShowError                                from "../show-error";

class PullRequestModal extends Component
{
    constructor(props, context) {
        super(props, context);
        this.onClose                    = this.onClose.bind(this);
        this.onInputChange              = this.onInputChange.bind(this);
        this.onSubmit                   = this.onSubmit.bind(this);
        this.assignBulkCategoryValue    = this.assignBulkCategoryValue.bind(this);
        this.validateTableRows          = this.validateTableRows.bind(this);
        this.checkAllRows               = this.checkAllRows.bind(this);
        this.removeRow                  = this.removeRow.bind(this);
        this.beforeSubmit               = this.beforeSubmit.bind(this);
        this.afterSubmit                = this.afterSubmit.bind(this);
        this.toggleIndividualCheck      = this.toggleIndividualCheck.bind(this);
        this.onDone                     = this.onDone.bind(this);
        this.validateRows               = this.validateRows.bind(this);
        this.existInModalTable          = this.existInModalTable.bind(this);
        this.onCloseModal               = this.onCloseModal.bind(this);
        this.state                      = {
                                            closeClass : '',
                                            components : this.createComponents(),
                                            categories : assemblyCategories(),
                                            bulkCategorySelect: {value: ''},
                                            continueBtn : { class: 'disabled', text: 'Continue', onClick: this.onSubmit },
                                            bulkChecked : false,
                                            isTableValid: false,
                                            lastChecked    : -1,
                                            checkedComponents: [],
                                            header      : { stepCount: 1, description: 'A Category is required to pull part numbers', class: ''},
                                            subAssemblies: JSON.parse(JSON.stringify(this.props.subAssemblies)),
                                            parts        : JSON.parse(JSON.stringify(this.props.parts)),
                                            isValidatingName: false,
                                            showErrorModal: false,
                                            errorMessage: ""
                                           };
    }

    createComponents()
    {
        let components = JSON.parse(JSON.stringify(this.props.checkedComponents));

        // Filter out components that should be excluded from BOM
        components = components.filter(component => !component.excludeFromBom);

        components.forEach((component) => {
            component.name            = getComponentName(component);
            component.isCheckedOnTable= false;
            component.status          = '';
            component.alreadyExist    =  false;
            component.previousCat     = component.properties.category ? component.properties.category.value : '';
            component.previousCpn     = component.properties.cpn ? component.properties.cpn.value : '';
            component.configurationString = component.configurationString || component.fullConfiguration;
        });
        return components;
    }

    componentDidMount()
    {
        const fetchComponents = (components) => {
            let payload = getPayloadForFetchingComponents(components);
            if(payload && payload.length > 0)
            {
                this.props.dispatch(componentActions.getCmpsInBulk({components: payload, products: [], successCb}));
            }
        }

        const handleResponse = (response) =>
        {
            for(let responseComponent of response)
            {
                let componentsList = components.filter(item => item.name === responseComponent.name);
                for(let cmp of componentsList)
                {
                    if(responseComponent.alreadyExist && cmp)
                    {
                        cmp.alreadyExist = true;
                        cmp.status       = 'contain-errors';
                    }
                }
            }
            this.setState({ components, isValidatingName: false });
            fetchComponents(components);
        };

        const successCb = (err, response) =>
        {
            if(err){}
            else
            {
                for(let responseComponent of response)
                {
                    let component = components.find(item => item.properties.duroId.value === responseComponent._id);
                    component.properties.lastModified.value = responseComponent.lastModified;
                }
            }
        };

        const getPayloadForNameValidation = (components) =>
        {
            let payload = [];
            for(let component of components)
            {
                if(!component.previousCpn)
                {
                    payload.push(component.name);
                } else {
                    console.log('FOUND COMPONENT WITH PREVIOUS CPN', component);
                }
            }
            if(payload.length > 0)
            {
                payload.successCb = handleResponse;
                return payload;
            }
            return null;
        };

        const getPayloadForFetchingComponents = (components) =>
        {
            let payloadData = [];
            components.forEach((item) => {
                if(item.properties.duroId.value)
                    payloadData.push(item.properties.duroId.value);
            })
            return payloadData;
        }

        const {components} = this.state;
        let payload = getPayloadForNameValidation(components);
        if(payload)
        {
            let _this = this;
            this.setState({isValidatingName: true}, () => {
                _this.props.dispatch(componentActions.isNameExistsBulk(payload));
            })
        }
        else
        {
            fetchComponents(components);
        }

        ReactTooltip.rebuild();
    }

    componentDidUpdate(prevProps, prevState)
    {
        let {checkedComponents,components} = this.state;
        if(prevState.components !== components)
        {
            this.validateTableRows()
        }
        ReactTooltip.rebuild();
    }

    onClose(e)
    {
        if(document.getElementsByClassName('popover-tool-tip-container')[0])
        {
            document.getElementsByClassName('popover-tool-tip-container')[0].style.zIndex = "99";
        }
        let state = this.state;
        state.closeClass = 'zoomOutAnimation';
        this.setState({closeClass: state.closeClass}, () => {
            let _this = this;
            _this.props.onClose(e);
        });
    }

    onInputChange(event, component)
    {
        const name  = event.target.name;
        const value = event.target.value;
        let {bulkCategorySelect, components} = this.state;

        switch(name)
        {
            case 'category-select':
                component.categoryWarning = false;
                component.properties.category.value = value;
                const category = findByName(value);
                if(component.previousCpn)
                {
                    component.status = 'updateCat';
                }
                component.properties.cpn.value = Utils.getCpnTag(category);
            break;
            case 'bulk-category-select':
                bulkCategorySelect.value = value;
                this.assignBulkCategoryValue(value);
            break;
        }
        this.setState({components, bulkCategorySelect}, this.validateTableRows());
    }

    assignBulkCategoryValue(value)
    {
        const category     = findByName(value);
        let {checkedComponents, components, bulkChecked}   = this.state;
        let setStateFlag = false;
        let checkedComponentsCopy = [];
        checkedComponents.forEach((component) =>
        {
            component.categoryWarning = false;
            if(component.type === 'Part' && permittedCategories("PART").includes(value))
            {
                setStateFlag = true;
                component.isCheckedOnTable = false;
                if(component.properties.category.value !== category.name)
                {
                    component.properties.category.value = category.name;
                    component.status                    = component.previousCpn ? 'updateCat' : component.status;
                    component.properties.cpn.value      = Utils.getCpnTag(category);
                }
            }
            else if(component.type === 'Assembly' && permittedCategories("ASSEMBLY").includes(value))
            {
                setStateFlag = true;
                component.isCheckedOnTable = false;
                if(component.properties.category.value !== category.name)
                {

                    component.isCheckedOnTable = false;
                    component.properties.category.value = category.name;
                    component.status                    = component.previousCpn ? 'updateCat' : component.status;
                    component.properties.cpn.value      = Utils.getCpnTag(category);
                }
            }
            else
            {
                component.categoryWarning = true;
                checkedComponentsCopy.push(component);
            }
        });
        if(setStateFlag)
        {
            if(checkedComponentsCopy.length !== components.length)
            {
                bulkChecked = false;
            }
            this.setState({bulkChecked, checkedComponents: checkedComponentsCopy});
        }
    }

    handleCheckboxShiftKey(i, lastChecked, components, checkedComponents, setChecked)
    {
        let current = i;
        let start = -1;
        let end  = -1;
        if(lastChecked < current && lastChecked !== -1)
        {
            start = lastChecked
            end = current
        }
        if(lastChecked > current && lastChecked !== -1)
        {
            start = current
            end = lastChecked
        }
        if(start >= 0 && end >= 0)
        {
            for(let j = start; j <= end; j++)
            {
                let pushComponent = !components[j].isCheckedOnTable && setChecked;
                components[j].isCheckedOnTable = setChecked;
                if(pushComponent) checkedComponents.push(components[j]);
            }
            components[i].isCheckedOnTable = setChecked;
            if(!setChecked)
            {
                checkedComponents = [];
                for(let index = 0; index <= components.length - 1; index++)
                {
                    if(components[index].isCheckedOnTable) checkedComponents.push(components[index]);
                }
            }
        }
        return { components, checkedComponents};
    }

    toggleIndividualCheck(i, event, component, setChecked)
    {
        let {checkedComponents, bulkChecked, components, lastChecked} = this.state;
        if (event.nativeEvent.shiftKey)
        {
            let data = this.handleCheckboxShiftKey(i, lastChecked, components, checkedComponents, setChecked);
            components = data.components;
            checkedComponents = data.checkedComponents;

        }
        else
        {
            component.isCheckedOnTable = setChecked;
            if(setChecked)
            {
                checkedComponents.push(component);
            }
            else
            {
                checkedComponents = this.removeComponentFromArray(component, checkedComponents);
            }
        }
        lastChecked = i;
        if(checkedComponents.length === components.length)
        {
            bulkChecked = true;
        }
        else
        {
            bulkChecked = false;
        }
        this.setState({ components, checkedComponents, bulkChecked, lastChecked});
    }

    removeComponentFromArray(componentToBeRemoved, components)
    {
        for(let i = 0; i < components.length; i++)
        {
            if(components[i].type === 'Part' && components[i].elementId === componentToBeRemoved.elementId && components[i].partId === componentToBeRemoved.partId)
            {
                if(componentToBeRemoved.configurationString)
                {
                    if(components[i].configurationString === componentToBeRemoved.configurationString)
                    {
                        components.splice(i, 1);
                    }
                }
                else if(!componentToBeRemoved.configurationString)
                {
                    components.splice(i, 1);
                }
            }
            else if(components[i].type === 'Assembly' && components[i].elementId === componentToBeRemoved.elementId)
            {
                if(componentToBeRemoved.configurationString)
                {
                    if(components[i].configurationString === componentToBeRemoved.configurationString)
                    {
                        components.splice(i, 1);
                    }
                }
                else if(!componentToBeRemoved.configurationString)
                {
                    components.splice(i, 1);
                }
            }
        }
        return components;
    }

    onSubmit()
    {

        const {components, isTableValid} = this.state;
        let cpnsAssigned = 0;
        let errorsFound  = 0;
        if(!isTableValid)
        {
            return;
        }

        const getSiblings = (currentComponent, componentsArray=components) =>
        {
            let siblingsHash = {};
            for(let parent of currentComponent.parents)
            {
                let siblings  = [];
                componentsArray.forEach((component) =>
                {
                    if(component.parents && component.parents.includes(parent))
                    {
                        siblings.push(component);
                    }
                });
                siblingsHash[parent] = siblings;
            }
            return siblingsHash;
        }

        const getComponentIfAlreadySynced = (currentComponent) =>
        {
            let parts    = this.state.parts;
            let response = null;
            if(currentComponent.type === 'Part')
            {
                const parts = this.state.parts;
                for(let part of parts)
                {
                    if(currentComponent.elementId === part.elementId && currentComponent.partId === part.partId && part.status === 'synced')
                    {
                        if(currentComponent.configurationString && currentComponent.configurationString === part.configuration)
                        {
                            response = part.properties;
                        }
                        else if(!currentComponent.configurationString && currentComponent.elementId === part.elementId)
                        {
                            response = part.properties;
                        }
                    }
                }
            }
            else if(currentComponent.type === 'Assembly')
            {
                const assemblies = this.state.subAssemblies;
                for(let assembly of assemblies)
                {
                    if(currentComponent.elementId === assembly.elementId && assembly.status === 'synced')
                    {
                        if(currentComponent.configurationString && currentComponent.configurationString === assembly.configuration)
                        {
                            response = assembly.properties;
                        }
                        else if(!currentComponent.configurationString && currentComponent.elementId === assembly.elementId)
                        {
                            response = assembly.properties;
                        }
                    }
                }

            }
            return response;
        }

        const pushComponentInSyncedArray = (currentComponent, response, incrementCpnAssigned=true) =>
        {
            if(incrementCpnAssigned)
            {
                cpnsAssigned++;
                currentComponent.status = 'synced';
            }
            else
            {
                currentComponent.status = 'already-synced';
                response.id = response.duroId.value;
                response.lastModified = response.lastModified.value;
            }

            if(currentComponent.type === 'Part')
            {
                const parts = this.state.parts;
                for(let part of parts)
                {
                    if(currentComponent.elementId === part.elementId && currentComponent.partId === part.partId)
                    {
                        if(currentComponent.configurationString && currentComponent.configurationString === part.configuration)
                        {
                            part.properties = response;
                            part.status     = 'synced';
                        }
                        else if(!currentComponent.configurationString && currentComponent.elementId === part.elementId)
                        {
                            part.properties = response;
                            part.status     = 'synced';
                        }
                    }
                }
            }
            else if(currentComponent.type === 'Assembly')
            {
                const assemblies = this.state.subAssemblies;
                for(let assembly of assemblies)
                {
                    if(currentComponent.elementId === assembly.elementId)
                    {
                        if(currentComponent.configurationString && currentComponent.configurationString === assembly.configuration)
                        {
                            assembly.properties = response;
                            assembly.status     = 'synced';
                        }
                        else if(!currentComponent.configurationString)
                        {
                            assembly.properties = response;
                            assembly.status     = 'synced';
                        }
                    }
                }
            }
        }

        const sendRequest = (component, callback) =>
        {
            const handleResponse = (response) =>
            {
                callback(response);
            };
            let childrenIds = [];
            if(component.type === 'Assembly')
            {
                component.nestedChildren.forEach((currentComponent) =>
                {
                    const componentIfAlreadySynced = getComponentIfAlreadySynced(currentComponent);
                    const existInModalTable = this.existInModalTable(currentComponent);
                    currentComponent = existInModalTable;
                    if(!currentComponent.isChecked || !existInModalTable.length)
                    {
                        return;
                    }
                    else if(componentIfAlreadySynced)
                    {
                        childrenIds.push({component: componentIfAlreadySynced.id, quantity: currentComponent.quantity ? currentComponent.quantity : 1, refDes: "", notes: ""});
                    }
                    else if(currentComponent.status !== 'synced')
                    {
                        if(currentComponent.type === 'Part')
                        {
                            if(componentIfAlreadySynced)
                            {
                                childrenIds.push({component: componentIfAlreadySynced.id, quantity: currentComponent.quantity ? currentComponent.quantity : 1, refDes: "", notes: ""});
                            }
                            else
                            {
                                sendRequest(currentComponent, (response) =>
                                {
                                    if(response.errors)
                                    {
                                        errorsFound++;
                                        components[x].status       = 'contain-errors';
                                        if(response.errors && response.errors[0].path === 'name')
                                        {
                                            components[x].alreadyExist = true;
                                        }
                                        components[x].errorMsg     = response.errors && response.errors[0].message;
                                    }
                                    else
                                    {
                                        pushComponentInSyncedArray(currentComponent, response);
                                        currentComponent.properties.duroId = {value: response.id};
                                        currentComponent.properties.lastModified = {value: response.lastModified};
                                        currentComponent.properties.cpn.value = response.cpn;
                                        currentComponent.properties.cpn = {value: response.cpn, propertyId: currentComponent.properties.cpn.propertyId};
                                        childrenIds.push({component: response.id, quantity: currentComponent.quantity ? currentComponent.quantity : 1, refDes: "", notes: ""});
                                    }
                                });
                            }

                        }
                        else
                        {
                            if(componentIfAlreadySynced)
                            {
                                childrenIds.push({component: componentIfAlreadySynced.id, quantity: currentComponent.quantity ? currentComponent.quantity : 1, refDes: "", notes: ""});
                            }
                        }
                    }
                });
            }

            const payload        = this.getData(component, childrenIds);
            if (payload.lastModified === "") {
                delete payload.lastModified
            }
            payload.successCb    = handleResponse;
            this.props.dispatch(componentActions.createPullRequest(payload));
        }

        // let mainChildrenHash      = {};

        // const pushSiblingsInMainHash = (siblings) =>
        // {
        //     for (let [key, value] of Object.entries(siblings))
        //     {
        //         if(!mainChildrenHash.hasOwnProperty(key))
        //         {
        //             mainChildrenHash[key] = value;
        //         }
        //     }
        // };

        let x = 0;

        const loopCallback = () =>
        {
            x++;
            if(x < components.length)
            {
                loopComponentsArray();
            }
            else
            {
                this.afterSubmit(cpnsAssigned, errorsFound, components);
                return;
            }

        }

        const loopComponentsArray = () =>
        {
            if(x >= components.length)
            {
                return;
            }
            if(components[x].previousCpn !== '' && components[x].status !== 'updateCat')
            {
                pushComponentInSyncedArray(components[x], components[x].properties, false);
                loopCallback();
            }
            else if(components[x].previousCpn === components[x].properties.cpn.value && components[x].status !== 'updateCat')
            {
                loopCallback();
            }
            else
            {
                components[x].status = 'being-synced';
                this.setState({ components }, () =>
                {
                    // if(components[x].status !== 'synced')
                    // {
                        const checkIfAlreadySynced = getComponentIfAlreadySynced(components[x]);
                        if(!checkIfAlreadySynced)
                        {
                            sendRequest(components[x], (response) =>
                            {
                                if(typeof response === 'string')
                                {
                                    this.setState({showErrorModal: true, errorMessage: response});
                                }
                                else if(response.errors)
                                {
                                    errorsFound++;
                                    components[x].status       = 'contain-errors';
                                    if(response.errors[0].path === 'name')
                                    {
                                        components[x].alreadyExist = true;
                                    }
                                    components[x].errorMsg     = response.errors[0].message;
                                }
                                else
                                {
                                    components[x].properties.duroId = {value: response.id};
                                    components[x].properties.cpn    = {value: response.cpn, propertyId: components[x].properties.cpn.propertyId};
                                    components[x].properties.lastModified = {value: response.lastModified};
                                    pushComponentInSyncedArray(components[x], response);
                                }
                                loopCallback();
                                // x++;
                                // if(x < components.length)
                                // {
                                //     loopComponentsArray();
                                // }
                                // else
                                // {
                                //     this.setState({ components });
                                // }
                            });
                        }
                        else
                        {
                            components[x].properties.duroId = {value: checkIfAlreadySynced.id};
                            components[x].properties.lastModified = {value: checkIfAlreadySynced.lastModified};
                            components[x].properties.cpn    = {value: checkIfAlreadySynced.cpn, propertyId: components[x].properties.cpn.propertyId};
                            // components[x].properties = checkIfAlreadySynced;
                            components[x].status     = 'synced';
                            loopCallback();
                            // x++;
                            // if(x < components.length)
                            // {
                            //     loopComponentsArray();
                            // }
                            // else
                            // {
                            //     this.setState({ components });
                            // }
                        }
                    // }
                    // else
                    // {
                        // loopCallback();
                        // x++;
                        // if(x < components.length)
                        // {
                        //     loopComponentsArray();
                        // }
                        // else
                        // {
                        //     this.setState({ components });
                        // }
                    // }
                });
            }
        }

        // const generateTree = () =>
        // {
        //     for(let component of components)
        //     {
        //         if(component.parents)
        //         {
        //             const siblings         = getSiblings(component, components);
        //             pushSiblingsInMainHash(siblings);
        //         }
        //     }
        // }
        // generateTree();
        this.beforeSubmit();
        loopComponentsArray();
    }

    beforeSubmit()
    {
        let {header, continueBtn}   = this.state;
        header                      = { stepCount: 2, description: 'Please wait ...' };
        continueBtn                 = { text: 'Done', class: 'disabled' };
        this.setState({ header, continueBtn });
    }

    onDone()
    {
        const {components} = this.state;
        this.props.afterPullRequestSubmit(components);
    }

    existInModalTable(cmpToBeSearched)
    {
        const {components} = this.state;
        const response = components.filter((cmp) =>
        {
            if(cmpToBeSearched.type === 'Part' && cmp.documentId === cmpToBeSearched.documentId && cmp.elementId === cmpToBeSearched.elementId && cmp.partId === cmpToBeSearched.partId)
            {
                return cmp;
            }
            if(cmpToBeSearched.type === 'Assembly' && cmp.documentId === cmpToBeSearched.documentId && cmp.elementId === cmpToBeSearched.elementId)
            {
                return cmp;
            }
        });
        return response;
    }

    afterSubmit(cpnsAssigned, errorsFound, components)
    {
        let {header, continueBtn}   = this.state;
        let descriptionText         = `${cpnsAssigned} of ${cpnsAssigned+errorsFound} CPNs assigned.`;
        if(errorsFound > 0)
        {
            descriptionText = `${descriptionText} ${errorsFound} Error(s) found.`;
        }
        header.description  = descriptionText;
        header.class        = 'bold-text';
        continueBtn         = { text: 'Done', class: '', onClick: () => this.onDone()};
        this.setState({ header, continueBtn, components });
    }

    removeDuplicateChildrenIds(childrenIds)
    {
        const filteredArr = childrenIds.reduce((outputArray, current) =>
        {
            const x = outputArray.find(item => item.component === current.component);
            if (!x)
            {
                return outputArray.concat([current]);
            }
            else
            {
                return outputArray;
            }
        }, []);
        return filteredArr;
    }

    getData(component, childrenIds=[])
    {
        const searchParams = this.props.queryParams;
        let configurations = null;
        if(childrenIds.length > 1)
        {
            childrenIds = this.removeDuplicateChildrenIds(childrenIds);
        }

        if(component.type === 'Assembly')
        {
            if(component.nestedChildren.length > 0)
            {
                childrenIds = this.populateSyncedChildrens(component, childrenIds);
            }
            configurations = this.extractAssociatedConfigurations(this.props.assemblies, component);
        }
        let payload        = null;

        payload            = {
                                name           : component.name,
                                category       : component.properties.category,
                                cpn            : {value: component.previousCpn},
                                elementId      : component.elementId,
                                documentId     : component.documentId,
                                workspaceId    : searchParams.workspaceId,
                                href           : component.href,
                                type           : component.type,
                                children       : childrenIds,
                                documentVersion: component.documentVersion,
                                partId         : component.partId,
                                parent         : component.parent ? component.parent : null,
                                quantity       : component.quantity,
                                configurationString: component.configurationString || component.fullConfiguration,
                                configurations,
                                isStandardContent: component.isStandardContent,
                                linkDocumentId  : searchParams.documentId,
                                onShapeCompanyId: searchParams.companyId,
                                lastModified: component.properties.lastModified.value,
                                isConfigured: component.isConfigured,
                            };
        return payload;
    }

    populateSyncedChildrens(component, childrenIds)
    {
        for(let cmp of component.nestedChildren)
        {
            if(cmp.suppressed) continue;
            if((!cmp.isChecked || (cmp.isChecked && !this.existInModalTable(cmp).length)) && (cmp.componentDuro || (cmp.properties && cmp.properties.duroId && cmp.properties.duroId.value)))
            {
                let id = cmp.componentDuro ? cmp.componentDuro._id : cmp.properties && cmp.properties.duroId && cmp.properties.duroId.value;
                let alreadyExist = childrenIds.find(item => item.component === id);
                if(!alreadyExist)
                {
                    childrenIds.push({component: id, quantity: cmp.quantity ? cmp.quantity : 1, refDes: "", notes: ""});
                }
            }
        }
        return childrenIds;
    }

    checkAllRows()
    {
        let {bulkChecked, components, checkedComponents}   = this.state;
        bulkChecked                     = !bulkChecked;
        if(bulkChecked)
        {
            components.forEach((component) =>
            {
                let isLinkedComponent = component.documentVersion && !component.isStandardContent;
                if(!isLinkedComponent)
                {
                    component.isCheckedOnTable = bulkChecked;
                    checkedComponents.push(component);
                }
            });
        }
        else
        {
            components.forEach((component) =>
            {
                let isLinkedComponent = component.documentVersion && !component.isStandardContent;
                if(!isLinkedComponent)
                {
                    component.isCheckedOnTable = bulkChecked;
                }
            });
            checkedComponents = [];
        }
        this.setState({ bulkChecked, components, checkedComponents });
    }

    validateTableRows()
    {
        let {continueBtn, isTableValid} = this.validateRows();
        this.setState({ continueBtn, isTableValid });
    }

    validateRows(){
        let {components, isTableValid, continueBtn, checkedComponents} = this.state;
        components.some((component) =>
        {
            if(!component.properties.category.value || component.status === 'contain-errors')
            {
                continueBtn.class  = 'disabled';
                isTableValid       = false;
                return true;
            }
            else
            {
                continueBtn.class  = '';
                isTableValid       = true;
            }
        });
        if(components.length === 0)
        {
            isTableValid         = false;
            continueBtn.class    = 'disabled';
        }
        return {continueBtn, isTableValid};
    }

    removeRow(componentToBeRemoved)
    {
        let {components,checkedComponents, bulkChecked}   = this.state;
        components         = this.removeComponentFromArray(componentToBeRemoved, components);
        checkedComponents  = this.removeComponentFromArray(componentToBeRemoved, checkedComponents);
        if(checkedComponents.length === components.length && components.length > 0)
        {
            bulkChecked = true;
        }
        else
        {
            bulkChecked = false;
        }

        let {continueBtn, isTableValid} = this.validateRows();
        this.setState({ components, checkedComponents, bulkChecked, continueBtn, isTableValid });
    }

    sortedComponents = (components) =>
    {
        let resultantArray = [...components].sort((cmpA,cmpB) =>
            {
                if(cmpA.displayOrder > cmpB.displayOrder)
                {
                    return 1;
                }
                else
                {
                    return -1;
                }
            });
        return resultantArray;
    };

    extractAssociatedConfigurations(assemblies, component)
    {
        let configurations = [];
        for(let assembly of assemblies)
        {
            if(component.elementId === assembly.id && assembly.configurationString !== component.configurationString)
            {
                configurations.push(assembly);
            }
        }
        return configurations;
    }

    onCloseModal()
    {
        this.setState({showErrorModal: false, errorMessage: ""});
    }

    render()
    {
        const {components, bulkCategorySelect, categories, continueBtn, bulkChecked, isTableValid, checkedComponents, formStatus, header, isValidatingName, showErrorModal, errorMessage} = this.state;

        const tBody   = this.sortedComponents(components).map((component, i) =>
                        (
                            <TableRow
                                key={generateKey()}
                                position={i}
                                onInputChange={this.onInputChange}
                                component={component}
                                removeRow={this.removeRow}
                                toggleIndividualCheck={this.toggleIndividualCheck}
                            />
                        ));
        const createOptions = () =>
        {
            let options = [];
            categories.forEach((category) =>
            {
                options.push(
                            <option
                                key={generateKey()}
                                value={category.name}
                                disabled={category.type === "HEADING"}
                            >
                                {Utils.getCategoryName(category)}
                            </option>
                           );
            });
            return options;
        }
        let markup =
                    <div>
                    {
                        showErrorModal &&
                        <ShowError errorMessage={errorMessage} hideErrorModal={this.onCloseModal}/>
                    }
                    <div className={`pull-request-modal zoomInAnimation ${this.props.className} ${this.state.closeClass}`}>
                            <header className={`warning modal-header ${header.class}`}>
                                <div className='header-heading'>
                                    <InlineIcon>
                                        <HeaderIconSrc/>
                                    </InlineIcon>
                                    <h4>Step {header.stepCount} of 2 </h4>
                                    <div className='description'>{header.description}</div>
                                </div>
                                <div className='btn-holder'>
                                    <button onClick={(e) => this.onClose(e)} type='button' className={`btn-cancel ${continueBtn.text === 'Done' ? 'display-none' : ''}`}>
                                        Cancel
                                    </button>
                                    <button onClick={continueBtn.onClick} type='button' className={`spinner-btn btn-header btn-continue  ${isValidatingName ? 'disabled' : continueBtn.class}`}>
                                        <span className='continue-btn-text disabled'>{isValidatingName ? 'Validating' : continueBtn.text}</span>
                                    </button>
                                </div>
                            </header>
                            <div className='modal'>
                                <div className='modal-content'>
                                    <div className={`select-category-holder`}>
                                        <h3>Select Categories</h3>
                                        <div className={`select-holder ${continueBtn.text === 'Done' ? 'display-none' : ''} ${checkedComponents.length > 0 ? 'enabled-bulk-category' : 'disabled-bulk-category'}`}>
                                            <select
                                                className={`bulk-category-select ${checkedComponents.length > 0 ? '' : 'disabled'}`}
                                                name='bulk-category-select'
                                                value={bulkCategorySelect.value}
                                                onChange={(event) => this.onInputChange(event)}
                                            >
                                                <option value='' disabled>Bulk select category</option>
                                                {createOptions()}
                                            </select>
                                        </div>
                                        <InlineIcon className={`info-icon ${continueBtn.text === 'Done' ? 'display-none' : ''}`} tooltip='Select Rows to enable bulk editing' tooltipPlace='top'>
                                            <BulkCategoryIconSrc/>
                                        </InlineIcon>
                                    </div>
                                    <ScrollArea
                                        className="area"
                                        contentClassName="content"
                                    >
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <label className={`checkbox-holder ${header.stepCount === 2 ? 'display-none' : ''}`}>
                                                        <input onChange={this.checkAllRows} checked={bulkChecked} type='checkbox' />
                                                        <span className='checkmark'></span>
                                                        </label>
                                                    </th>
                                                    <th>CPN</th>
                                                    <th>CATEGORY</th>
                                                    <th>TYPE</th>
                                                    <th>NAME</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {tBody}
                                            </tbody>
                                        </table>
                                    </ScrollArea>
                                </div>
                            </div>
                        </div>
                        </div>
        return markup;
    }
}

export default PullRequestModal;
