import     './index.scss';
import React from "react";
import InlineIcon from '../icon/inline-icon.jsx';
import ErrorIcon from '../../../assets/icons/error-icon';
import WarningModalBox from "../warning-modal-box/index.jsx";

const ShowError = (props) =>
{
    let {errorMessage, hideErrorModal, errorData}= props;

    return( <span className='show-error'>
               <WarningModalBox className='warning-modal' showCrossIcon={true} onClose={hideErrorModal}>
                    <div className='error-modal-inner'>
                        <InlineIcon>
                            <ErrorIcon/>
                        </InlineIcon>
                        <h1>Error Message</h1>
                        {
                            errorData &&
                            <div>
                            <span className="warning-text"> Following components cannot be updated. </span>
                            {
                                errorData.map((item) => {
                                    return <span className='error-cpn'>{item.cpn}</span>
                                })
                            }
                            </div>
                        }
                        <p>{errorMessage}</p>
                        <div className='bottom-section'>
                            {
                                <button className='btn' onClick={hideErrorModal}>GOT IT</button>
                            }
                        </div>
                    </div>
                </WarningModalBox>
            </span>
    )
}

export default ShowError;
