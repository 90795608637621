import React from "react";
import WarningModalBox          from "../warning-modal-box/index.jsx";
import InlineIcon               from '../icon/inline-icon.jsx';
import BeingSyncedIconSrc       from '../../../assets/icons/being-synced-icon';
import SyncedIconSrc            from '../../../assets/icons/synced-icon';
import ReactHtmlParser          from "react-html-parser";

const ModalBox = (props) => {

    let {icon, modal, onClick} = props;

    return(
        <WarningModalBox className='refresh-assembly-modal'>
            <div className='refresh-modal-header'>
                <InlineIcon className='modal-sync-icon'>
                    {
                        icon ? icon :
                        modal.displayBtn ?
                        <SyncedIconSrc/> :
                        <BeingSyncedIconSrc/>
                    }
                </InlineIcon>
                <h4>{modal.headerText}</h4>
            </div>
            <div className='modal-content-inner'>
                <p>{ReactHtmlParser(modal.bodyText)}</p>
                {
                    modal.displayBtn &&
                    <button className='btn' onClick={onClick}>OK</button>
                }
            </div>
        </WarningModalBox>
        );
}

export default ModalBox;
