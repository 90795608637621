import * as componentActions from '../actions/component';
import { handleActions }     from "redux-actions"
import { getPropertyObj }    from '../helpers/components-helper';

function getInitialState ()
{
  let stateObj = {
    components          : [],
    tab                 : 'Specifications',
    view                : 'view',
    isLoading           : false,
    componentDuro       : {},
    OnShapeData         : null,
    actualComponentDuro : null,
    prevComponentState  : {},
    needToSyncOnOnshape : false,
    RemoteComponentObj  : {},
    inputs              : {
                            name :
                            {
                                value : "",
                                valid : true,
                                class : ""
                            },
                            category :
                            {
                                value : "",
                                valid : true,
                                class : ""
                            },
                            revision :
                            {
                                value : "",
                                valid : true,
                                class : ""
                            },
                            status :
                            {
                                value : "",
                                valid : true,
                                class : ""
                            },
                            eid :
                            {
                                value : "",
                                valid : true,
                                class : ""
                            },
                            description :
                            {
                                value : "",
                                valid : true,
                                class : ""
                            },
                            manufacturers :
                            {
                                value : [],
                                valid : true,
                                class : ""
                            },
                            specs :
                            [

                            ],
                            procurement :
                            {
                                value : "",
                                valid : true,
                                class : ""
                            }
                          }
                }

    return JSON.parse(JSON.stringify(stateObj))
}

let initialState = getInitialState()

function componentSucess(state, { payload })
{
	  state.componentDuro        = payload.componentDuro ? payload.componentDuro : {};
    state.actualComponentDuro  = JSON.parse(JSON.stringify(payload.componentDuro));
    state.OnShapeData          = payload.onShapeData;
    state.partId 	             = state.OnShapeData.partId;
    state.elementId            = state.OnShapeData.elementId;
    state.isLoading            = false;
    return {...state};
}

function componentError(state, { payload })
{
  return {...state, ...payload}
}

function isLoading(state, { payload })
{
  state.isLoading = payload;
  return {...state}
}

function isSyncing(state, { payload })
{
  if(!state.needToSyncOnOnshape)
  {
    state.isSyncing = payload;
  }
  return {...state}
}

function resetState(state = initialState)
{
    state = initialState;
    state.isLoading = false;
    return {...state};
}

function resetFormInputs(state)
{
    let {inputs} = {...getInitialState()};
    state = {...state, inputs: inputs};
    return {...state};
}

function switchView(state=initialState, { payload })
{
  state.view = payload.to;
  if(payload.retrievePrevComponent)
  {
    state.componentDuro      = state.prevComponentState
  }
  else if(payload.preservePrevComponent)
  {
    state.prevComponentState = {...getInitialState()}
    state.inputs.specs       = state.componentDuro.specs ? state.componentDuro.specs : []
    state.prevComponentState = JSON.parse(JSON.stringify(state.componentDuro))
  }
  return {...state}
}

function switchTab(state, { payload })
{
  state.tab = payload.tab;
  return {...state}
}

function SynctoDuro(state, { payload })
{
  if(payload && payload.errors && !payload.success)
  {
    state.RemoteComponentObj  = {...getInitialState()}
    state.isSyncing           = false
    state.errors              = payload.errors
    if(state.errors && state.errors.length > 0)
    {
      state.errors = state.errors.map(function(error, index)
      {
        let message = error.message && JSON.parse(error.message).message ? JSON.parse(error.message).message : error.message
        if(message === 'Remote is newer')
        {
          state.RemoteComponentObj = JSON.parse(error.message).existingComp
        }
        return error
      });
    }
  }
  else
  {
    if(state.needToSyncOnOnshape)
    {
      state.needToSyncOnOnshape = false
      state.isModified          = false
      state.isSyncing           = false
    }
    else
    {
      state.componentDuro       = payload
      state.actualComponentDuro = JSON.parse(JSON.stringify(payload))
      state.isModified          = false
      state.isSyncing           = false
      state.isSynced            = true
    }
  }
  return {...state}
}

function SyncwithRemoteObject(state)
{
  state.needToSyncOnOnshape = true
  state.componentDuro       = JSON.parse(JSON.stringify(state.RemoteComponentObj))
  state.actualComponentDuro = JSON.parse(JSON.stringify(state.RemoteComponentObj))
  state.errors              = {}
  return {...state}
}

function hideSyncedModal(state)
{
  state.isSynced  = false
  state.errors    = {}
  return {...state}
}

function clearErrors(state)
{
  state.errors = null
  return {...state}
}

function updateEditComponentFormFields(state, { payload })
{
  state.componentDuro = payload.ComponentDuro
  return {...state}
}

// This is similar like switch statement to handle actions and return new state
export default handleActions({
  [componentActions.componentSucess]                : componentSucess,
  [componentActions.componentError]                 : componentError,
  [componentActions.isLoading]                      : isLoading,
  [componentActions.SynctoDuro]                     : SynctoDuro,
  [componentActions.clearErrors]                    : clearErrors,
  [componentActions.isSyncing]                      : isSyncing,
  [componentActions.resetState]                     : resetState,
  [componentActions.switchView]                     : switchView,
  [componentActions.switchTab]                      : switchTab,
  [componentActions.hideSyncedModal]                : hideSyncedModal,
  [componentActions.resetFormInputs]                : resetFormInputs,
  [componentActions.SyncwithRemoteObject]           : SyncwithRemoteObject,
  [componentActions.updateEditComponentFormFields]  : updateEditComponentFormFields
}, initialState);
