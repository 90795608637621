import React                              from "react";
import authService                        from "../services/authService";
import {connect}                          from 'react-redux';
import {bindActionCreators}               from 'redux';
import * as Actions                       from '../actions/user';
import * as componentActions              from '../actions/component';

function Auth(props)
{
    authService.init();
    authCheck();

    function authCheck()
    {
        authService.on("onAutoLogin", (response) =>
        {
            let user  = response.data;
            if(!user || !user.onShapeKeys)
            {
                props.history.push("/signin");
            }
            else
            {
                props.setCurrentUser(user);
                props.setCategories();
                props.history.push("/");
            }
        });

        authService.on("onUnauthorize", (res) =>
        {
            if(props.history.location.pathname !== "/signin")
            {
                props.history.push("/signin");
            }
        });
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        setCurrentUser  : Actions.setCurrentUser,
        setCategories   : componentActions.setCategories,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
