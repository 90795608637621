import                          "./index.scss"
import React, { Component } from 'react';
import ShowComponent        from '../show-component/index.jsx';

class RightSideBar extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { ...props };
    }

    render() {
        return (
            <div className="duro">
                <ShowComponent/>
            </div>
        );
    }
}

export default RightSideBar;
