import                                               './index.scss';
import React, { useState, useEffect }           from 'react';
import RemoveIconSrc                            from '../../../../assets/icons/remove-icon';
import InlineIcon                               from '../../icon/inline-icon.jsx';
import {assemblyCategories, partCategories}     from '../../../../modules/categories';
import {generateKey, checkToolTipAppearance}    from '../../../../helpers/components-helper';
import PartIconSrc                              from '../../../../assets/icons/part-icon-dark.jsx';
import AssemblyIconSrc                          from '../../../../assets/icons/assembly-icon-dark.jsx';
import WarningIconSrc                           from '../../../../assets/icons/warning-icon';
import BeingSyncedIcon                          from '../../../../assets/icons/being-synced-icon';
import SyncedIcon                               from '../../../../assets/icons/synced-icon';
import DuplciateErrorIcon                       from '../../../../assets/icons/error-icon';
import ReactTooltip                             from 'react-tooltip'
import AssemblyConfigurationIconDark            from '../../../../assets/icons/assembly-configurations-icon-dark';
import PartConfigurationIconDark                from '../../../../assets/icons/part-configurations-icon-dark.jsx';
import Utils                                    from '../../../../utils';

const TableRow = ((props) =>
{
    const {component}     = props;
    const cpn             = component.properties.cpn;
    const cmpCategory     = component.properties.category;
    const categories      = component.type === 'Assembly' ? assemblyCategories() : partCategories();
    const [isChecked, setIsChecked] = useState(component.isCheckedOnTable);
    const [categoryOptions, setCategoryOptions] = useState(null);

    const createOptions = () =>
    {
        let options = [];
        if(component.documentVersion && !component.isStandardContent)
        {
            options.push(
                <option
                    key={generateKey()}
                    value={cmpCategory.value}
                    disabled={true}
                >
                    {cmpCategory.value}
                </option>
                );
            return options;
        }
        categories.forEach((category) =>
        {
            options.push(
                        <option
                            key={generateKey()}
                            value={category.name}
                            disabled={(component.previousCat && category.name === component.previousCat) || category.type === "HEADING"}
                        >
                            {Utils.getCategoryName(category)}
                        </option>
                       );
        });
        return options;
    }

    useEffect(() =>
    {
        setCategoryOptions(createOptions());
    }, []);

    useEffect(() =>
    {
        if(component.status !== '' || component.categoryWarning)
        {
            ReactTooltip.rebuild();
        }
    });

    const toggleCheckState = (event) =>
    {
        setIsChecked(!isChecked);
        props.toggleIndividualCheck(props.position, event, component, !isChecked);
    }

    const onInputChange = ((event, component) =>
    {
        props.onInputChange(event, component);
    });
    const cpnValue = cpn.value ? cpn.value : Utils.getCpnTag();
    const cmpType  = component.type;
    const isConfigured  = component.configurationString && component.configurationString !== 'default' && !component.isStandardContent;
    const linked   = component.documentVersion && !component.isStandardContent ? true : false;
    const cpnIcon  = component.status === 'updateCat' ?
                    <InlineIcon tooltip='CPN will be changed' tooltipPlace='top'>
                        <WarningIconSrc/>
                    </InlineIcon>
                    :
                    component.status === 'being-synced' ?
                    <InlineIcon>
                        <BeingSyncedIcon/>
                    </InlineIcon>
                    :
                    component.status === 'synced' ?
                    <InlineIcon>
                        <SyncedIcon/>
                    </InlineIcon>
                    :
                    component.status === 'contain-errors' ?
                    <InlineIcon tooltip={`${component.errorMsg ? component.errorMsg : 'Name is taken'}`} tooltipPlace='top'>
                        <DuplciateErrorIcon/>
                    </InlineIcon>
                    :
                    component.categoryWarning ?
                    <InlineIcon tooltip='Component could not be updated with selected category' tooltipPlace='top'>
                        <WarningIconSrc/>
                    </InlineIcon>
                    :
                    null;

    return (
                <tr className={`${component.status} ${linked ? 'disabled' : ''}`}>
                    <td>
                        <label className={`checkbox-holder ${component.status === 'being-synced' || component.status === 'synced' || component.status === 'already-synced' ? 'display-none' : ''}`}>
                        <input type='checkbox' checked={isChecked} onChange={!linked ? (e) => toggleCheckState(e) : (e) => {}}/>
                        <span className='checkmark'></span>
                        </label>
                    </td>
                    <td>

                        <div className='cpn'>
                            <span className={component.status === 'synced' ? '' : 'cpn-value'}>
                                {cpnValue}
                            </span>
                            {cpnIcon}
                        </div>
                    </td>
                    <td>
                        {
                            component.status === 'being-synced' || component.status === 'synced' || component.status === 'already-synced'?
                            <span>
                                {cmpCategory.value}
                            </span>
                            :
                            <div className={`select-holder ${cmpCategory && cmpCategory.value ? '' : 'set-category'}`}>

                                <select
                                    name='category-select'
                                    value={cmpCategory && cmpCategory.value ? cmpCategory.value : ''}
                                    onChange={(event) => onInputChange(event, component)}
                                >
                                    <option value="" disabled={true}>Set Category</option>

                                    {categoryOptions}
                                </select>
                            </div>
                        }
                    </td>
                    <td>
                        <InlineIcon className={ `type-icon ${cmpType === 'Assembly' ? 'assembly-icon' : ''}`}>
                            { cmpType === "Part" ? isConfigured ? <PartConfigurationIconDark/> : <PartIconSrc/> : isConfigured ? <AssemblyConfigurationIconDark/> : <AssemblyIconSrc/> }
                        </InlineIcon>
                    </td>
                    <td>
                        <div data-tip=''
                            data-tootip-text={component.name}
                            data-place='top'
                            onMouseOver={(e) => checkToolTipAppearance(e, component.name)}
                            className="component-name"
                            >{component.name}
                        </div>
                    </td>
                    <td>
                        <InlineIcon
                            onClick={!linked ? () => props.removeRow(component): undefined}
                            className={`remove-icon ${component.status === 'being-synced' || component.status === 'synced' || component.status === 'already-synced' ? 'disabled' : ''}`}>
                            <RemoveIconSrc/>
                        </InlineIcon>
                    </td>
                </tr>
           );
});

export default TableRow;
