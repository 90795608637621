export const saveTokens = (response) => {
    if (response.success) {
        response.data.createdTime = new Date();
        localStorage.setItem('tokensData', JSON.stringify(response.data));
    }
};

export const fetchToken = () => {
    let data = {};
    if (localStorage.getItem('tokensData')) {
        const tokens = localStorage.getItem('tokensData');
        data = JSON.parse(tokens);
    } else {
        return '';
    }
    return data;
};

export const checkTokenStatus = () => {
    const data = fetchToken();
    let state = '';
    if (data === '') {
        state = 'notPresent';
    } else {
        const first = new Date(data.createdTime);
        const second = new Date();
        const diff = second.getTime() - first.getTime();
        const minutes = Math.floor(diff / 1000 / 60);
        if (minutes >= 55) {
            // As token expires after 60 min so we are refreshing it begore 5 min.
            state = 'doRefresh';
        } else {
            state = 'permit';
        }
    }
    return state;
};

export const getQueryVariable = (searchParams) => {
    const data = {};
    if(searchParams)
    {
        const query = searchParams.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            data[pair[0]] = decodeURIComponent(pair[1]);
        }
    }
    return data;
};
