import                                               './index.scss';
import React, { Component }                     from 'react';
import Assemblies                               from '../assemblies/index.jsx';
import Document                                 from '../document/index.jsx';
import RightSideBar                             from '../right-side-bar/index.jsx';
import { connect }                              from 'react-redux';
import { getQueryVariable }  from '../../helpers/serviceHelper';
import * as oauthActions     from '../../actions/oauth';
import $ from 'jquery';
import Config from '../../../../config';
import Pusher from 'pusher-js';
import { consts } from '../../utils/consts';

class Main extends Component {
    constructor(props, context) {
        super(props, context);
        this.pusher = null;
        this.state = { ...props };
        this.reloadPusher = this.reloadPusher.bind(this);
    }

    reloadPusher(){
        let queryParams = localStorage.getItem('queryParams');
        if(queryParams)
        {
          queryParams     = getQueryVariable(queryParams);
          this.pusher.unsubscribe(consts.pusher.channelName)
          this.pusher.disconnect();
        }
    }

    componentWillMount()
    {
      let queryParams = localStorage.getItem('queryParams');
      if (queryParams)
      {
        queryParams = getQueryVariable(queryParams);
        this.props.dispatch(oauthActions.setQueryParams({ queryParams }));
      }
    }

    componentDidMount()
    {
        let containerDiv = document.querySelector(".foreign-data-container")
        let queryParams  = localStorage.getItem('queryParams');
        if (queryParams)
        {
            this.setUpPusher()
        }
    }

    setUpPusher() {
        this.pusher = new Pusher(Config.PUSHER_APP_KEY, {
        cluster: Config.PUSHER_APP_CLUSTER,
        encrypted: true
        });
        this.pusher.subscribe(consts.pusher.channelName);
        window.pusherObj = this.pusher;
        window.addEventListener('beforeunload', this.reloadPusher);
    }

    render() {
        const {user} = this.props;
        const loadMain = localStorage.getItem('loadMain') === 'true';
        let queryParams  = localStorage.getItem('queryParams');
        if (queryParams && !this.pusher) {
            this.setUpPusher()
        }
        return (
                <React.Fragment>
                    { user && loadMain &&
                        <div className="middle">
                            <Assemblies pusher={this.pusher} history={this.props.history} user={user}/>
                            <Document />
                            <RightSideBar />
                        </div>
                    }
                </React.Fragment>
            );
    }
}

const mapStateToProps = ({userReducer}) => ({
    user: userReducer.user
});

export default connect(mapStateToProps)(Main);
