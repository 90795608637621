import React, {Component} from "react";

export default class Status extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            status: ''
        };
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount()
    {
        this.setState({status: this.props.status});
    }

    onInputChange(event, item, i)
    {
        let value = event.target.value.toUpperCase();
        this.props.openValidationModal && this.props.openValidationModal(event);
        this.setState({ status: value }, () => {
            this.props.onStatusChange(value, item, i);
        });
    }

    render()
    {
        let selectDropDownMarkup = <select
            className={`status-select ${this.props.className}`}
            name={this.props.name ? this.props.name : "status"}
            value={this.state.status}
            onChange={(event) => this.onInputChange(event, this.props.item, this.props.i)}>
            {this.props.options}
        </select>

        let markup =   <span data-tip={this.props.dataTip} data-place={this.props.dataPlace}>
                            {selectDropDownMarkup}
                        </span>
        return markup;
    }
}
