import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="BULK-SELECTION-0" transform="translate(-1267.000000, -208.000000)">
            <g id="Group-5" transform="translate(562.000000, 161.000000)">
                <g id="TABLE">
                    <g id="CONTENT" transform="translate(49.000000, 36.000000)">
                        <g id="REMOVE" transform="translate(656.000000, 11.000000)">
                            <g id="Remove-icon">
                                <g>
                                    <path d="M16,8 C16,12.4183351 12.4182171,16 8.00026344,16 C3.58178286,16 0,12.4183351 0,8 C0,3.58166491 3.58178286,0 8.00026344,0 C12.4182171,0 16,3.58166491 16,8" id="Fill-1" fill="#3CD1B5"></path>
                                    <path d="M10.4903226,5.66784668 L5.67267934,10.556619" id="Stroke-4" stroke="#17171F"></path>
                                    <path d="M10.4903226,10.556619 L5.67267934,5.66784668" id="Stroke-6" stroke="#17171F"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
