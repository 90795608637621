import { combineReducers } from 'redux';
import oauthReducer 			 from './oauthReducer';
import documentReducer 		 from './documentReducer';
import assemblyReducer 		 from './assembliesReducer';
import duroReducer 				 from './duroReducer';
import componentReducer 	 from './componentReducer';
import userReducer         from './user';
import signInLoadingReducer from './signInLoadingReducer';

const rootReducer = combineReducers({
  oauthReducer    : oauthReducer,
  documentReducer : documentReducer,
  assemblyReducer : assemblyReducer,
  duroReducer   	: duroReducer,
  componentReducer: componentReducer,
  userReducer,
  signInLoadingReducer
});

export default rootReducer;
