import                                               './index.scss';
import React, { useState, useEffect }           from 'react';
import RemoveIconSrc                            from '../../../../assets/icons/remove-icon';
import InlineIcon                               from '../../../ui/icon/inline-icon.jsx';
import {generateKey, checkToolTipAppearance, getStatusRank, toOptions}    from '../../../../helpers/components-helper';
import ReactTooltip                             from 'react-tooltip'
import Status from '../../status.jsx';
import Schemas from "../../../../modules/schemas";
import ArrowIconSrc from '../../../../assets/icons/co-small-arrow.jsx';
import Utils from "../../../../utils";
const TableRow = ((props) =>
{
    const {component, revisionScheme} = props;
    const cpn             = component.cpn;
    const [isChecked, setIsChecked] = useState(component.isCheckedOnStatusTable);

    const toggleCheckState = (event) =>
    {
        setIsChecked(!isChecked);
        props.toggleIndividualCheck(event, component);
    }

    const cmpType  = component.type;
    const linked   = component.documentVersion && !component.isStandardContent ? true : false;
    const previousStatus = Utils.getPreviousStatus(component);
    const previousRevision = Utils.getPreviousRevision(component);
    const isStatusDisabled = Utils.checkRevisionManaged(component) ? "" : "disabled-status";
    const isStatusInvalid = getStatusRank(component.nextStatus) < getStatusRank(previousStatus);
    const showNextRevisionValue = component && getStatusRank(component.nextStatus) > getStatusRank(component.status);
    const showCurrentRevision   = component && (getStatusRank(previousStatus) <= getStatusRank(component.nextStatus) || isStatusInvalid)
    const showPreviousRevision = component && (getStatusRank(component.nextStatus) <= getStatusRank(previousStatus)) && (getStatusRank(component.status) != getStatusRank(component.nextStatus))
    return (
                <tr className={`${component.status} ${linked ? 'disabled' : ''}`}>
                    <td>
                        <label className={`checkbox-holder`}>
                        <input type='checkbox' checked={!component.co ? component.isCheckedOnStatusTable : false} onChange={!linked ? (e) => toggleCheckState(e) : (e) => {}}/>
                        <span className='checkmark'></span>
                        </label>
                    </td>
                    <td>

                        <div className='cpn'>
                            <span className={''}>
                                {cpn}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div data-tip='' className='truncate-text' onMouseOver={(e) => checkToolTipAppearance(e, component.name)}>
                            {component.name}
                        </div >
                    </td>
                    <td>
                         <div className="revision-holder">
                            <span className={`revision-label revision-modified ${revisionScheme}`}>
                                {component.revision || '-'}
                                {component.modified ? <span data-tip="Component has been modified"
                                        data-place="right" className="modified-alert-icon"/> : null}
                            </span>
                            <InlineIcon className="arrow">
                                <ArrowIconSrc/>
                            </InlineIcon>
                            <div className={`revision-label text doted-border ${revisionScheme}`}>
                            {showNextRevisionValue ? component.nextRevisionInput && component.nextRevisionInput.value : showPreviousRevision ? previousRevision : showCurrentRevision ? component.revision :  ''}
                            {
                              showCurrentRevision && component.modified ? <span data-tip="Component has been modified"
                              data-place="right" className="modified-alert-icon"/> : null
                            }
                            </div>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span className="status-holder">
                                <span className={`status-label ${component.status}`}>
                                    {component.status}
                                </span>
                                <InlineIcon className="arrow">
                                    <ArrowIconSrc/>
                                </InlineIcon>
                                <div>
                                    <Status
                                        dataTip={isStatusInvalid ? `Must be ${component.status} or higher` : '' }
                                        dataPlace={'right'}
                                        onStatusChange={props.onStatusChange}
                                        i={component._id}
                                        className={`select-status ${isStatusInvalid ? "invalid" : ""} ${isStatusDisabled}`}
                                        item={component}
                                        name="child-status"
                                        key={generateKey()}
                                        status={component.nextStatus}
                                        options={toOptions(Schemas.component.status.list((getStatusRank(component.nextStatus) > getStatusRank(previousStatus)) ? previousStatus : component.nextStatus))}/>
                                </div>
                            </span>
                        </div>
                    </td>
                    <td>
                        <div data-tip='' className='truncate-text' onMouseOver={(e) => checkToolTipAppearance(e, component.description)}>
                            {component.description}
                        </div>
                    </td>
                    <td>
                        <InlineIcon
                            onClick={!linked ? () => props.remove(component._id): undefined}
                            className={`remove-icon ${component.status === 'being-synced' || component.status === 'synced' || component.status === 'already-synced' ? 'disabled' : ''}`}>
                            <RemoveIconSrc/>
                        </InlineIcon>
                    </td>
                </tr>
           );
});

export default TableRow;
