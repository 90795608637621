import React from 'react';
import { checkToolTipAppearance } from '../../../../helpers/components-helper';
import './index.scss';

const ApplicabilityField = (props) =>
{
    let { applicability } = props;
    let { value }         = applicability;
    value                 = value || '';
    return(
            <div className="tab-footer-value applicability-field">
                <span className='label'>APPLICABILITY</span>
                <span
                    data-tip=''
                    onMouseOver={(e) => checkToolTipAppearance(e, value)}
                    className='truncate-text'>
                    {value}
                </span>
            </div>
    );
}

export default ApplicabilityField;
