import {getStatusesList} from '../../helpers/components-helper';

const componentSchema = {
    status :
    {
        list(currentStatus='')
        {
            return getStatusesList(currentStatus);
        },

        normalize(value)
        {
            return value;
        },

        validate(value, revision)
        {
            if(value === "" && revision === "") return "";

            let valid = false;

            this.list().forEach((child) =>
            {
                if (child.value === value) { valid = true };
            })

            if (valid)
            {
                return "";
            }
            return "Status is not valid";
        }
    }
}

export default componentSchema;
