import React from 'react';

const UpdateStatusIcon = () => (
   <svg width="18px" height="15px" viewBox="0 0 18 15">
      <g
        transform="translate(-1121 -120) translate(1078 82) translate(44 39) rotate(90 8 8)"
        strokeWidth={1.5}
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        fillRule="evenodd"
      >
        <path d="M6.366 9.5V16" />
        <path d="M12.7310391 10.6604775L6.36684375 5 0 10.6604775" />
        <path d="M12.7310391 5.6604775L6.36684375 0 0 5.6604775" />
      </g>
    </svg>
);

export default UpdateStatusIcon;
