// Recuder specify how the application's state changes in response to actions sent to the store.

import { handleActions } from 'redux-actions';
import * as oauthActions from '../actions/oauth';

const initialState = {
    isOnshapeCompanyAdmin: false,
    access_token: '',
    refresh_token: '',
    error: '',
    mainLoading: true,
    queryParams: null
};

function onshapetokenSuccess(state, { payload }) {
    payload = payload || state;
    payload.mainLoading = false;
    return { ...state, ...payload };
}

function onshapetokenError(state, { payload }) {
    payload.mainLoading = true;
    return { ...state, ...payload };
}

function setQueryParams(state, { payload } )
{
    state.queryParams = payload.queryParams;
    return { ...state };
}

function setIsOnshapeCompanyAdmin(state, { payload }) {
    return { ...state, isOnshapeCompanyAdmin: payload };
}

// This is similar like switch statement to handle actions and return new state
export default handleActions(
    {
        [oauthActions.onshapeTokenSuccess]: onshapetokenSuccess,
        [oauthActions.onshapeTokenError]: onshapetokenError,
        [oauthActions.setQueryParams]: setQueryParams,
        [oauthActions.setIsOnshapeCompanyAdmin]: setIsOnshapeCompanyAdmin
    },
    initialState,
);
