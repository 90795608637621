import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="BULK-SELECTION-0" transform="translate(-1410.000000, -861.000000)" fill="#FFFFFF">
            <g id="Group-2" transform="translate(1381.000000, -2.000000)">
                <g id="NAV" transform="translate(0.000000, 25.000000)">
                    <g id="Help" transform="translate(29.000000, 838.000000)">
                        <path d="M9,0 C4.0368,0 0,4.0368 0,9 C0,13.9632 4.0368,18 9,18 C13.9632,18 18,13.9632 18,9 C18,4.0368 13.9632,0 9,0 Z M9,1.2 C13.3148,1.2 16.8,4.6852 16.8,9 C16.8,13.3148 13.3148,16.8 9,16.8 C4.6852,16.8 1.2,13.3148 1.2,9 C1.2,4.6852 4.6852,1.2 9,1.2 Z M9,4 C7.35,4 6,5.35 6,7 C5.997656,7.160938 6.060156,7.31562 6.172656,7.42968 C6.285156,7.544524 6.439056,7.608586 6.599996,7.608586 C6.760936,7.608586 6.914836,7.544524 7.027336,7.42968 C7.139836,7.315618 7.202336,7.16092 7.199992,7 C7.199992,5.99844 7.998432,5.2 8.999992,5.2 C10.001552,5.2 10.799992,5.99844 10.799992,7 C10.807024,7.82188 10.383592,8.24218 9.793752,8.8 C9.498432,9.0789 9.171092,9.36954 8.893752,9.75 C8.616412,10.13046 8.399992,10.62578 8.399992,11.2 C8.397648,11.360938 8.460148,11.51562 8.572648,11.62968 C8.685148,11.744524 8.839048,11.808586 8.999988,11.808586 C9.160928,11.808586 9.314828,11.744524 9.427328,11.62968 C9.539828,11.515618 9.602328,11.36092 9.599984,11.2 C9.599984,10.90312 9.686702,10.69766 9.862484,10.45624 C10.038266,10.21482 10.311704,9.95936 10.618724,9.66874 C11.232004,9.08906 12.008564,8.27812 11.999964,6.99994 L11.999964,6.99369 C11.996838,5.34681 10.647624,3.99989 8.999964,3.99989 L9,4 Z M9,12.4 C8.55782,12.4 8.2,12.75782 8.2,13.2 C8.2,13.64218 8.55782,14 9,14 C9.44218,14 9.8,13.64218 9.8,13.2 C9.8,12.75782 9.44218,12.4 9,12.4 Z" id="Fill-1"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

}

export default SvgIcon;
