// It is just a middleware same like thunk to communicate outside world i-e APIs
// Every Action comes first to redux-saga as it is running/ listening the these actions & connected to store with reducers...
// In order to run our Saga, we need to:

//   create a Saga middleware with a list of Sagas to run...
//   connect the Saga middleware to the Redux store ...

import { put, call, takeLatest, cps, select}                      from 'redux-saga/effects';
import { getDocumentAssemblies, getAssemblyMetaData, getPartsInPartStudio}   from '../services/assembliesService';
import * as assemblyActions                                       from '../actions/assemblies';
import * as oauthActions                                          from '../actions/oauth';
import * as componentActions                                      from '../actions/component';
import * as documentActions                                       from '../actions/document';
import { getAssemblyDefinition }                                  from '../services/duroService';
import { getQueryParams} from './selectors/component-selectors';
import { getMainAssemblyId } from './selectors/assemblies-selector';
import { filterElements } from '../helpers/components-helper';

export function* documentAssembliesSaga({payload}) {
    try
    {
        let queryParams= yield select(getQueryParams);
        let response = yield cps(getDocumentAssemblies, {queryParams});
        if(payload && payload.cb)
        {
            payload.cb(response);
        }
        else
        {
          yield put(assemblyActions.documentAssembliesSuccess(response));
          if(!filterElements(response))
          {
              yield put(documentActions.setDocumentLoading(false));
          }
        }
    }
    catch(err)
    {
        yield put(assemblyActions.documentAssembliesError(err));
    }
}

export function* AssemblyMetaData({payload})
{
    try
    {
        let queryParams= yield select(getQueryParams);
        if(payload.successCb)
        {
            yield cps(getAssemblyMetaData, payload, queryParams, (error, response) =>
            {
                if(payload.successCb)
                {
                    if(error)
                    {
                        payload.successCb(error);
                    }
                    else
                    {
                        payload.successCb(null, response);
                    }
                }
            });

        }
        else
        {
            yield put(componentActions.isLoading(true));
            if(payload.isRedirecting)
            {
                yield put(documentActions.setDocumentLoading(true));
            }
            let response = yield cps(getAssemblyMetaData, payload, queryParams);
            if(response.status === 500)
            {
                window.sessionStorage.lastOpenedAssembly = '';
                yield put(documentActions.setDocumentLoading(false));
                yield put(componentActions.isLoading(false));
            }
            else
            {
                yield put(componentActions.resetState());
                if(payload.isRedirecting)
                {
                    if(response && response.onShapeData)
                    {
                      yield put(documentActions.updateDocumentImage(response.onShapeData.thumbnail));
                    }
                }
                yield put(componentActions.componentSucess(response));
            }
        }
    }
    catch(err)
    {
        // yield put(assemblyActions.documentAssembliesError(err));
    }
}

export function* refreshTokenSaga()
{
  yield put(oauthActions.getOnshapeRefreshToken(assemblyActions.getDocumentAssemblies().type));
}

export function* getPartStudio({payload})
{
    try
    {
        yield put(componentActions.resetState());
        let queryParams= yield select(getQueryParams);
        let response  = yield cps(getPartsInPartStudio, {...payload, companyId: queryParams.companyId});
        if(response.error || response.status === 404)
        {
            payload.elementId = yield select(getMainAssemblyId);
            response = yield cps(getAssemblyDefinition, payload);
            if(payload.successCb)
            {
                payload.successCb(null, response, payload.shouldResyncBom);
            }
            else
            {
                yield put(duroActions.getDataSuccess(response));
            }
        }
        else if(payload.successCb)
        {
            payload.successCb(null, response);
        }
        else
        {
            yield put(duroActions.getDataSuccess(response));
        }
    }
    catch(err)
    {
        payload.elementId   = yield select(getMainAssemblyId);
        payload.queryParams = yield select(getQueryParams);
        let response        = yield cps(getAssemblyDefinition, payload);
        if(payload.successCb)
        {
            payload.successCb(null, response, payload.shouldResyncBom);
        }
        else
        {
            yield put(duroActions.getDataSuccess(response));
        }
        // throw err;
    }
}

export default function* watchAuthProcess() {
    yield takeLatest(assemblyActions.getDocumentAssemblies().type, documentAssembliesSaga);
    yield takeLatest(assemblyActions.getAssemblyMetaData().type, AssemblyMetaData);
    yield takeLatest(assemblyActions.getPartStudio().type, getPartStudio);
}
