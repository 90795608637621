import './index.scss';
import React, { Component } from 'react';
import InlineIcon  from '../icon/inline-icon.jsx';
import Config from '../../../../../config';
import Pusher from 'pusher-js';
import WarningIconSrc from '../../../assets/icons/warning-icon';
import * as assemblyActions  from '../../../actions/assemblies';
import {getParsedItemFromSessionStorage} from '../../../helpers/components-helper';
import { consts } from '../../../utils/consts';

class RefreshAssemblyButton extends Component
{
  constructor(props, context) {
    super(props, context);
    this.setAssemblyRefreshFlags = this.setAssemblyRefreshFlags.bind(this);
}

  componentDidMount()
  {
    let queryParams = this.props.queryParams;
    let {documentId} = queryParams;
    let _this = this;
    const hasOpenedSameDocument = (data) =>
    {
        if(data.documentId === documentId)
        {
            let lastOpenedAssembly      = getParsedItemFromSessionStorage('lastOpenedAssembly');
            const searchParams          = queryParams;
            let elementId = null;
            if(lastOpenedAssembly && lastOpenedAssembly.elementId && searchParams.documentId === lastOpenedAssembly.documentId)
            {
                elementId = lastOpenedAssembly.elementId;
            }
            this.props.setPendingUpdated(true);
            this.props.handleNewUpdate();
        }
    }

    if(queryParams && this.props.pusher)
    {
        this.props.pusher.channel(consts.pusher.channelName).bind(consts.pusher.events.documentChanged, data => {
            hasOpenedSameDocument(data);
        });
    }
  }

    setAssemblyRefreshFlags(){
      this.props.setAssemblyRefreshFlags(true);
      this.props.setPendingUpdated(false);
      this.props.handleNewUpdate(false);
    }

    render()
    {
        return <React.Fragment>
                  <InlineIcon
                      onClick={() => this.setAssemblyRefreshFlags(true)}
                      className={`assembly-refresh-icon ${this.props.pendingUpdates ? 'warning-message': ''} ${this.props.refreshButtonClass}`}
                      tooltip='Sync top level items to Duro. Release from onshape to perform a deep sync.'
                      tooltipPlace='right'
                  >
                      Sync
                      {this.props.pendingUpdates? <span className="warning-icon"><WarningIconSrc/></span> : ""}
                  </InlineIcon>
             </React.Fragment>
    }
};

export default RefreshAssemblyButton;
