import React, { Component }        from 'react';
import {generateKey} from '../../../../helpers/components-helper';

class Tree extends Component
{
    constructor(props)
    {
        super(props);
        this.getTree = this.getTree.bind(this);
        this.state   =
        {
            rootAssembly: this.props.rootAssembly,
            showPullRequestModal: this.props.showPullRequestModal,
            tree: this.getTree()
        };
    }

    getTree()
    {
        let {rootAssembly, setQuantityProperty} = this.props;
        setQuantityProperty(rootAssembly);
        this.filterAssemblyTree(rootAssembly);
        return <React.Fragment>
                    <ul className='assembly-ul-list-inner'>
                        <li key={generateKey()}>
                            {this.props.generateNavMarkup(rootAssembly, 'separator-1', false, true)}
                        </li>
                    </ul>
                </React.Fragment>;
    }

    filterAssemblyTree(assembly)
    {
        let tree = [];
        if(assembly.nestedChildren)
        {
            for(let children of assembly.nestedChildren)
            {
                let alreadyPushed = tree.find(cmp => {
                    if(children.type === 'Part' && children.partId === cmp.partId && children.elementId === cmp.elementId && children.documentId === cmp.documentId && children.fullConfiguration === cmp.fullConfiguration)
                    {
                        return true;
                    }
                    else if(children.type === 'Assembly' && children.elementId === cmp.elementId && children.documentId === cmp.documentId && children.fullConfiguration === cmp.fullConfiguration)
                    {
                        return true;
                    }
                    return false
                });
                if(!alreadyPushed)
                {
                    tree.push(children);
                }
                this.filterAssemblyTree(children);
            }
            assembly.nestedChildren = tree;
        }
        assembly.refreshAssembly = this.props.refreshAssembly;
    }

    componentDidUpdate(prevProps)
    {
        let {showPullRequestModal, rootAssembly, refreshAssembly} = this.props;
        let _this = this;
        if((prevProps.showPullRequestModal && !showPullRequestModal) || (rootAssembly && this.state.rootAssembly && rootAssembly.name !== this.state.rootAssembly.name))
        {
            this.filterAssemblyTree(rootAssembly);
            this.setState({rootAssembly}, () => {
                _this.props.setRowsBackgroundColor();
            });
        }

        if(prevProps.refreshAssembly !== refreshAssembly)
        {
            this.setAssemblyRefreshFlag(rootAssembly, refreshAssembly);
        }

        if(prevProps.rootAssembly !== rootAssembly)
        {
            let tree = this.getTree();
            this.setState({tree}, () => {
                _this.props.setRowsBackgroundColor();
            });
        }
    }

    setAssemblyRefreshFlag(assembly)
    {
        let {refreshAssembly}    = this.props;
        assembly.refreshAssembly = refreshAssembly;
        if(assembly.nestedChildren)
        {
            for(let children of assembly.nestedChildren)
            {
                this.setAssemblyRefreshFlag(children);
            }
        }
    }

    render()
    {
        let {rootAssembly, tree} = this.state;
        return tree;
    }
}

export default Tree;
