import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="NEW-USER-MENU" transform="translate(-1127.000000, -384.000000)" fill="#888888">
                <g id="MENU" transform="translate(1102.000000, 80.000000)">
                    <g id="Signout" transform="translate(25.000000, 301.000000)">
                        <g id="CLOSE" transform="translate(7.000000, 10.000000) scale(-1, 1) translate(-7.000000, -10.000000) translate(0.000000, 3.000000)">
                            <g id="Group-2">
                                <path d="M9.28941176,9.49152542 C8.97317647,9.49152542 8.69647059,9.76836158 8.69647059,10.0847458 L8.69647059,12.8135593 L1.18588235,12.8135593 L1.18588235,1.18644068 L8.69647059,1.18644068 L8.69647059,3.91525424 C8.69647059,4.23163842 8.97317647,4.50847458 9.28941176,4.50847458 C9.60564706,4.50847458 9.88235294,4.23163842 9.88235294,3.91525424 L9.88235294,0.593220339 C9.88235294,0.276836158 9.60564706,0 9.28941176,0 C9.28941176,0 9.28941176,0 9.28941176,0 L0.592941176,0 C0.276705882,0 0,0.276836158 0,0.593220339 L0,13.4067797 C0,13.7231638 0.276705882,14 0.592941176,14 L9.28941176,14 C9.60564706,14 9.88235294,13.7231638 9.88235294,13.4067797 L9.88235294,10.0847458 C9.88235294,9.72881356 9.60564706,9.49152542 9.28941176,9.49152542 C9.28941176,9.49152542 9.28941176,9.49152542 9.28941176,9.49152542 Z" id="Shape" fillRule="nonzero"></path>
                                <path d="M14,7.45454545 C14,7.42032086 14,7.38609626 14,7.35187166 C14,7.31764706 14,7.31764706 14,7.28342246 C14,7.28342246 14,7.24919786 14,7.21497326 L14,7.21497326 C14,7.18074866 13.9672967,7.18074866 13.9672967,7.14652406 L13.9345933,7.11229947 L13.9345933,7.07807487 L11.2856233,4.27165775 C11.0894033,4.06631016 10.7950733,4.06631016 10.5988533,4.27165775 C10.4026333,4.47700535 10.4026333,4.78502674 10.5988533,4.99037433 C10.5988533,4.99037433 10.5988533,4.99037433 10.5988533,4.99037433 L12.3648333,6.90695187 L5.43172648,6.90695187 C5.17009981,6.90695187 4.94117647,7.14652406 4.94117647,7.42032086 C4.94117647,7.69411765 5.17009981,7.93368984 5.43172648,7.93368984 L12.33213,7.93368984 L10.5661499,9.85026738 C10.3699299,10.055615 10.3699299,10.3636364 10.5661499,10.568984 C10.6642599,10.6716578 10.7950733,10.7058824 10.8931833,10.7058824 C11.0239966,10.7058824 11.1548099,10.6374332 11.2529199,10.5347594 L13.8364833,7.76256684 C13.8364833,7.76256684 13.8364833,7.72834225 13.8691867,7.72834225 L13.8691867,7.69411765 C13.90189,7.65989305 13.90189,7.62566845 13.9345933,7.59144385 L13.9345933,7.59144385 C13.9345933,7.59144385 13.9345933,7.59144385 13.9345933,7.55721925 C13.9345933,7.52299465 13.9672967,7.48877005 13.9672967,7.45454545 L14,7.45454545 Z" id="Shape" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default SvgIcon
