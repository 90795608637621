import React from "react";

function BulkUpdateStatus()
{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      version="1.1"
      viewBox="0 0 18 15"
    >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(-1121 -120) translate(1078 82)"
                id="Bulk-Status-Icon"
            >
                <g transform="translate(44 39)">
                    <g strokeWidth="1.5" transform="rotate(90 8 8)">
                      <path d="M6.366 9.5V16"></path>
                      <path d="M12.7310391 10.6604775L6.36684375 5 0 10.6604775"></path>
                      <path d="M12.7310391 5.6604775L6.36684375 0 0 5.6604775"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default BulkUpdateStatus;
