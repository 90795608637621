import serviceCalls from './serviceCalls';

export const getDocument = (payload, cb) => {
    const {queryParams} = payload;
    const path = `/onshape/getDocument?documentID=${queryParams.documentId}`;
    serviceCalls.get(path).then((response) =>
    {
        cb(null, response.data);
    }).catch((error) =>
    {
        cb(null, error.data);

    });
};
