import { createAction } from 'redux-actions';

export const getComponent 				= createAction('GET_COMPONENT');
export const componentSucess 			= createAction('COMPONENT_SUCCESS');
export const componentError             = createAction('COMPONENT_ERROR');
export const isLoading 					= createAction('COMPONENT_LOADING');
export const updateComponentMetadata  	= createAction('UPDATE_COMPONENT');
export const SynctoDuro 				= createAction('SYNC_TO_DURO');
export const clearErrors 				= createAction('CLEAR_ERRORS');
export const isSyncing 					= createAction('IS_SYNCING');
export const resetState 				= createAction('RESET_STATE');
export const switchView 				= createAction('SWITCH_VIEW');
export const switchTab 					= createAction('SWITCH_TAB');
export const hideSyncedModal 			= createAction('HIDE_SYNCED_MODAL');
export const updateEditFormInputState 	= createAction('UPDATE_EDIT_FORM_INPUT_STATE');
export const updateEditComponentFormFields = createAction('UPDATE_EDIT_COMPONENT_FORM_FIELDS');
export const resetFormInputs 			= createAction('RESET_FORM_INPUTS');
export const SyncwithRemoteObject 		= createAction('SYNC_WITH_REMOTE_OBJECT');
export const setCategories              = createAction('SET_CATEGORIES');
export const createPullRequest          = createAction('CREATE_PULL_REQUEST');
export const isNameExistsBulk           = createAction('IS_NAME_EXISTS_BULK');
export const getCmpsInBulk              = createAction('GET_CMPS_IN_BULK');
export const updateCmpsInBulk           = createAction('UPDATE_CMPS_IN_BULK');
export const getMassValue               = createAction('GET_MASS_VALUE');
