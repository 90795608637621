import                                               './index.scss';
import React, { Component }                     from 'react';
import { connect }                              from 'react-redux';
import { RingLoader }                           from 'react-spinners';
import * as assemblyActions                     from '../../actions/assemblies';
import ShowAssemblies                           from '../ui/show-assemblies/index.jsx';
import { spinnerStyles, sizeUnit, size, color } from '../../helpers/spinnerStyles';
import LeftSideBarNavigationMenu                from '../ui/left-side-bar-navigation-menu/index.jsx';
import RibbonCloseSrc                           from "../../assets/icons/ribbon-close-arrow";
import RibbonOpenSrc                            from "../../assets/icons/ribbon-open";
import InlineIcon                               from "../ui/icon/inline-icon.jsx";

class Assemblies extends Component {
    constructor(props, context)
    {
        super(props, context);
        this.state = {
                ...props,
                currentNav: 'assembly',
                style:
                {
                    width: "381"
                }
        };
        this.onMouseDown       = this.onMouseDown.bind(this);
        this.onMouseMove       = this.onMouseMove.bind(this);
        this.onMouseLeave      = this.onMouseLeave.bind(this);
        this.setSideBarWidth   = this.setSideBarWidth.bind(this);
    }

    componentDidMount() {
        let queryParams = this.props.queryParams;
        let payload = { elementId: queryParams.elementId, documentId: queryParams.documentId, workspaceId: queryParams.workspaceId};
        this.props.dispatch(assemblyActions.getDocumentAssemblies(payload));
    }

    onMouseDown(e) {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseLeave);
        e.stopPropagation();
        e.preventDefault();
    }


    onMouseMove(e) {
        if (e.pageX >= 380){
            if (this.state.style.width !== e.pageX){
                let style = this.state.style;
                style.width    = e.pageX;
                this.setState({style});
            }
        }
        e.stopPropagation();
        e.preventDefault();
    }

    onMouseLeave(e){
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);
        let style = this.state.style;

        let top = document.querySelector(".ribbon-icon-holder").offsetTop;

        if( e.clientY > top && e.clientY < top + 24)
        {
            style.width = style.width >= 800 ? 381 :800;
        }
        style.width = style.width < 382 ? 381 : style.width > 800 ? 800 : style.width;
        this.setState({style});
        e.preventDefault();
        e.stopPropagation();
    }

    setSideBarWidth(e)
    {
        e.preventDefault();
        e.stopPropagation();
        let style = this.state.style;
        style.width = style.width == 800 ? 381 : 800;
        this.setState({style});
    }

    render() {
        const data         = this.props;
        const {currentNav, style} = this.state;

        return (
            <div className="assemblies"
                style={{maxWidth: style.width+"px"}}>
                <div className="ribbon-resize"
                    onMouseDown={(e) => this.onMouseDown(e)}
                    onDoubleClick={this.setSideBarWidth}
                    >
                    <div className="ribbon-icon-holder" onClick={this.setSideBarWidth}>
                        {
                            style.width < 800 ?
                                <InlineIcon
                                    className="close-icon">
                                    <RibbonCloseSrc/>
                                </InlineIcon>
                            :   <InlineIcon className="open-icon">
                                    <RibbonOpenSrc/>
                                </InlineIcon>
                        }
                    </div>
                </div>
                <LeftSideBarNavigationMenu history={this.props.history} currentNav={currentNav}/>
                    { data.assemblyLoading &&
                        <div className="loader-holder">
                            <RingLoader
                                css={spinnerStyles}
                                sizeUnit={sizeUnit}
                                size={size}
                                color={color}
                                loading={data.assemblyLoading}
                            />
                        </div>
                    }
                    {
                        data.assemblies.length === 0 ? null :
                        <ShowAssemblies
                          pusher={this.props.pusher}
                          hasLoadedAssembly={this.props.hasLoadedAssembly}
                          history={this.props.history}
                          assemblies={data.assemblies}
                          dispatch={this.props.dispatch}
                          queryParams={this.props.queryParams}
                          user={this.props.user}
                        />
                    }
            </div>
        );
    }
}

const mapStateToProps = ({assemblyReducer, oauthReducer}) => ({
    queryParams : oauthReducer.queryParams,
    assemblyLoading: assemblyReducer.assemblyLoading,
    assemblies: assemblyReducer.assemblies
});

export default connect(mapStateToProps)(Assemblies);
