import                  	                 './index.scss';
import React, { Component }             from 'react';
import { generateKey,
        createLinkUrl,
        getPropertyObj,
        checkToolTipAppearance,
        getImageOfSpecificSize
        }                               from '../../../helpers/components-helper';
import InlineIcon                       from '../../ui/icon/inline-icon';
import OpenLinkIconSrc                  from '../../../assets/icons/open-link-icon.jsx';
import ScrollArea                       from "react-scrollbar";
import ReactTooltip                     from 'react-tooltip';
import ModifiedTriangleSrc              from '../../../assets/icons/modified-triangle-icon.jsx';
import MassField                        from '../common/mass-field';
import Utils                            from '../../../utils';
import { syncComponentThumbnail }       from "../../../services/componentService";
import ModalBox                         from '../../ui/modal-box';
import ErrorIcon                        from '../../../assets/icons/error-icon';
import ApplicabilityField               from '../common/applicability-field';


class ViewComponent extends Component {

    constructor(props, context)
    {
        super(props, context);
        this.hideRefreshModal = this.hideRefreshModal.bind(this);
        this.setRefreshModalSuccessState = this.setRefreshModalSuccessState.bind(this);
        this.setRefreshModalFailedState = this.setRefreshModalFailedState.bind(this);
        this.showRefreshModalState = this.showRefreshModalState.bind(this);
        this.state = {
            thumbnailSyncButtonEnable: true,
            refreshModal: { headerText: 'Syncing Thumbnail with Duro', bodyText: 'Please wait...', visibility: false, displayBtn: false },
        }
    }

    componentDidMount()
    {
        ReactTooltip.rebuild();
    }

    onThumbnailSync(onShapeData, componentDuro, queryParams) {
        const {partId, elementId, documentVersion, documentId, configurationString, isStandardContent} = onShapeData;
        const payload = { partId, elementId, documentVersion, documentId, workspaceId: queryParams.workspaceId, configurationString, linkDocumentId: queryParams.documentId, isStandardContent, duroCompId: componentDuro._id, duroCompName: componentDuro.name };
        this.showRefreshModalState();
        syncComponentThumbnail(payload, (err, res) => {
            if(!err) this.setRefreshModalSuccessState()
            else this.setRefreshModalFailedState()
       })
    }

    showRefreshModalState() {
        const {refreshModal}      = this.state;
        refreshModal.visibility = true;
        this.setState({refreshModal, thumbnailSyncButtonEnable: false})
    }

    hideRefreshModal() {
        const refreshModal = {headerText: 'Syncing Thumbnail with Duro', bodyText: 'Please wait...', visibility: false, displayBtn: false}
        this.setState({refreshModal, thumbnailSyncButtonEnable: true});
    }

    setRefreshModalSuccessState() {
        const refreshModal = {headerText: 'Sync Complete', bodyText: 'The latest thumbnail has been pulled from Onshape and updated in Duro.', visibility: true , displayBtn: true}
        this.setState({refreshModal});
    }

    setRefreshModalFailedState() {
        const refreshModal = {headerText: 'Sync Failed', bodyText: 'The Syncing has failed. Please try again.', visibility: true, displayBtn: true};
        this.setState({refreshModal});
    }

  render()
  {
    let {componentDuro, OnShapeComp, massObj, dispatch, queryParams} = this.props;
    const {refreshModal} = this.state;
    const thumbnailSyncFailed = (refreshModal.headerText === 'Sync Failed'); 
    let category, eid, revision, status, description, minQty, unitPrice , leadTime, lastModified, lastModifiedBy, specs, procurement, docsLength = 0, manufacturers = 0, link = null;
    const isSynced                   = !(Object.keys(componentDuro).length === 0 && componentDuro.constructor === Object);
    if(isSynced)
    {
        category        = componentDuro.category ? componentDuro.category : "";
        revision        = componentDuro.revision;
        status          = componentDuro.status;
        // description     = componentDuro.description;
        minQty          = componentDuro.primarySource ? componentDuro.primarySource.minQuantity : '';
        unitPrice       = componentDuro.primarySource ? componentDuro.primarySource.unitPrice   : '';
        leadTime        = componentDuro.primarySource && componentDuro.primarySource.leadTime.value ? componentDuro.primarySource.leadTime.value + componentDuro.primarySource.leadTime.unit : '';
        lastModified    = componentDuro.lastModified ? this.props.formatDate(componentDuro.lastModified) : null;
        lastModifiedBy  = componentDuro.lastModifiedBy ? `${componentDuro.lastModifiedBy.firstName} ${componentDuro.lastModifiedBy.lastName}` : '';
        specs           = componentDuro.specs ? componentDuro.specs.map((spec) =>
                        {
                            return (
                                    <li key={generateKey()} className="tab-footer-value">
                                        <span className='label specs-key' >{ spec.key }</span>
                                        <span>{ spec.value }</span>
                                    </li>
                                    )
                        }) : null;
        link            = createLinkUrl(`component/view/${componentDuro._id}`, 'switchEnvToGeneral=true');
        procurement     = componentDuro.procurement ? componentDuro.procurement : '';
        docsLength      = componentDuro.documents ? componentDuro.documents.length : 0;
        manufacturers   = componentDuro.manufacturers ? componentDuro.manufacturers.length : 0;
    }

    description = getPropertyObj(OnShapeComp.properties, 'Description').value;
    eid       = componentDuro.eid;
    const sourcingClass     = !isSynced ||  !manufacturers || manufacturers === 0 ? 'grayed-out' : '';
    const releaseDocClass   = !isSynced ||  !docsLength || docsLength === 0 ? 'grayed-out' : '';

    const WithLink = ({ link, children }) => (link ?
                        <a href={link} target='_blank'>
                            {children}
                        </a>
                        : children
                    );
    const imageObject = OnShapeComp && OnShapeComp.thumbnail ? getImageOfSpecificSize(OnShapeComp.thumbnail, '300x170') : null;
    const imageLink   = imageObject ? imageObject.href : '';
    // const massObj     = getPropertyObj(OnShapeComp.properties, 'Mass');

    return (
            <div>
                <div className="tab-content-holder">
                    <div className="tab-content-image">
                        <img
                            src={imageLink}
                            alt=""
                        />
                    </div>
                    <div className="tab-content-detail">
                        <span className="tab-content-value">
                            <span className='label'>CPN</span>
                                <span className={`${isSynced ? 'cpn-value' : ''} property-value`}>
                                    {
                                        componentDuro && componentDuro.cpn ?
                                        <React.Fragment>
                                            <a href={link} target='_blank'>
                                                {Utils.getCpnValue(componentDuro)}
                                                <InlineIcon>
                                                    <OpenLinkIconSrc/>
                                                </InlineIcon>
                                            </a>
                                        </React.Fragment>
                                         :
                                            Utils.getCpnTag()
                                        }
                                </span>
                            </span>
                        <span className="tab-content-value">
                            <span className='label'>CAT</span>
                            <span className='property-value'>
                                {category}
                            </span>
                        </span>
                        <span className="tab-content-value">
                            <span className='label'>EID</span>
                            <span className='property-value'>{eid}</span>
                        </span>
                        <span className="tab-content-value">
                            <span className='label'>REV</span>
                            <span className='property-value'>
                                {revision}
                                {
                                    componentDuro && componentDuro.modified ?
                                        <span className="modified">
                                            <InlineIcon
                                                tooltip={"Component has been modified"}
                                                tooltipPlace="right"
                                            >
                                              <ModifiedTriangleSrc />
                                            </InlineIcon>
                                        </span>
                                        :
                                        null
                                }
                            </span>
                        </span>
                        <span className="tab-content-value">
                            <span className='label'>STATUS</span>
                            <span className='property-value'>
                                <span className={status ? `status-label ${status}` : '' }>
                                    {status}
                                </span>
                            </span>
                       </span>
                    </div>
                </div>
                {isSynced && <div className="thumbnail-button">
                    <button type="button" className={this.state.thumbnailSyncButtonEnable ? '' : 'disabled'} onClick={() => {this.onThumbnailSync(OnShapeComp, componentDuro, queryParams)}}><span>Sync Image</span></button>                 
                </div>}
                {refreshModal.visibility &&
                    <ModalBox icon={thumbnailSyncFailed && <ErrorIcon/>} modal={refreshModal} onClick={this.hideRefreshModal}/>}
                <div className="tab-content-footer">
                    <div className='section-creator-created'>
                        <div className="tab-footer-value">
                            <span className='label'>LAST MODIFIED</span>
                            <span
                                data-tip=''
                                className='truncate-text'
                                onMouseOver={(e) => checkToolTipAppearance(e, lastModified)}
                                >
                                {lastModified ? lastModified : ''}
                            </span>
                        </div>
                        <div className="tab-footer-value">
                            <span className='label'>MODIFIED BY</span>
                            <span
                                data-tip=''
                                onMouseOver={(e) => checkToolTipAppearance(e, lastModifiedBy)}
                                className='truncate-text'>
                                {lastModifiedBy ? lastModifiedBy : ''}
                            </span>
                        </div>
                    </div>
                    <div className='section-release-sourcing'>
                        <div className='tab-footer-value release-doc'>
                            <WithLink link={link && docsLength > 0 ? `${link}?tab=documents` : null}>
                                <span className={`release-doc-label ${releaseDocClass} label`}>
                                    LATEST RELEASE
                                </span>
                                <span className={`${docsLength > 0  ? 'italic' : 'property-value'}`}>
                                        {docsLength} Document(s)
                                </span>
                                {
                                    !releaseDocClass &&
                                    <InlineIcon>
                                        <OpenLinkIconSrc/>
                                    </InlineIcon>
                                }
                            </WithLink>
                        </div>
                        <div className='tab-footer-value release-doc view-part'>
                            <WithLink link={link && manufacturers > 0 ? `${link}?tab=sourcing` : null}>
                                <span className={`release-doc-label ${releaseDocClass} label`}>
                                    SOURCING
                                </span>
                                <span className={`${manufacturers > 0  ? 'italic' : 'property-value'}`}>
                                    {manufacturers} Source(s)
                                </span>
                                {
                                    !sourcingClass &&
                                    <InlineIcon>
                                        <OpenLinkIconSrc/>
                                    </InlineIcon>
                                }
                            </WithLink>
                        </div>
                    </div>
                    <div className='section-procurement'>
                        <div className="tab-footer-value procurement-radio-btns">
                            <span className='label'>PROCUREMENT</span>
                            <span className={`${procurement ? '' : 'property-value'}`}>
                            {procurement ? procurement: 'N/A'} </span>
                        </div>
                    </div>
                    <div className='section-description-specs'>
                        <div className="tab-footer-value">
                            <span className='label'>DESCRIPTION</span>
                            <span
                                data-tip=''
                                onMouseOver={(e) => checkToolTipAppearance(e, description)}
                                className='truncate-text'>
                                {description}
                            </span>
                        </div>
                        <div className='tab-footer-value specs-div'>
                            {
                                Utils.isApplicabilityFieldEnabled() && <ApplicabilityField applicability={getPropertyObj(OnShapeComp.properties, 'Applicability')}/>
                            }
                            {
                                specs &&
                                    <div>
                                        <ScrollArea
                                        className="specs"
                                        contentClassName="content"
                                        >
                                            <div className=''>
                                                <ul className="specs-scroll">
                                                {specs}
                                                </ul>
                                            </div>
                                        </ScrollArea>
                                    </div>
                            }
                            <div className="tab-footer-value">
                                <MassField massObj={massObj} view={'view'} dispatch={dispatch} onShapeComp={OnShapeComp} queryParams={queryParams} componentDuro={componentDuro}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ViewComponent;
