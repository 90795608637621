import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns-xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1-UNSYNCED-PART-READ-ONLY" transform="translate(-1829.000000, -50.000000)" fill="#FFFFFF">
            <g id="Seperator" transform="translate(1436.000000, 39.000000)">
                <g id="top">
                    <g id="Sync" transform="translate(393.000000, 11.000000)">
                        <g id="noun_Refresh_1921312" transform="translate(0.500000, 0.000000)">
                            <path d="M6.5524764,1.20670686 L2.69016627,0.0252913439 C2.34428775,-0.0731599491 1.97919375,0.123742637 1.88311639,0.478167292 L1.88311639,0.478167292 C1.78703902,0.832591946 1.97919375,1.20670686 2.32507227,1.30515815 L4.57328265,1.9943172 C1.86390091,3.27418401 0,6.08989099 0,9.33878366 C0,12.8239594 2.15213301,15.7971885 5.14974685,16.9589137 C5.57248726,17.1164358 6.03365862,16.8013917 6.03365862,16.3485157 C6.03365862,16.0728521 5.86071936,15.8168787 5.61091821,15.7184274 C3.09369121,14.7536048 1.28743671,12.2529419 1.28743671,9.33878366 C1.28743671,6.54276694 2.93996742,4.12086513 5.32268611,3.07728143 L4.57328265,5.59763453 C4.47720528,5.95205918 4.66936002,6.3261741 5.01523854,6.42462539 L5.01523854,6.42462539 C5.36111706,6.52307668 5.72621105,6.3261741 5.82228842,5.97174944 L6.97521682,2.01400746 C7.07129418,1.67927307 6.87913945,1.30515815 6.5524764,1.20670686 Z" id="Shape" fillRule="nonzero"></path>
                            <path d="M14.6941097,16.6963518 L12.4458667,16.00799 C15.136072,14.7099364 17,11.897487 17,8.652353 C17,5.17120926 14.8478357,2.20141997 11.8501783,1.04103873 C11.4274318,0.883698898 10.9662537,1.19837856 10.9662537,1.65073057 C10.9662537,1.92607527 11.1391955,2.18175249 11.3890003,2.28008989 C13.9062639,3.24379635 15.7125446,5.74156614 15.7125446,8.652353 C15.7125446,11.445135 14.0599899,13.8642349 11.6772366,14.9066112 L12.4266509,12.389174 C12.5227297,12.0351593 12.3305722,11.6614772 11.9846886,11.5631398 C11.6388051,11.4648025 11.2737058,11.6614772 11.177627,12.0154919 L10.0246819,15.9686551 C9.9286031,16.3226697 10.1207606,16.6963518 10.4666442,16.7946892 L14.3290104,17.9747379 C14.674894,18.0730753 15.0399932,17.8764005 15.136072,17.5223859 L15.136072,17.5223859 C15.2321508,17.1683713 15.0399932,16.7946892 14.6941097,16.6963518 Z" id="Shape" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
