import * as React from "react"

const FlagIcon = () => {
  return (
    <svg width="14px" height="19px" viewBox="0 0 14 19">
      <g
        stroke="#000"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M.5 10.945s.812-.812 3.25-.812c2.437 0 4.062 1.624 6.5 1.624 2.438 0 3.25-.812 3.25-.812v-9.75s-.812.812-3.25.812c-2.438 0-4.063-1.625-6.5-1.625-2.438 0-3.25.813-3.25.813v9.75zM.5 16.633v-5.687"
          transform="translate(-90 -86) translate(90 87)"
        />
      </g>
    </svg>
  );
};

export default FlagIcon;
