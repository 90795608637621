// Recuder specify how the application's state changes in response to actions sent to the store.

import { handleActions } from 'redux-actions';
import * as types from '../actions/document';

const initialState = {
    document: {},
    documentLoading: true,
};

function documentSuccess(state, { payload })
{
    state.document = payload;
    state.owner    = payload.owner;
    state.documentLoading = false;
    return { ...state };
}

function documentError(state, { payload }) {
    payload.documentLoading = false;
    return { ...state, ...payload };
}

function updateDocumentImage(state, { payload })
{
    state.document.thumbnail = payload;
    state.documentLoading    = false;
    return { ...state };
}

function setDocumentLoading(state, { payload })
{
    state.documentLoading = payload;
    return { ...state };
}

// This is similar like switch statement to handle actions and return new state
export default handleActions(
    {
        [types.documentSuccess]: documentSuccess,
        [types.documentError]: documentError,
        [types.updateDocumentImage]: updateDocumentImage,
        [types.setDocumentLoading]: setDocumentLoading,
    },
    initialState,
);
