import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="12px" height="13px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="BULK-SELECTION-0" transform="translate(-564.000000, -68.000000)" fill="#18171D" fillRule="nonzero">
            <g id="HEADER" transform="translate(524.000000, 52.000000)">
                <path d="M48.2984491,16.7700482 C48.1880393,16.6023872 48.0011075,16.5010386 47.8003602,16.5 L40.5990741,16.5 C40.3860942,16.5003667 40.1892647,16.6135905 40.0818735,16.7975134 C39.9744822,16.9814364 39.9726202,17.2085003 40.0769808,17.3941597 L42.9154878,22.5010718 L40.0769808,27.6079838 C39.9726202,27.7936432 39.9744822,28.0207071 40.0818735,28.2046301 C40.1892647,28.3885531 40.3860942,28.5017769 40.5990741,28.5021435 L47.8003602,28.5021435 C48.0011075,28.501105 48.1880393,28.3997564 48.2984491,28.2320953 L51.8990922,22.8311307 C52.0336359,22.6294726 52.0336359,22.3666699 51.8990922,22.1650117 L48.2984491,16.7700482 Z M47.4763023,27.3019292 L41.6192563,27.3019292 L44.1217032,22.7951243 C44.228902,22.6094505 44.228902,22.3806909 44.1217032,22.1950171 L41.6192563,17.7002144 L47.4823034,17.7002144 L50.6808746,22.5010718 L47.4763023,27.3019292 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
