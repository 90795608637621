import React     from "react"

const SvgIcon = () =>
{
    return <svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13" width="10" height="14">
    <title>4_SIGN IN PAGE</title>
    <g id="4_SIGN IN PAGE">
        <g id="SIGN UP FORM">
            <g id="BACK">
                <g id="Back Arrow">
                    <g id="Back Arrow">
                        <path id="Shape" className="shp0" d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z" />
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
