import                              "./index.scss";
import React                        from "react";
import {Link}                       from "react-router-dom";
import {connect}                    from "react-redux";
import ReactTooltip                 from 'react-tooltip';
import LogoSrc                      from '../../assets/icons/logo';
import InlineIcon                   from '../ui/icon/inline-icon.jsx';
import GoogleIcon                   from "../ui/icon/google"
import SSOIcon                      from "../ui/icon/sso"
import * as userActions             from '../../actions/user';
import * as baseUrlActions          from '../../actions/baseUrl';
import config                       from '../../../../config';
import { getCompanyConfiguration }  from '../../services/userService';
import BackdropLoader               from '../backdrop-loader';
import URLSearchParams              from "url-search-params";
import { removeLocalStorageItem, createLinkUrl }   from '../../helpers/components-helper';

export class SignIn extends React.Component
{
    constructor(props, context)
    {
        super(props, context);
        this.onSubmitForm            = this.onSubmitForm.bind(this);
        this.setSubmitCb             = this.setSubmitCb.bind(this);
        this.state  = {
            email: null,
            password: null,
            errorMessage: "",
        };
    }

    onSubmitForm(event)
    {
        event.preventDefault();
        let payload = { payload: {email: this.state.email, password: this.state.password,timeOffset: new Date().getTimezoneOffset(), successCb: this.setSubmitCb , jwtAuth: true, vendor: 'ONSHAPE' }};
        this.props.dispatch(userActions.signIn(payload));
    };

    setSubmitCb(response)
    {
        if(response && response.signedToken)
        {
            localStorage.setItem('loadMain', false);
            this.props.history.push({pathname: '/oauthCodeRequest'});
        }
        else
        {
            this.setState({errorMessage: "Invalid account credentials"});
        }
    }

    onInputChange(event)
    {
        let name  = event.target.name;
        let value = event.target.value;
        switch (name)
        {
            case "email":
                this.setState({email: value });
                break;
            case "password":
                this.setState({password: value });
                break;
        }
    }

    componentDidMount()
    {
        if(window && window.localStorage && window.localStorage.queryParams)
        {
            removeLocalStorageItem("baseUrl");
            removeLocalStorageItem("clientBaseUrl");
            let urlParams = new URLSearchParams(window.localStorage.queryParams);
            let companyId = urlParams.has('companyId') ? urlParams.get('companyId') : null;
            this.props.dispatch(baseUrlActions.getBaseUrl(companyId));
        }
        ReactTooltip.rebuild();
        let bodyClass = this.props.bodyClass ? (this.props.bodyClass + ' authentication') : 'authentication';
        document.querySelector("body").setAttribute('data-modal', bodyClass);
    }

    componentWillUnmount()
    {
        document.querySelector("body").removeAttribute('data-modal');
    }

    handleGoogleSignIn (e) {
        e.preventDefault();
        const getGoogleOAuth2Link = () =>
        {
            const paramsStr = JSON.stringify({
                timeOffset: new Date().getTimezoneOffset(),
                jwtAuth: true,
                vendor: 'ONSHAPE'
            });
            const paramsValue = Buffer.from(paramsStr).toString("base64");
            return `${config.API_ORIGIN}/auth/google/signin?params=${paramsValue}`;
        }

        const strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
        const authWin = window.open(getGoogleOAuth2Link(), '_blank', strWindowFeatures).focus();
        if (window.focus()) {
            authWin.focus();
        }
    }

    render()
    {
        const {location, signInLoadingReducer} = this.props;
        const markup =
        <BackdropLoader isLoading={signInLoadingReducer.signInLoading}>
            <div className='signin-page disable-screen'>
                    <title>Sign In</title>
                <div className="logo-container">
                    <InlineIcon> <LogoSrc /> </InlineIcon>
                    <h3>
                        Simple and Fast PLM. Finally.
                    </h3>
                </div>
                <div className="signin-form">
                    <form onSubmit={this.onSubmitForm}>
                        <div className="header-block">
                            <h2>Sign in </h2>
                            <p>{this.state.errorMessage}</p>
                        </div>
                        <div className="form-control">
                            <label className="input-label" htmlFor={"email"}>Email</label>
                            <input
                                onChange={(event) => this.onInputChange(event)}
                                type="text"
                                name="email"
                                data-place="right"
                            />
                        </div>
                        <div className="form-control">
                            <label className="input-label" htmlFor={""}>Password</label>
                            <input
                                name="password"
                                type="password"
                                className="password-field"
                                onChange={(event) => this.onInputChange(event)}
                            />
                        </div>
                        <div className="remember-me-container">
                            <label className="checkbox-holder">
                                <input type="checkbox"/>
                                <span className="checkmark"></span>
                            </label>
                            <span> Remember Me? </span>
                        </div>
                        <div className='submit-block flex-h flex-v'>
                            <a className="password-link" href={`${createLinkUrl("reset")}`} target="_blank">
                                Forgot your password?
                            </a>
                            <input type="submit" className="" value="Sign In"/>
                        </div>
                        <div className='flex-h flex-v'>
                            <div className="or-block">
                                OR
                            </div>

                            <div className="oauth2-block">
                                <a onClick={this.handleGoogleSignIn}>
                                    <button type="button">
                                        <InlineIcon>
                                            <GoogleIcon/>
                                        </InlineIcon>
                                        Sign in with Google
                                    </button>
                                </a>
                            </div>
                            <div className="oauth2-block">
                                <Link to="/signin-with-sso">
                                    <button className="sso" type="button">
                                        <InlineIcon>
                                            <SSOIcon/>
                                        </InlineIcon>
                                        Sign in with SSO
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="signup-block">
                    <span>Don’t have an account?</span> <a href={`${createLinkUrl("signup")}`} target="_blank">Sign Up.</a>
                </div>
            </div>
         </BackdropLoader>

        return markup
    }
}

export default connect((store) => store)(SignIn);
