import { put, call, takeLatest, cps, all, cancelled, select } from 'redux-saga/effects';
import { authRefreshtokenOnshapeService }                     from '../services/authenticationService';
import * as duroActions                                       from '../actions/duro'
import * as userActions                                       from '../actions/user';
import { signInService }                                      from '../services/userService';

export function* signIn({ payload })
{
    try
    {
        yield put(duroActions.duroLoading(true));
        let authenticated = yield cps(signInService, payload);
        if(!authenticated)
        {
            payload.payload.successCb(null);
        }
        else
        {
            yield put(duroActions.duroLoading(false));
            localStorage.setItem("jwtoken", authenticated.signedToken);
            payload.payload.successCb(authenticated);
        }
    }
    catch(error)
    {
        console.log('----------------in saga error----------------------', error);
    }
}

export default function* watchAuthProcess()
{
  yield takeLatest(userActions.signIn().type, signIn);
}
