import                                              "./index.scss"
import React, { Component }                     from 'react';
import { connect }                              from 'react-redux';
import { RingLoader }                           from 'react-spinners';
import { spinnerStyles, sizeUnit, size, color } from '../../helpers/spinnerStyles';
import * as componentActions                    from '../../actions/component';
import WarningModalBox                          from "../ui/warning-modal-box/index.jsx";
import ShowError                                from "../ui/show-error";
import EditIcon                                 from '../../assets/icons/edit-icon';
import NotTrackedIcon                           from '../../assets/icons/not-tracked';
import InlineIcon                               from "../ui/icon/inline-icon.jsx";
import SyncIcon                                 from '../../assets/icons/sync-icon';
import SyncedIcon                               from '../../assets/icons/synced-icon';
import LeftArrow                                from '../../assets/icons/left-arrow';
import ReactTooltip                             from 'react-tooltip'
import TabsHeading                              from './common/tabs-headings/index.jsx'
import TopHeader                                from './common/top-header/index.jsx'
import ComponentView                            from './view/index.jsx'
import ComponentEdit                            from './edit/index.jsx'
import { getPropertyObj, generateKey, getConfigurationString } from '../../helpers/components-helper';
import * as assemblyActions                     from '../../actions/assemblies';
import ErrorIcon                                from '../../assets/icons/error-icon';

class ShowComponent extends Component {

    constructor(props, context)
    {
        super(props, context);
        this.Update          = this.Update.bind(this);
        this.onCloseModal    = this.onCloseModal.bind(this);
        this.getData         = this.getData.bind(this);
        this.show            = this.show.bind(this);
        this.switch          = this.switch.bind(this);
        this.isModified      = this.isModified.bind(this);
        this.state           = {
                                    editPageErrors: {},
                                    showErrorModal: false,
                                    massObj: {}
                                };
    }

    isModified(actualComponentDuro, componentDuro)
    {
        if(actualComponentDuro && componentDuro)
        {
            return JSON.stringify(actualComponentDuro) !== JSON.stringify(componentDuro);
        }
        return true;
    }

    show(e)
    {
        let tab = event.target.getAttribute("name");
        this.props.dispatch(componentActions.switchTab({ tab }));
    }

    switch(to, preservePrevComponent=false, retrievePrevComponent=false, updateComponentMetadata=false)
    {
        if(updateComponentMetadata)
        {
            this.Update(to, preservePrevComponent, retrievePrevComponent);
        }
        else
        {
            this.props.dispatch(componentActions.resetFormInputs());
            this.props.dispatch(componentActions.switchView({ to, preservePrevComponent, retrievePrevComponent }));
        }
    }

    formatDate(dateInNumber)
    {
        let dateString     = new Date(dateInNumber);
        let formatted_date = dateString.getFullYear() + "-" + (dateString.getMonth() + 1) + "-" + dateString.getDate();
        return formatted_date;
    }

    getPropertyObj(array, toFind)
    {
        let property = array.find((obj) => obj.name == toFind);
        let value = property ? property.value : null;
        if(property && property.valueType && property.valueType.toLowerCase() === 'enum')
        {
            value = property.enumValues[value].label;
        }
        return { value, propertyId: property && property.propertyId, name: property && property.name };
    }

    Update(to, preservePrevComponent, retrievePrevComponent)
    {
        let payload = this.getData();
        let {dispatch, partId} = this.props;
        const afterUpdate = (err) =>
        {
            if(!err)
            {
                dispatch(componentActions.switchView({ to, preservePrevComponent, retrievePrevComponent }));
                let {elementId, documentId, documentVersion, workspaceId, configurationString, href, isStandardContent} = payload.onShapeData;
                elementId   = elementId ? elementId : href.split('/')[10];
                workspaceId = workspaceId ? workspaceId : href.split('/')[8];
                if(partId)
                {
                    const payloadForRefresh = {partId, elementId, documentVersion, documentId, workspaceId, isStandardContent, configurationString};
                    dispatch(componentActions.getComponent(payloadForRefresh));
                }
                else
                {
                    const payloadForRefresh = {elementId, documentVersion, documentId, workspaceId, configurationString};
                    dispatch(assemblyActions.getAssemblyMetaData(payloadForRefresh));
                }
            }
            else
            {
                this.setState({editPageErrors: err, showErrorModal: true});
                dispatch(componentActions.isLoading(false));
            }
        };

        payload.successCb = afterUpdate;
        dispatch(componentActions.updateComponentMetadata(payload));
    }

    getData()
    {
        const {inputs, OnShapeData, partId, elementId, queryParams} = this.props;
        let descriptionPropObj      = getPropertyObj(OnShapeData.properties, 'Description');
        descriptionPropObj.value    = inputs.description.value;
        let properties              = [{propertyId: descriptionPropObj.propertyId, value: inputs.description.value}];
        let componentDuro           = this.props.componentDuro
        let actualComponentDuro     = this.props.actualComponentDuro
        let dataForOnShape  = { href: OnShapeData.href,
                                properties,
                                partId,
                                elementId,
                                documentId: OnShapeData.documentId,
                                documentVersion: OnShapeData.documentVersion,
                                workspaceId: OnShapeData.workspaceId,
                                configurationString: getConfigurationString(OnShapeData),
                                isStandardContent: OnShapeData.isStandardContent
                               };
        let payload         =
        {
            onShapeData: dataForOnShape,
            componentDuro,
            actualComponentDuro
        };
        return payload;
    }

    onCloseModal = () =>
    {
        this.setState({showErrorModal: false, editPageErrors: {}})
    }

    render()
    {
        const {partId, elementId, componentDuro, tab, view, isSyncing, isSynced, OnShapeData, isLoading, dispatch, queryParams}  = this.props;
        const {editPageErrors, massObj, showErrorModal} = this.state;
        const OnShapeComp  = OnShapeData && OnShapeData.mimeType !== "onshape/partstudio" ? OnShapeData : null;
        let errors         = [];
        if(editPageErrors.errors)
        {
            for(let [key, value] of Object.entries(editPageErrors.errors))
            {
                let message = value.message;
                if(value.path === 'manufacturers' && message === 'Must contain at least 1 character')
                {
                    message = `Manufacturers must contain at least 1 character`;
                }
                errors.push(<li key={generateKey()}>{message}</li>)
            }
            if(errors.length === 0)
            {
                errors.push(<li>Sorry, we are unable to update this component.</li>)
            }
        }
        const isModified  = componentDuro ? this.isModified(this.props.actualComponentDuro, this.props.componentDuro) : true
        const markup =
            <div className={`${isLoading ? 'component-loader' : ''}`}>
            {
               isLoading ?
                    <RingLoader
                        css={spinnerStyles}
                        sizeUnit={sizeUnit}
                        size={size}
                        color={color}
                        loading={true}
                    /> : OnShapeComp ?
                    <div className={`sidebar-content ${view}`}>
                       <TopHeader
                           isModified={isModified}
                           componentDuro={componentDuro}
                           switch={this.switch}
                           view={view}
                           OnShapeComp={OnShapeComp}
                           getPropertyObj={this.getPropertyObj}
                           OnShapeData={this.props.OnShapeData}/>   

                        <div className="sidebar-body">
                        <TabsHeading
                            tab={tab}
                            show={()=> this.show()}
                            OnShapeComp={OnShapeComp}
                        />
                            <div className="tab-content">
                                {
                                    tab === "Specifications" && view ==='view' ?
                                        <ComponentView
                                            formatDate={this.formatDate}
                                            OnShapeComp={OnShapeComp}
                                            componentDuro={componentDuro}
                                            massObj={massObj}
                                            dispatch={dispatch}
                                            queryParams={queryParams}
                                        />
                                        :
                                        tab === "Specifications" && view ==='edit' ?
                                        <ComponentEdit
                                            formatDate={this.formatDate}
                                            massObj={massObj}
                                            queryParams={queryParams}
                                        />
                                        :
                                        null
                                }
                                {
                                    errors && errors.length > 0 &&
                                    <div className="error-modal">
                                        <WarningModalBox>
                                            <div className='error-modal-header'>
                                                <InlineIcon className="modal-sync-icon">
                                                    <ErrorIcon/>
                                                </InlineIcon>
                                                <span className="">Cannot update due to following Reason(s):</span>
                                            </div>
                                            <div className="modal-content-inner">
                                                {errors}
                                            </div>
                                            <div className="modal-footer">
                                                <div>
                                                    <input
                                                        type='button'
                                                        value='OK'
                                                        onClick={this.onCloseModal}
                                                    />
                                                </div>
                                            </div>
                                        </WarningModalBox>
                                    </div>
                                }
                            </div>
                        </div>
                        <div>
                        {
                            showErrorModal &&
                            <ShowError errorMessage={editPageErrors} hideErrorModal={this.onCloseModal}/>
                        }
                        </div>
                    </div>
                    :
                    <div className='empty-part-view'>
                        <InlineIcon>
                            <LeftArrow/>
                        </InlineIcon>
                        <div>Select a part in Assembly Tree<br/>to view data.</div>
                     </div>
                }
            </div>

        return markup
    }
}

const mapStateToProps = ({componentReducer, oauthReducer}) => ({
    partId                 : componentReducer.partId,
    elementId              : componentReducer.elementId,
    componentDuro          : componentReducer.componentDuro,
    OnShapeData            : componentReducer.OnShapeData,
    isLoading              : componentReducer.isLoading,
    tab                    : componentReducer.tab,
    view                   : componentReducer.view,
    actualComponentDuro    : componentReducer.actualComponentDuro,
    needToSyncOnOnshape    : componentReducer.needToSyncOnOnshape,
    inputs                 : componentReducer.inputs,
    queryParams            : oauthReducer.queryParams
});

export default connect(mapStateToProps)(ShowComponent);
