import base  from "./data/default.json";
import merge from "merge";

// NOTE: Obviously there's duplication of code but there's no good way to do this properly now.
//       Let's revisit this later. For now, any change to the `production.json` file should also
//       be reflected on the file `server/middlewares/prod-middleware.js`.

let loadData = (file) => {
    try {
        return require(`./data/${file}.json`);
    }
    catch(err) {
        return {};
    }
}

// Sets of configurations of different environments are hard coded as json files in the data directory.
// At runtime we use a web server to serve the client app. The ENV environment variable is injected to
// the server, which is used to inject to the response back to the web browser as `window.ENV`. This
// is setup is in the legacy code.
// To externalize the configurations, we expose additional configurations other than ENV as environment
// variables for the web server to inject into the response.
function windowOverride(cfg) {
    cfg.ONSHAPE_CLIENT_ID = process.env.ONSHAPE_CLIENT_ID || window.ONSHAPE_CLIENT_ID || cfg.ONSHAPE_CLIENT_ID;
    cfg.API_ORIGIN = process.env.API_ORIGIN || window.API_ORIGIN || cfg.API_ORIGIN;
    cfg.CLIENT_ORIGIN = process.env.CLIENT_ORIGIN || window.CLIENT_ORIGIN || cfg.CLIENT_ORIGIN;
    cfg.REDIRECT_URI = process.env.REDIRECT_URI || window.REDIRECT_URI || cfg.REDIRECT_URI;
    cfg.PUSHER_APP_KEY = process.env.PUSHER_APP_KEY || window.PUSHER_APP_KEY || cfg.PUSHER_APP_KEY;
    cfg.PUSHER_APP_CLUSTER = process.env.PUSHER_APP_CLUSTER || window.PUSHER_APP_CLUSTER || cfg.PUSHER_APP_CLUSTER;
    cfg.AUTH_URL = process.env.AUTH_URL || window.AUTH_URL || cfg.AUTH_URL;
    cfg.ONSHAPE_BASE_URL = process.env.ONSHAPE_BASE_URL || window.ONSHAPE_BASE_URL || cfg.ONSHAPE_BASE_URL;

    return cfg;
}

let ENV = window.ENV || "production";
let config = merge.clone(base);
let data = loadData(ENV);
config = merge.recursive(true, config, data);
config.ENV = ENV;
config = windowOverride(config);

export default config
