import Defaults                                 from '../../../config';
import axios                                    from 'axios';
import { getJWToken }                           from './jwt-token-service';
import history                                  from '../history.jsx';
import { getLocalStorageItem }                  from '../helpers/components-helper';

class serviceCalls
{
    static get(path, cb)
    {
        const jwToken = getJWToken();
    	const headers =
		{
          headers     :
          {
            "Content-Type": "application/json",
            "Authorization": `BEARER ${jwToken}`
          }
		}
        let modifiedBaseUrl = getLocalStorageItem("baseUrl");
        const url = modifiedBaseUrl && Defaults.ENV !== 'local' ? modifiedBaseUrl + path : Defaults.API_ORIGIN + path;
        return new Promise((resolve, reject) =>
        {
            axios.get(url, headers)
            .then( (response) =>
            {
                resolve(response.data);
            })
            .catch((error) =>
            {
                if(error.response && error.response.status === 401)
                {
                    history.push("/signin");
                }
                else if(error.response && error.response.status === 400)
                {
                    localStorage.setItem("jwtoken", "");
                    history.push("/signin");
                }
                else
                {
                    reject(error.response);
                }
            });
        });
    }

    static post(path, data, cb, addOptions = true)
    {
        const jwToken = getJWToken();
        const headers =
        {
          headers     :
          {
            "Content-Type": "application/json",
            "Authorization": `BEARER ${jwToken}`
          }
        }
        let modifiedBaseUrl = getLocalStorageItem("baseUrl");
        const url = modifiedBaseUrl && Defaults.ENV !== 'local' ? modifiedBaseUrl + path : Defaults.API_ORIGIN + path;
        return axios.post(url, data, headers)
        .then( (response) =>
        {
            cb(null, response.data);
        })
        .catch((error) =>
        {
            if(error.response && error.response.status === 401)
            {
                history.push("/signin");
            }
            else if(error.response && error.response.status === 400)
            {
                localStorage.setItem("jwtoken", "");
                history.push("/signin");
            }
            else
            {
                cb(error.response);
            }
        });
    }
}

export default serviceCalls;
