import React, { Component }                     from 'react';
import EditIcon                                 from '../../../../assets/icons/edit-icon';
import PartIcon                                 from '../../../../assets/icons/part-icon-dark.jsx';
import InlineIcon                               from "../../../ui/icon/inline-icon.jsx";
import SyncIcon                                 from '../../../../assets/icons/sync-icon';
import SyncedIcon                               from '../../../../assets/icons/synced-icon';
import ReactTooltip                             from 'react-tooltip';
import                                               "./index.scss";
import AssemblyIcon                             from '../../../../assets/icons/assembly-icon-dark.jsx';
import {getComponentDisplayName}                       from '../../../../helpers/components-helper';

class TopHeader extends Component {

    constructor(props, context)
    {
        super(props, context);
    }

    componentDidMount()
    {
        ReactTooltip.rebuild();
    }

    render()
    {
        const {view, componentDuro, OnShapeComp, isModified, OnShapeData}         = this.props;
        let {documentVersion, isStandardContent} = OnShapeData;
        let name            = '';
        let isLinkedComponent = documentVersion && !isStandardContent;
        let editTooltip = isLinkedComponent ? 'Linked Document. This component must be edited in the Duro PLM app within the source document.' : 'Edit';
        let editTooltipClass = `edit-tooltip ${isLinkedComponent && 'disabled'}`;
        let editTooltipPlace = isLinkedComponent ? 'left' : 'bottom';
        let editTooltipDataId = isLinkedComponent ? 'edit-icon-tooltip' : null;
        if(OnShapeComp)
        {
            name = getComponentDisplayName(OnShapeComp);
        }

        return (
            <div className={`sidebar-header ${view}`}>
                <div className='sidebar-heading'>
                   {/* <InlineIcon onClick={''}>
                        { OnShapeComp.partId ? <PartIcon/> : <AssemblyIcon/> }
                    </InlineIcon>*/}
                    <span className={'heading-content'}>
                        { name && name.value ? name.value : ''}
                    </span>
                </div>
                {view === 'view' ? (
                <div className='sidebar-icon'>
                    <InlineIcon
                        onClick={!isLinkedComponent ? ()=> this.props.switch('edit', true, false) : null}
                        tooltip={editTooltip}
                        tooltipPlace = {editTooltipPlace}
                        className={editTooltipClass}
                        dataId={editTooltipDataId}>
                        <EditIcon/>
                    </InlineIcon>
                </div>
                )
                : (
                    <div className='action-buttons'>
                        <input
                            type='button'
                            className='cancel-btn'
                            onClick={()=>this.props.switch('view', false, true)}
                            value='Cancel' />
                        <input onClick={()=>this.props.switch('view', false, false, true)}
                            className='save-btn'
                            type='submit'
                            value='SAVE'/>
                    </div>
                )}
          </div>
        );
    }
}

export default TopHeader;
