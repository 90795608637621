import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="21px" height="18px" viewBox="0 0 21 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-EMPTY" transform="translate(-1641.000000, -98.000000)" fill="#888888" fillRule="nonzero">
            <path d="M1652.378,115.455398 C1651.96337,115.00704 1651.96337,114.273363 1652.378,113.825005 L1657.65519,108.118628 L1642.05544,108.118628 C1641.45233,108.118628 1641,107.58875 1641,106.977353 L1641,106.855074 C1641,106.202916 1641.49002,105.713798 1642.05544,105.713798 L1657.65519,105.713798 L1652.378,99.9666619 C1651.96337,99.5183037 1651.96337,98.7846268 1652.378,98.3362686 C1652.79264,97.8879105 1653.47113,97.8879105 1653.88577,98.3362686 L1661.27383,106.325196 C1661.57539,106.651274 1661.57539,107.140392 1661.27383,107.466471 L1653.88577,115.455398 C1653.47113,115.903756 1652.79264,115.903756 1652.378,115.455398 Z" id="Arrow" transform="translate(1651.250000, 106.895833) rotate(-180.000000) translate(-1651.250000, -106.895833) "></path>
        </g>
    </g>
</svg>
}

export default SvgIcon
