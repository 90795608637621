import serviceCalls from './serviceCalls';

export const getDocumentAssemblies = (payload, cb) =>
{
    const {queryParams} = payload;
    const path = `/onshape/getDocumentAssemblies?documentID=${queryParams.documentId}&workspaceID=${queryParams.workspaceId}`;
    serviceCalls.get(path).then((response) =>
    {
        cb(null, response.data);
    }).catch((error) =>
    {
        cb(error);

    });
};

export const getAssemblyMetaData = (payload, queryParams, cb) =>
{
    let {documentId, workspaceId, elementId, documentVersion, configurationString, ignoreMass} = payload;
    let path = `/onshape/getAssemblyMetaData?documentID=${documentId}&workspaceID=${workspaceId}&elementId=${elementId}`;
    if(documentVersion)
    {
        path = `${path}&documentVersion=${documentVersion}&linkDocumentId=${queryParams.documentId}`;
    }
    if(configurationString)
    {
        path = `${path}&configuration=${configurationString}`;
    }
    if(ignoreMass)
    {
        path = `${path}&ignoreMass=${ignoreMass}`;
    }
    serviceCalls.get(path).then((response) =>
    {
        cb(null, response.data);
    }).catch((error) =>
    {
        cb(null, error);
    });
};

export const updatePartNumberService = (payload, cb) =>
{
    let path = `/onshape/updatePartNumber`;
    serviceCalls.post(path, {payload}, (err, res) =>
    {
        if (err)
        {
            cb(err, null);
        }
        else if(res.success)
        {
            cb(null, res.data);
        }
    });
};

export const getPartsInPartStudio = (payload, cb) =>
{
    let {companyId, documentId, workspaceId, partStudioId, shouldResyncBom=false} = payload;
    let path = `/onshape/getPartsInPartStudio?companyId=${companyId}&documentId=${documentId}&workspaceId=${workspaceId}&partStudioId=${partStudioId}&shouldResyncBom=${shouldResyncBom}`;
    serviceCalls.get(path).then((response) =>
    {
        cb(null, response.data);
    }).catch((error) =>
    {
        cb(error);
    });
};
