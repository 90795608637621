import React, { Component }      from "react"

const SvgIcon = () =>
{
  return <svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17'>
      <g fill='none' fillRule='evenodd' stroke='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1'>
        <g fill='#F54A4F' stroke='#FFF' strokeWidth='0.451' transform='translate(-1242 -85)'>
          <g transform='translate(1062 85)'>
            <path d='M197.386252 16.302853L181.177815 16.302853 189.471544 0.664105515z'></path>
          </g>
        </g>
      </g>
    </svg>
}

export default SvgIcon
