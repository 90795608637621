import                      "./index.scss";
import React, { useState }  from "react";
import SsoSigninForm        from "./sso-signin-form/index.jsx";
import { checkDomainExist } from "../../services/userService";
import config               from '../../../../config';
import validations, {validateField} from '../../modules/validations';
import Utils                from "../../utils";

const SSOIntegration = () => {
    const [input, setInput] = useState({value: "", message: "", class: "", valid: false});
    const [stateValues, setStateValues] = useState({isEmail: false, flag: true, label: "SSO-Company Domain", displayText: "I don't know my company domain", isDomainExist: false});

    const errorMessage = (err) => 
    {
        return (err && err.data && err.data.errors) ? err.data.errors[0].message : ""
    }

    const domainCheck = (value) => {
        let { isEmail } = stateValues;
        let data = isEmail ? { email: value } : {domainName: value};
        checkDomainExist(data, (err, res) =>
        {
            if(err)
            {
                setInput({value: value, valid: false, message: errorMessage(err), class: "error"});
                return;
            }
            setInput({value: value, valid: true, message: "", class: ""});
        })
    }

    const onInputChange = event =>
    {
        let { isEmail } = stateValues;
        let value = event.target.value;
        if(isEmail)
        {
            validateField(input, validations.user.email, value);
            if(input.valid)
                domainCheck(value);
            else
            {
                input.class = "error";
                input.message = "Company domain not found";
                setInput({...input});
            }
        }
        else
        {
            if(Utils.hasSpecialCharExceptHyphen(value))
                setInput({value: value, message: "Company domain not found", valid: false, class: "error"});
            else
                domainCheck(value);
        }
    };

    const samlRequest = () =>
    {
        let { isEmail } = stateValues;
        let param = isEmail ? `email=${input.value}` : `domainName=${input.value}`;        
        const strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
        let authWin =  window.open(`${config.API_ORIGIN}/saml/initialize?${param}&vendor=ONSHAPE` , '_blank', strWindowFeatures).focus();
        if (window.focus()) {
            authWin.focus();
        }
    }

    const navigatePage = () =>
    {
        let { flag } = stateValues;
        let newObj  = {
            flag: !flag,
            label: !flag ? "SSO-Company Domain" : "Company Email",
            displayText: !flag ? "I don't know my company domain" : "I know my company Domain",
            isEmail: flag,
        }
        setStateValues(newObj);
        setInput({value: "", message: "", class: "", valid: false});
    };

    const onBack = () => {
        let { flag } = stateValues;
        if(!flag)
            navigatePage();
        else
            window.location.href = "/signin";
    }

    let { label, displayText, flag } = stateValues;
    let continueBtnClass = `continue-button ${input.valid ? '' : 'disabled'}`;
    return(
        <div className="content-block sign-in-with-sso">
            <SsoSigninForm
                flag={flag}
                input={input}
                label={label}
                onBack={onBack}
                displayText={displayText}
                navigatePage={navigatePage}
                onInputChange={onInputChange}
                onContinueClick={samlRequest}
                continueBtnClass={continueBtnClass}
            />
        </div>
    );
}

export default SSOIntegration;
