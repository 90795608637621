import "./index.scss";
import React, { useCallback, useEffect } from "react";
import InlineIcon from "../icon/inline-icon.jsx";
import Menu from "../menu/index.jsx";
import DuroLogoSrc from "../../../assets/icons/duro-logo";
import AssemblyIconSrc from "../../../assets/icons/assembly-icon-light-blue.jsx";
import LibraryIconSrc from "../../../assets/icons/library-icon";
import HelpIconSrc from "../../../assets/icons/help-icon";
import SettingIconSrc from "../../../assets/icons/settings-icon";
import { setJwToken, removeLocalStorageItem } from "../../../helpers/components-helper";
import SignOutIconSrc from "../../../assets/icons/sign-out-icon";
import SyncIconSrc from "../../../assets/icons/sync-icon";
import WarningIconSrc from "../../../assets/icons/warning-icon";
import Config from "../../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { getIsOnshapeCompanyAdmin } from "../../../actions/oauth";
import { resetWebhooks } from "../../../services/duroService";
import * as duroActions from "../../../actions/duro";
import config from "../../../../../config";

const LeftSideBarNavigationMenu = (props) => {
    const dispatch = useDispatch();
    const isAdmin = useSelector(
        (state) => state.oauthReducer.isOnshapeCompanyAdmin && state.userReducer.user.role === "ADMINISTRATOR",
    );

    useEffect(() => {
        dispatch(getIsOnshapeCompanyAdmin());
    }, [dispatch]);

    const signOut = useCallback(() => {
        setJwToken("");
        if (Config.ENV === "production") {
            try {
                window.FS.identify(false);
            } catch (error) {
                console.log(error);
            }
        }
        removeLocalStorageItem("baseUrl");
        removeLocalStorageItem("clientBaseUrl");
        props.history.push("/signin");
    }, [props.history]);

    const handleResetWebhooks = useCallback(async () => {
        try {
            await resetWebhooks();
            props.history.go(0);
        } catch (error) {
            if (config.ENV === "local") {
                console.error("Error resetting webhooks", error);
            }

            dispatch(
                duroActions.setWebhookModal({
                    icon: <WarningIconSrc />,
                    visibility: true,
                    headerText: "Onshape Connection Reset Failed",
                    bodyText: "The connection to Onshape could not be reset. Please try again in a few minutes.",
                    displayBtn: true,
                }),
            );
        }
    }, [props.history]);

    const actionsList = React.useMemo(() => {
        const actions = [
            {
                label: "Sign Out",
                icon: <SignOutIconSrc />,
                to: "",
                onClick: signOut,
                disabled: "",
                labelWapperClass: "menu-option",
                key: "sign-out",
            },
        ];

        if (isAdmin) {
            actions.unshift({
                label: "Reset Webhooks",
                icon: <SyncIconSrc />,
                to: "",
                onClick: handleResetWebhooks,
                disabled: "",
                labelWapperClass: "menu-option",
                key: "reset-webhooks",
            });
        }

        return actions;
    }, [isAdmin, handleResetWebhooks, signOut]);

    return (
        <div className="icon-list">
            <div className="side-nav-logo">
                <InlineIcon>
                    <DuroLogoSrc />
                </InlineIcon>
            </div>
            <ul>
                <li>
                    <InlineIcon className={props.currentNav === "assembly" ? "selected" : ""}>
                        <AssemblyIconSrc />
                    </InlineIcon>
                </li>
                <li className="display-none">
                    <InlineIcon>
                        <LibraryIconSrc />
                    </InlineIcon>
                </li>
            </ul>
            <ul className="bottom-list">
                <li>
                    <a href="https://duro.zendesk.com/hc/en-us/articles/360029171772" target="_blank">
                        <InlineIcon>
                            <HelpIconSrc />
                        </InlineIcon>
                    </a>
                </li>
                <li>
                    <Menu options={actionsList}>
                        <button className="menu-trigger" type="button">
                            <InlineIcon className="setting-wheel-icon">
                                <SettingIconSrc />
                            </InlineIcon>
                        </button>
                    </Menu>
                </li>
            </ul>
        </div>
    );
};

export default LeftSideBarNavigationMenu;
