import React, { Component }  from 'react';
import                            "./index.scss";
import EditIcon              from '../../../../assets/icons/edit-icon';
import NotTrackedIcon        from '../../../../assets/icons/not-tracked';
import InlineIcon            from "../../../ui/icon/inline-icon.jsx";
import SyncIcon              from '../../../../assets/icons/sync-icon';
import SyncedIcon            from '../../../../assets/icons/synced-icon';

class TabsHeading extends Component {

    constructor(props, context)
    {
        super(props, context)
    }

    render()
    {
        const {tab, show, OnShapeComp}         = this.props;
        return (
                <div className="sidebar-tabs">
                    <div className="tabs">
                        <div
                            className={tab === "Specifications" ? "selected" : ""}
                            name="Specifications"
                            onClick={show}
                            >
                            {OnShapeComp.partId ? 'Part Details' : 'Assembly Details'}
                        </div>
                    </div>
                </div>
            );
    }
}

export default TabsHeading;
