import React from 'react';

const SvgIcon = () => (
    <svg
        width="52px"
        height="52px"
        viewBox="0 0 52 52"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="PART-LIGHT" transform="translate(1.000000, 1.000000)" stroke="#F4F4F4">
                <polygon
                    id="Path-2"
                    points="49.9241694 11.5409799 36.358482
                    11.5409799 36.358482 35.8920446 12.4148251
                    35.8920446 12.4148251 49.9247605 49.9241694
                    49.9247605"
                />
                <path d="M23.5,22.5 L36.0124313,35.830237" id="Path-3" />
                <path d="M24.5,0.5 L36.5,11.5" id="Path-4" />
                <path d="M0.449013379,23.5507807 L13.0229932,36.1247606" id="Path-5" />
                <polygon
                    id="Path"
                    points="40.1560483 0.404027071 49.9595887
                    9.91905396 49.9595887 49.9247605 11.3759637
                    49.9247605 0.464804516 40.4869023 0.464804516
                    23.2287103 24.0149911 23.2287103 24.0149911
                    0.404027071"
                />
            </g>
        </g>
    </svg>
);

export default SvgIcon;
