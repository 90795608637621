import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/index.jsx';

import './style/main.scss';

import configureStore from './store/configureStore';

const component = (
    <Provider store={configureStore()}>
        <App />
    </Provider>
);

ReactDOM.render(component, document.getElementById('root'));
