import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="8-MAKE-SELECTION" transform="translate(-713.000000, -240.000000)" fill="#1AC7A7" stroke="#1AC7A7">
            <g id="MODAL" transform="translate(523.000000, 52.000000)">
                <g id="Group-5" transform="translate(39.000000, 109.000000)">
                    <g id="TABLE">
                        <g id="CONTENT" transform="translate(49.000000, 36.000000)">
                            <g id="NAME">
                                <g id="Syncing" transform="translate(102.000000, 43.066667)">
                                    <g id="noun_Refresh_1921312" transform="translate(0.789474, 0.750000)">
                                        <path d="M5.17300769,0.905030145 L2.12381547,0.0189685079 C1.85075348,-0.0548699619 1.56252138,0.0928069776 1.48667083,0.358625469 L1.48667083,0.358625469 C1.41082028,0.62444396 1.56252138,0.905030145 1.83558337,0.978868615 L3.6104863,1.4957379 C1.47150072,2.45563801 0,4.56741824 0,7.00408775 C0,9.61796957 1.69905238,11.8478914 4.06558962,12.7191853 C4.39933205,12.8373269 4.7634147,12.6010438 4.7634147,12.2613868 C4.7634147,12.0546391 4.62688371,11.8626591 4.42967227,11.7888206 C2.44238779,11.0652036 1.0163974,9.18970645 1.0163974,7.00408775 C1.0163974,4.90707521 2.32102691,3.09064885 4.20212061,2.30796107 L3.6104863,4.1982259 C3.53463575,4.46404439 3.68633686,4.74463057 3.95939885,4.81846904 L3.95939885,4.81846904 C4.23246083,4.89230751 4.52069293,4.74463057 4.59654349,4.47881208 L5.50675012,1.5105056 C5.58260067,1.2594548 5.43089957,0.978868615 5.17300769,0.905030145 Z" id="Shape" strokeWidth="0.5" fillRule="nonzero"></path>
                                        <path d="M11.6006129,12.5222638 L9.82568421,12.0059925 C11.9495305,11.0324523 13.4210526,8.92311524 13.4210526,6.48926475 C13.4210526,3.87840695 11.7219756,1.65106498 9.35540395,0.780779046 C9.02165668,0.662774174 8.65756873,0.898783918 8.65756873,1.23804793 C8.65756873,1.44455645 8.79410171,1.63631437 8.99131601,1.71006742 C10.9786294,2.43284726 12.4046405,4.30617461 12.4046405,6.48926475 C12.4046405,8.58385123 11.099992,10.3981761 9.21887098,11.1799584 L9.81051388,9.29188047 C9.88636554,9.0263695 9.73466223,8.74610793 9.46159627,8.67235489 C9.18853032,8.59860184 8.90029403,8.74610793 8.82444237,9.0116189 L7.91422252,11.9764913 C7.83837087,12.2420023 7.99007418,12.5222638 8.26314013,12.5960169 L11.3123766,13.4810534 C11.5854426,13.5548065 11.8736789,13.4073004 11.9495305,13.1417894 L11.9495305,13.1417894 C12.0253822,12.8762785 11.8736789,12.5960169 11.6006129,12.5222638 Z" id="Shape" strokeWidth="0.5" fillRule="nonzero"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
