import                                   './index.scss';
import React, { Component }         from 'react';
import ReactTooltip                 from 'react-tooltip';
import InlineIcon                   from '../icon/inline-icon.jsx';
import CloseIcon                    from '../../../assets/icons/close.jsx';

class ModalBox extends Component
{
    constructor(props, context) {
        super(props, context);
        this.onClose = this.onClose.bind(this);
        this.state          = {
            closeClass : ''
        };
    }

    componentDidMount()
    {
        ReactTooltip.rebuild();
    }

    componentDidUpdate()
    {
        ReactTooltip.rebuild();
    }

    onClose(e)
    {
        if(document.getElementsByClassName('popover-tool-tip-container')[0])
        {
            document.getElementsByClassName('popover-tool-tip-container')[0].style.zIndex = '99';
        }
        let state = this.state;
        state.closeClass = 'zoomOutAnimation';
        this.setState(state);
        let _this = this;
        setTimeout( () => {
          _this.props.onClose(e);
        }, 300)
    }

    render()
    {
        let markup = null;
        markup =
            <div id={this.props.modalId ? this.props.modalId : ''} className={`zoomInAnimation ${this.props.className} ${this.state.closeClass}`}>
                <div className="background"
                    // onClick={this.props.reject}
                    >

                </div>
                <div className='modal'>
                    <div className={'modal-content'}>
                        {this.props.children}
                    </div>

                    <div className='buttons modal-close'>
                        {
                            !this.props.showCrossIcon ? null
                            :
                             <InlineIcon onClick={this.onClose}>
                                <CloseIcon/>
                            </InlineIcon>
                        }
                    </div>
                </div>
            </div>


        return markup;
    }
}

export default ModalBox;
