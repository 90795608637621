
import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="12-PULL-PART-NUMBERS-6D" transform="translate(-713.000000, -513.000000)">
            <g id="MODAL" transform="translate(562.000000, 161.000000)">
                <g id="NAME" transform="translate(49.000000, 36.000000)">
                    <g id="ERROR" transform="translate(102.000000, 316.000000)">
                        <g id="outline-error-24px">
                            <ellipse id="Oval-2" fill="#18171D" fillRule="nonzero" cx="7.875" cy="7.5" rx="3.375" ry="4.5"></ellipse>
                            <path d="M7.5,0 C3.36,0 0,3.36 0,7.5 C0,11.64 3.36,15 7.5,15 C11.64,15 15,11.64 15,7.5 C15,3.36 11.64,0 7.5,0 Z M8.25,11.25 L6.75,11.25 L6.75,9.75 L8.25,9.75 L8.25,11.25 Z M8.25,8.25 L6.75,8.25 L6.75,3.75 L8.25,3.75 L8.25,8.25 Z" id="Shape" fill="#F54A4F" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

}

export default SvgIcon
