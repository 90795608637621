import React, {Component} from 'react';
import InlineIcon               from '../../icon/inline-icon.jsx';
import AssemblyIconSrc          from '../../../../assets/icons/assembly-icon-light.jsx';
import ArrowSrc                 from '../../../../assets/icons/arrow-icon';
import AssemblyConfigurationIconLight from '../../../../assets/icons/assembly-configurations-icon-light';
import * as assemblyActions from '../../../../actions/assemblies';
import {isAlreadyFetching, updateFetchingPropertiesArray, getPropertyObj, getConfigurationString} from '../../../../helpers/components-helper';
import $ from 'jquery';
import Utils from '../../../../utils';

class Assembly extends Component
{
    constructor(props, context)
    {
        super(props);
        this.handleOnClickEvent = this.handleOnClickEvent.bind(this);
        this.state = {
            item: this.props.item,
            refreshAssembly: false,
            isRefreshing: false
        }
    }

    setChildrenParentAttribute(assembly, cpn)
    {
      if(assembly.nestedChildren)
      {
        for(let children of assembly.nestedChildren)
        {
          if(children.parent)
          {
            children.parent.cpn = cpn;
          }
          else
          {
            let cpnWithoutVariant = Utils.getCpnWithoutVariant(cpn);
            children.parent = {cpnWithoutVariant, configurationString: getConfigurationString(assembly)};
          }
        }
      }
    }

    getMetaData()
    {
        let {workspaceId, dispatch, fetchingProperties} = this.props;
        let {item}                  = this.state;
        let {elementId, documentId, documentVersion, fullConfiguration, configuration} = item;
        const successCb = (err, res) =>
        {
            if(err)
            {

            }
            else
            {
                let excludeFromBom = null;
                let nameProperty  = null;
                let cpn = '';
                let category = '';
                let duroId = '';
                let lastModified = '';
                if(res.componentDuro)
                {
                    cpn = res.componentDuro.cpn;
                    category = res.componentDuro.category;
                    duroId = res.componentDuro._id;
                    lastModified = res.componentDuro.lastModified;
                }

                if(res.onShapeData && res.onShapeData.properties)
                {
                    nameProperty              = getPropertyObj(res.onShapeData.properties, 'Name');
                    let excludFromBomProperty = getPropertyObj(res.onShapeData.properties, 'Exclude from BOM');
                    excludeFromBom             = excludFromBomProperty ? excludFromBomProperty.value : false;
                    item.href                 = res.onShapeData.href;
                }

                if(item.properties)
                {
                    item.properties.cpn      = {value: cpn};
                    item.properties.category = {value: category};
                    item.properties.duroId   = {value: duroId};
                    item.properties.lastModified = {value: lastModified};
                }
                else
                {
                    item.properties = {cpn: {value: cpn}, category: {value: category}, duroId: {value: duroId}, lastModified: {value: lastModified}};
                }
                this.setChildrenParentAttribute(item, cpn);

                if(nameProperty && item.name !== nameProperty.value)
                {
                    item.name = nameProperty && nameProperty.value;
                }
                item.excludeFromBom = excludeFromBom;
            }
            this.setState({item, isRefreshing: false});
        }

        this.setState({isRefreshing: true}, () =>
        {
            const payload = {elementId, documentVersion, documentId, workspaceId, successCb, configurationString: getConfigurationString(item), ignoreMass: true};
            dispatch(assemblyActions.getAssemblyMetaData(payload));
        });
    }

    componentDidUpdate(prevProps, prevState)
    {
        let isRefreshingAssembly = localStorage.getItem('refreshAssembly');
        if(isRefreshingAssembly === 'true' && !prevState.refreshAssembly)
        {
            this.setState({refreshAssembly: true});
        }

        if(!prevState.refreshAssembly && this.state.refreshAssembly)
        {
            this.getMetaData();
            this.setState({refreshAssembly: false});
        }
    }

    componentDidMount()
    {
        this.getMetaData();
    }

    handleOnClickEvent()
    {
        let {item} = this.state;
        let id     = `${item.elementId}${item.displayOrder}`;
        $(`#${id}`).removeClass(item.listClass);
        if (!item.iconClass)
            item.iconClass = 'open';
        else
            item.iconClass = item.iconClass === 'open' ? '' : 'open';

        if (!item.listClass)
            item.listClass = 'open';
        else
            item.listClass = item.listClass === 'open' ? 'close' : 'open';
        this.setState({item}, () => {
            $(`#${id}`).addClass(item.listClass);
            this.props.setRowsBackgroundColor();
        });
    }



    render()
    {
        const {addLinkedDocumentIcon, handlerDoubleClick, toggleItemChecked, getTreeItem, bulkUpdate, fetchingProperties, listClass} = this.props;
        let {item, isRefreshing} = this.state;
        let configurationString  = getConfigurationString(item);
        let isConfigured      = configurationString && configurationString !== 'default';
        const disableCheckbox = bulkUpdate || item.documentVersion || isRefreshing || item.excludeFromBom ? true : false;
        return (
            <div className={`${listClass} assembly-holder`}>
                <label className={`checkbox-holder ${disableCheckbox ? 'pointer-events-none' : ''}`}>
                    <input
                        type='checkbox'
                        checked={disableCheckbox ? '' : item.isChecked ? item.isChecked : ''}
                        onChange={(e) => {
                            toggleItemChecked(item, !item.isChecked, false, e.nativeEvent.shiftKey);
                        }}
                    />
                    <span
                        className={`checkmark ${disableCheckbox ? 'disabled' : ''}`}
                        onDoubleClick={() => handlerDoubleClick(item)}
                    ></span>
                </label>
                <InlineIcon onClick={(e) => this.handleOnClickEvent(item) }
                            className={`assembly-arrow ${item.iconClass}`}
                            onMouseOver={(e) => addHOverState(e)}>
                            <ArrowSrc/>
                </InlineIcon>

                <InlineIcon className={'component-type-icon'}>
                    {isConfigured ? <AssemblyConfigurationIconLight/> : <AssemblyIconSrc/>}
                </InlineIcon>
                {getTreeItem(item, isRefreshing)}
                <span>
                    {addLinkedDocumentIcon(item)}
                </span>
            </div>
        );
    }
}

export default Assembly;
