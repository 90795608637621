import { WebhookRegistrationFailCause } from "./consts";

class EventEmitter
{
    constructor()
    {
        this.events = {};
    }

    _getEventListByName(eventName)
    {
        if ( typeof this.events[eventName] === 'undefined' )
        {
            this.events[eventName] = new Set();
        }
        return this.events[eventName]
    }

    on(eventName, fn)
    {
        this._getEventListByName(eventName).add(fn);
    }

    once(eventName, fn)
    {
        const self = this;
        const onceFn = function (...args) {
            self.removeListener(eventName, onceFn);
            fn.apply(self, args);
        };
        this.on(eventName, onceFn);
    }

    emit(eventName, ...args)
    {
        this._getEventListByName(eventName).forEach(function (fn) {
            fn.apply(this, args);
        }.bind(this));
    }

    removeListener(eventName, fn)
    {
        this._getEventListByName(eventName).delete(fn);
    }
}

class Utils {
    static EventEmitter = EventEmitter;

    static getCpnTag(categoryObject, cpnVariant = true) {
        let tag = "";

        if (!window.__isIntelligentCpnScheme) {
            tag = `${tag.padStart(window.__nonIntelligent.counterLength, "x")}`;
        } else if (categoryObject) {
            tag = `${categoryObject && categoryObject.code ? categoryObject.code : "xxx"}-xxxxx`;
        } else tag = "xxx-xxxxx";

        if (window.__cpnSchemeType === "EXTRA-TWO-DIGIT-VARIANT" && cpnVariant) {
            tag = `${tag}-xx`;
        }
        return tag;
    }

    static getCpnValue(item) {
        let cpn = item.cpn;
        if (window.__cpnSchemeType === "EXTRA-TWO-DIGIT-VARIANT") {
            if (item && cpn && cpn.split("-").pop() !== item.cpnVariant) {
                cpn = `${cpn}-${item.cpnVariant}`;
            }
        }
        return cpn;
    }

    static getCpnWithoutVariant(cpn) {
        if (window.__cpnSchemeType === "EXTRA-TWO-DIGIT-VARIANT" && cpn) {
            let cpnArray = cpn.split("-");
            cpnArray.pop();
            cpn = cpnArray.join("-");
        }
        return cpn;
    }

    static getCategoryName(category) {
        return window.__isIntelligentCpnScheme ? category.displayNameWithPrefix : category.name;
    }

    static checkRevisionManaged(item) {
        return item && window.__isNotRevisionManagedEnabled ? item.revisionManaged : true;
    }

    static hasSpecialCharExceptHyphen(value) {
        let regex = /[@\_~`!#$%\^&*+=.\[\]\\';,/{}|\\":<>\?]/g;
        return regex.test(value);
    }

    static getPreviousRevision(item) {
        if (!item) return "";
        if (item.previousStatus === "DESIGN" && item.previousRevision === "") return "1";
        else return item.previousRevision ? item.previousRevision : item.revision;
    }

    static getPreviousStatus(item) {
        if (!item) return;
        else if (item.modified && item.previousStatus) return item.previousStatus;
        else return item.status;
    }

    static isApplicabilityFieldEnabled()
    {
        return window.__isApplicabilityFieldEnabled;
    }

    static serializeElementKey({ documentId, elementId }) {
        return `${documentId}.${elementId}`;
    }

    static getWebhookModalBodyText(cause) {
        switch (cause) {
            case WebhookRegistrationFailCause.NOT_ADMIN:
                return "Please contact your administrator to reset the Onshape connection. Releases will not be synced until the connection is re-established.";
            case WebhookRegistrationFailCause.ONSHAPE_API_ERROR:
            case WebhookRegistrationFailCause.STORE_FAILURE:
                return "Please try reloading the application again in a few minutes. Releases will not be synced until the connection is re-established.";
            default:
                return "Please contact support@durolabs.com with this error message.";
        }
    }

    static queryStringFromObject(obj) {
        return Object.entries(obj).reduce((acc, [key, value], i) => {
            acc += `${i === 0 ? "?" : "&"}${key}=${encodeURIComponent(value)}`;
            return acc;
        }, "");
    }
}

export default Utils;
