import React, { Component }        from 'react';
import Tree                        from './tree';
import Part                        from './part';
import Assembly                    from './assembly';
import PartStudio                  from './part-studio';
import {generateKey, getLinkedDocumentHref, checkToolTipAppearance, getComponentName, getWorkSpaceId, getParsedItemFromSessionStorage} from '../../../helpers/components-helper';
import $                           from 'jquery';
import ReactTooltip                from 'react-tooltip';
import InlineIcon                  from '../icon/inline-icon.jsx';
import LinkedDocumentSrc           from '../../../assets/icons/linked-document-icon';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import * as componentActions       from '../../../actions/component';
import Utils                       from '../../../utils';


let displayOrder = 0;

class AssemblyTree extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            rootAssembly: props.list,
            partStudio: props.partStudio
        };
        this.generateNavMarkup = this.generateNavMarkup.bind(this);
        this.childNameHover    = this.childNameHover.bind(this);
        this.getTreeItem       = this.getTreeItem.bind(this);
    }

    componentDidMount()
    {
        this.setRowsBackgroundColor();
    }

    componentDidUpdate()
    {
        this.setRowsBackgroundColor();
        ReactTooltip.rebuild();
    }

    setRowsBackgroundColor()
    {
        $(".side-nar-list .scrollarea ul.assembly-ul-list-inner.open>li:visible>.assembly-ul-list>.assembly-holder").each(function(i)
        {
            let row = $(this);
            row.attr('id', '' + i);
        });

        $(".side-nar-list .scrollarea ul.assembly-ul-list-inner.open>li:visible>.assembly-ul-list>.assembly-holder").each(function(i)
        {
            let row = $(this);
            let className = 'separator-1';
            if(i%2 === 0)
            {
                className = 'separator-2';
            }
            row.addClass(className);
        });
    }

    setNameWithQuantity(name, quantityText)
    {
        return <React.Fragment>
                    {name}
                    {quantityText ? <span className='italic'>{quantityText}</span> : ''}
               </React.Fragment>
    }

    removeHOverState(e)
    {
        var elems = document.querySelectorAll('li');
        [].forEach.call(elems, (el) => {
            el.classList.remove('hover-state');
        });
    }

    childNameHover(e)
    {
        e.stopPropagation();
        this.addHOverState(e);
    }

    addHOverState(e)
    {
        let element = e.target;
        element.closest('li').classList.add('hover-state');
    }

    filterAssemblyTree(assembly)
    {
        let tree = [];
        if(assembly.nestedChildren)
        {
            for(let children of assembly.nestedChildren)
            {
                let alreadyPushed = tree.find(cmp => {
                    if(children.type === 'Part' && children.partId === cmp.partId && children.elementId === cmp.elementId && children.documentId === cmp.documentId && children.fullConfiguration === cmp.fullConfiguration)
                    {
                        return true;
                    }
                    else if(children.type === 'Assembly' && children.elementId === cmp.elementId && children.documentId === cmp.documentId && children.fullConfiguration === cmp.fullConfiguration)
                    {
                        return true;
                    }
                    return false
                });
                if(!alreadyPushed)
                {
                    tree.push(children);
                }
                this.filterAssemblyTree(children);
            }
            assembly.nestedChildren = tree;
        }
        assembly.refreshAssembly = this.props.refreshAssembly;
    }

    generateNavMarkup(item, listClass=null, isChild = false)
    {
        let markup = null;
        item.displayOrder = displayOrder++;
        if (item.type === 'Part')
        {
            markup = (
                        <Part
                            item={item}
                            toggleItemChecked={this.props.toggleItemChecked}
                            bulkUpdate={this.props.bulkUpdate}
                            addLinkedDocumentIcon={this.addLinkedDocumentIcon}
                            getTreeItem={this.getTreeItem}
                            dispatch={this.props.dispatch}
                            workspaceId={getWorkSpaceId(item.workspaceId, this.props.queryParams)}
                            refreshAssembly={this.props.refreshAssembly}
                            fetchingProperties={this.props.fetchingProperties}
                        />
                    );

        }
        else if(item.type === 'Assembly')
        {
            item.nestedChildren = isChild ? (this.props.getChildAssembly(item, false)).nestedChildren : item.nestedChildren;
            this.filterAssemblyTree(item);
            markup = (
                        <div className='assembly-ul-list'>
                            <Assembly
                                item={item}
                                handlerDoubleClick={this.props.handlerDoubleClick}
                                toggleItemChecked={this.props.toggleItemChecked}
                                getChildAssembly={this.props.getChildAssembly}
                                listClass={listClass}
                                bulkUpdate={this.props.bulkUpdate}
                                addLinkedDocumentIcon={this.addLinkedDocumentIcon}
                                getTreeItem={this.getTreeItem}
                                addHOverState={this.addHOverState}
                                setRowsBackgroundColor={this.setRowsBackgroundColor}
                                workspaceId={getWorkSpaceId(item.workspaceId, this.props.queryParams)}
                                dispatch={this.props.dispatch}
                                refreshAssembly={this.props.refreshAssembly}
                                fetchingProperties={this.props.fetchingProperties}
                            />
                            <ul className={`assembly-ul-list-inner ${item.listClass}`} id={`${item.elementId}${item.displayOrder}`}>
                                {
                                    item.nestedChildren && item.nestedChildren.length > 0 && item.nestedChildren.map(c =>
                                        (
                                                <li
                                                    onMouseOver={(e) => e.stopPropagation()}
                                                    onMouseOut={(e) => this.removeHOverState(e)}
                                                    key={generateKey()}
                                                    className={`have-child`}
                                                >
                                                    <div className='separator'></div>
                                                    {this.generateNavMarkup(c, null, true)}
                                                </li>
                                        )
                                    )
                                }
                            </ul>
                        </div>
                    );
        }
        return markup;
    }

    addLinkedDocumentIcon(item)
    {
        let href          = getLinkedDocumentHref(item);
        return (
            item.documentVersion && !item.isStandardContent ?
            <InlineIcon className={'linked-document-icon'}
                tooltip='Linked document. Part Number must be assigned or edited in source document. Click to open.'
                tooltipPlace='top'>
                <a href = {href ? href : ""} target = "blank"><LinkedDocumentSrc/></a>
            </InlineIcon> : ""
        );
    }

    getTreeItem(item, isRefreshing)
    {
        let cpnVal               = item.properties && item.properties.cpn.value ? item.properties.cpn.value : Utils.getCpnTag();
        let name        = getComponentName(item, {setQuantity: true});
        let nameWithQty = this.setNameWithQuantity(name, item.quantityText);
        return (
            <span
                className={`${item.selectedClass} name-area ${item.excludeFromBom ? 'excluded-from-bom' : ''}`}
                onMouseOut={(e) => this.removeHOverState(e)}
                onClick={(e) => this.props.redirectToItem(item)}>

                <div
                    onMouseOver={(e) => this.childNameHover(e)}
                    onMouseOut={(e) => this.removeHOverState(e)}
                    className='component-name-div'
                >
                    <span className='component-cpn'> {isRefreshing ?
                        <SkeletonTheme color="#2a2a36" highlightColor="#3CD1BF">
                            <Skeleton delay={0.9} width={50} height={7}/>
                        </SkeletonTheme>
                        : cpnVal}
                    </span>
                    <span
                        className='component-name'
                        data-tip = ''
                        onMouseOver={(e) => checkToolTipAppearance(e, `${name} ${item.quantityText ?  item.quantityText : ''}`)}
                    >
                        {nameWithQty}
                    </span>
                </div>
            </span>
        );
    }

    render()
    {
        let {list, partStudio, showPullRequestModal, setQuantityProperty, refreshAssembly, fetchingProperties} = this.props;
        let lastOpenedAssembly = getParsedItemFromSessionStorage('lastOpenedAssembly');
        let type = 'assembly';
        if(lastOpenedAssembly)
        {
            type = lastOpenedAssembly.type;
        }
        let markup = list ?
                        <React.Fragment>
                            <Tree
                                setQuantityProperty={setQuantityProperty}
                                rootAssembly={list}
                                generateNavMarkup={this.generateNavMarkup}
                                showPullRequestModal={showPullRequestModal}
                                setRowsBackgroundColor={this.setRowsBackgroundColor}
                                refreshAssembly={refreshAssembly}
                                fetchingProperties={fetchingProperties}
                            />
                        </React.Fragment>
                    :
                    partStudio ?
                        <React.Fragment>
                            <PartStudio partStudios={partStudio} generateNavMarkup={this.generateNavMarkup}/>
                        </React.Fragment>
                    :
                    null;
        return markup;
    }
}

export default AssemblyTree;
