import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="BULK-SELECTION-0" transform="translate(-985.000000, -122.000000)" fill="#888888">
            <g id="BULK-ACTIONS" transform="translate(752.000000, 116.000000)">
                <path d="M238.439657,16.5624869 C237.988191,16.5624869 237.657258,16.2886401 237.973213,15.0834597 L238.491233,12.9462392 C238.581244,12.6037188 238.596363,12.4669366 238.491233,12.4669366 C238.355723,12.4669366 237.769594,12.703338 237.423541,12.936348 L237.197737,12.5665558 C238.296093,11.6492114 239.559208,11.1112677 240.100685,11.1112677 C240.552292,11.1112677 240.627324,11.6452549 240.401803,12.4669366 L239.808468,14.7136676 C239.703338,15.1108726 239.748555,15.2476547 239.853403,15.2476547 C239.989196,15.2476547 240.433031,15.0828945 240.869095,14.740374 L241.124855,15.0828945 C240.056598,16.1510101 238.890841,16.5624869 238.439657,16.5624869 M240.133608,8.16663715 C240.794346,8.16663715 240.989345,8.55013575 240.989345,8.98831893 C240.989345,9.53615388 240.550879,10.0425868 239.802957,10.0425868 C239.176981,10.0425868 238.879254,9.72762057 238.896634,9.20748117 C238.896634,8.76915669 239.263883,8.16663715 240.133608,8.16663715 M239.412959,6.00059072 C235.823557,6.04863403 232.952827,8.99778629 233.000587,12.5874688 C233.048631,16.175597 235.997502,19.0471735 239.586904,18.9994128 C243.17574,18.9510869 246.047036,16.0019346 245.999416,12.4125347 C245.951373,8.82412388 243.002219,5.95268872 239.412959,6.00059072" id="Fill-1"></path>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
