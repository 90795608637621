import                                   "./index.scss"
import React, { Component }         from 'react';
import * as duroActions             from '../../../actions/duro';
import { connect }                  from 'react-redux';
import ItemsList                    from "../items-list/index.jsx";

class ShowAssemblies extends Component {
    constructor(props, context)
    {
        super(props, context);
    }


    render() {
         return ( <div className={"sidebar-view " } id="sidebar-view">


            <div className={'nav-list'}>
                <ItemsList duroReducer={this.props} dispatch={this.props.dispatch} user={this.props.user}/>
            </div>
        </div>

        );
    }
}

const mapStateToProps = ({duroReducer}) =>
({
    parts: duroReducer.parts,
});

export default connect(mapStateToProps)(ShowAssemblies);
