import serviceCalls from './serviceCalls';

export const getAssemblyDefinition = (payload, cb) =>
{
    const {queryParams, configurationString, elementId, shouldResyncBom=false} = payload;
    let path = `/onshape/getAssemblyDefinition?documentID=${queryParams.documentId}&workspaceID=${queryParams.workspaceId}&elementID=${elementId}&companyId=${queryParams.companyId}&shouldResyncBom=${shouldResyncBom}`;
    if(configurationString)
    {
        path = `${path}&configuration=${configurationString}`;
    }
    serviceCalls.get(path).then((response) =>
    {
        cb(null, response.data);
    }).catch((error) =>
    {
        cb(null, error.data);
    });
};

export const resetWebhooks = () => {
    return new Promise((resolve, reject) => {
        serviceCalls.post("/onshape/resetWebhooks", {}, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
};
