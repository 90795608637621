import React, { Component }     from 'react';
import                               './index.scss';
import ArrowSrc                 from '../../../assets/icons/arrow-down';
import AssemblyIconDarkSrc      from '../../../assets/icons/assembly-icon-dark';
import AssemblyIconLightSrc     from '../../../assets/icons/assembly-icon-light';
import PartStudioIconSrc        from '../../../assets/icons/part-studio-icon-dark';
import InlineIcon               from '../icon/inline-icon.jsx';
import { checkToolTipAppearance, filterAssembliesData, getParsedItemFromSessionStorage, setItemInSessionStorage } from '../../../helpers/components-helper';
import Config from '../../../../../config';
import * as assemblyActions  from '../../../actions/assemblies';
import ReactTooltip   from 'react-tooltip';
import * as DURO_ACTIONS from '../../../actions/duro';
import AssemblyConfigurationIconDark  from '../../../assets/icons/assembly-configurations-icon-dark';
import AssemblyConfigurationIconLight from '../../../assets/icons/assembly-configurations-icon-light';

class AssembliesDropDown extends Component
{
     constructor(props, context)
     {
        super(props, context);
        this.onClickHandler               = this.onClickHandler.bind(this);
        this.onDropDownExternalClick      = this.onDropDownExternalClick.bind(this);
        this.closeDropDown                = this.closeDropDown.bind(this);
        this.sortItemList                 = this.sortItemList.bind(this);
        this.refreshAssembly              = this.refreshAssembly.bind(this);
        this.handleRefreshAssemblyAction  = this.handleRefreshAssemblyAction.bind(this);
        this.state =
        {
            items                       : this.sortItemList() || [],
            dropdownClass               : "close",
            selectedTextForDropdown     : this.props.selectedTextForAssemblyList,
            isRefreshingAssembly        : false,
            pendingUpdates              : false
        };
    }

    componentDidMount()
    {
        if(this.props.refreshDocument)
        {
            this.refreshAssembly();
        }
    }

    sortItemList(listItems)
    {
        listItems = listItems ? listItems : this.props.listItems;
        if(listItems.length > 0)
        {
            listItems.sort((a, b) =>
            {
                if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            });
        }
        else
        {
            return null;
        }
        return listItems;
    }

    closeDropDown()
    {
        document.body.removeEventListener("click", this.onDropDownExternalClick);
        this.setState({ dropdownClass: "close" }, () => {
            ReactTooltip.rebuild();
        });
    }

    onDropDownExternalClick(event)
    {
        this.closeDropDown()
    }

    onClickHandler(event)
    {
        let state = this.state;
        let dropdownClass = state.dropdownClass == "close" ? "open" : "close";
        if (dropdownClass === "open")
        {
            document.body.addEventListener("click", this.onDropDownExternalClick);
        }
        this.setState({ dropdownClass }, () => {
            ReactTooltip.rebuild();
        });
    }

    refreshAssembly()
    {
        let {dispatch} = this.props;
        this.setState({ isRefreshingAssembly: true }, () =>
        {
            dispatch(assemblyActions.getDocumentAssemblies({cb: this.handleRefreshAssemblyAction}));
        });
    }

    checkIfAssemblyExist(items)
    {
        let lastOpenedAssembly = getParsedItemFromSessionStorage('lastOpenedAssembly');
        if(items.length > 0)
        {
            for(let item of items)
            {
                if(item.id === lastOpenedAssembly.elementId)
                {
                    if(lastOpenedAssembly.configurationString && item.configurations)
                    {
                        return item.configurations.find((config) => config.configurationString === lastOpenedAssembly.configurationString);
                    }
                    else if(lastOpenedAssembly.configurationString && !item.configurations)
                    {
                        return false;
                    }
                    else
                    {
                        return true;
                    }
                }
            }
        }
        return false;
    }


    handleRefreshAssemblyAction(assemblies)
    {
        let {queryParams, dispatch, mainAssemblyId, setMyState} = this.props;
        let items   = this.sortItemList(assemblies);
        let lastOpenedAssembly = getParsedItemFromSessionStorage('lastOpenedAssembly');
        let assemblyExist      = true;
        if(lastOpenedAssembly)
        {
            assemblyExist = this.checkIfAssemblyExist(items);
            if(!assemblyExist)
            {
                //we are using filter Assemblies data to filter Part Studio and Assmeblies from the response
                items                        = filterAssembliesData(items);
                let topElement               = items && items[0];
                if(!topElement)
                {
                  this.props.afterRefreshDocument(items, assemblyExist);
                  return;
                }

                //setting the first item of the response as the last last Opened Assembly
                lastOpenedAssembly.elementId = topElement.id;
                lastOpenedAssembly.type      = topElement.type;
                lastOpenedAssembly.configuration = '';
                lastOpenedAssembly.configurationString = '';
                setItemInSessionStorage('lastOpenedAssembly', lastOpenedAssembly);
                const successCb = (error, response) =>
                {
                    if(topElement.type.toLowerCase() === 'assembly')
                    {
                      let payload = { elementId: topElement.id, isRedirecting: true, documentId: queryParams.documentId, workspaceId: queryParams.workspaceId, type: topElement.type};
                      dispatch(assemblyActions.getAssemblyMetaData(payload));
                    }
                    setMyState(error, response);
                };

                if(topElement.type.replace(/ /g, '').toLowerCase() === 'partstudio')
                {
                  dispatch(assemblyActions.getPartStudio({ partStudioId: lastOpenedAssembly.elementId, workspaceId: queryParams.workspaceId, documentId: queryParams.documentId, successCb}));
                }
                else
                {
                  dispatch(DURO_ACTIONS.getAssemblyDefination({ elementId: lastOpenedAssembly.elementId, successCb, setInnerLoading: true}));
                }
            }
        }
        else
        {
            this.props.unsetSelectedTextForAssemblyList(items);
        }
        this.props.afterRefreshDocument(items, assemblyExist);
    }

    render()
    {
        let { items, dropdownClass, selectedTextForDropdown, isRefreshingAssembly } = this.state;
        let type = "ASSEMBLY";
        let isConfigured = false;
        items.forEach((item) => {
            item.configurationString = item.configurationString || '';
            if(item.name === selectedTextForDropdown.value && item.configurationString === selectedTextForDropdown.configurationString)
            {
                type = item.elementType || item.type;
                isConfigured = Boolean(item.configurationString);
            }
        });
        let iconSrc = type === "ASSEMBLY" ? dropdownClass === "close" ? <AssemblyIconLightSrc/> : <AssemblyIconDarkSrc />  : <PartStudioIconSrc />;
        if(isConfigured && type === "ASSEMBLY")
        {
            iconSrc = dropdownClass === "close" ? <AssemblyConfigurationIconLight/> : <AssemblyConfigurationIconDark/>;
        }
        let markup = <div className="dropdown">
                        <div className='assembly-dropdown-holder'>
                            <div className={`dropbtn ${dropdownClass} assembly-icon-left-side`} onClick={this.onClickHandler}>
                                <span>
                                <InlineIcon className='assembly-icon'>
                                    {iconSrc}
                                </InlineIcon>
                                    <div className='list-truncate'>
                                        {selectedTextForDropdown.value}
                                    </div>
                                </span>
                                <InlineIcon onClick={this.onClickHandler}><ArrowSrc/></InlineIcon>
                            </div>
                        </div>
                        <div className={`${dropdownClass} dropdown-content`}  id="myDropdown">
                            <ul className='assemblies-dropdown'>
                            {
                                items.map((item, i) =>
                                {
                                    let clazz = item.name === selectedTextForDropdown.value && item.configurationString === selectedTextForDropdown.configurationString ? 'selected' : '';
                                    let isConfigured = Boolean(item.configurationString);
                                    let block =
                                        <li key={i} className={clazz}>
                                            <a onClick={(e) => this.props.selectAssembly(item)} data-tip='' onMouseOver={(e) => {checkToolTipAppearance(e, item.name); ReactTooltip.rebuild()}}>
                                                <InlineIcon className='assembly-icon'>
                                                {
                                                    item.type && item.type.toLowerCase() === "assembly" ? isConfigured ? <AssemblyConfigurationIconDark/> :
                                                    <AssemblyIconDarkSrc/> :
                                                    <PartStudioIconSrc />
                                                }
                                                </InlineIcon>
                                                <div className='list-truncate'>
                                                    {item.name }
                                                </div>
                                            </a>
                                        </li>
                                    return block;
                                })
                            }
                            </ul>
                        </div>
                      </div>
        return markup;
    }
}

export default AssembliesDropDown;
