import React, { Component }      from "react"

const SvgIcon = () =>
{
  return <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <path fill="#fff" fillRule="evenodd" clipRule="evenodd" d="M5.5.5h-1v1h1v-1zm-1 8v1h1v-1h-1zm0-6.5h1v1h-1V2zM5 6.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM4.5 8V7h1v1h-1z" id="Shape"></path>
        </svg>
}

export default SvgIcon
