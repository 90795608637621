import                 "./index.scss";
import React           from "react";
import LazyInput       from "../../ui/lazy-input/input.jsx";
import InlineIcon      from "../../ui/icon/inline-icon.jsx";
import BackIcon        from "../../../assets/icons/back-icon.jsx";

const SsoSigninForm =  (props) =>
{
    let { label, displayText, onInputChange, navigatePage, onContinueClick, continueBtnClass, input, onBack, flag } = props;
    let containerClass  = `input-container ${!flag ? "active" : ""}`;
    let inputClass = `${input.class} ${!flag ? "for-email" : ""}`;
    return(
        <div className="sign-in-with-sso-form">
            <h2>Sign in with SSO</h2>
            <form className="form-styling">
                <div className="error-message">
                    <label>{label}</label>
                    <span>{input.message}</span>
                </div>
                <div className={containerClass}>
                    <LazyInput
                        id="inputfield"
                        type="text"
                        name="sso-domain"
                        value={input.value}
                        className={inputClass}
                        onChange={onInputChange}
                    />
                </div>
                <a onClick={onContinueClick} className={continueBtnClass}>
                    <button className={continueBtnClass} type="button">
                        Continue
                    </button>
                </a>
                <div className="link-holder" >
                    <span className="link" onClick={navigatePage}>{displayText}</span>
                </div>
                    <a onClick={onBack} className="back-button" type="button">
                        <InlineIcon className="icon">
                           <BackIcon/>
                        </InlineIcon>
                        Back
                    </a>
            </form>
        </div>
    )

}

export default SsoSigninForm;
