import                           "./index.scss"
import React, { Component } from 'react';
import InlineIcon           from "../icon/inline-icon.jsx";
import LoadingIcon          from "../../../assets/icons/loading.jsx";

class Spinner extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        let markup = null;
        let iconClassName = this.props.iconClassName ? this.props.iconClassName : '';
        markup =
                <div className="spinner-wrapper">
                    <div className="spinner">
                    <InlineIcon
                      className={iconClassName}
                    >
                       <LoadingIcon />
                     </InlineIcon>
                    </div>
                </div>
        return markup;
    }
}

export default Spinner;
