import React, { Component }         from 'react';
import                                   './index.scss'
import InlineIcon                   from '../../../ui/icon/inline-icon.jsx';
import ModalBox                     from '../../../ui/warning-modal-box';
import CoAlertIcon                  from '../../../../assets/icons/co-alert-icon.jsx';
import ReactTooltip                 from 'react-tooltip';
import validations                  from '../../../../modules/validations';
import BulkUpdateInfoIcon           from '../../../../assets/icons/bulk-update-info-icon.jsx';
import { getToolTipText, capitalizeString } from '../../../../helpers/components-helper';

export default class StatusUpdateModalForNonAssembly extends Component
{
    render()
    {
        let toolTip = getToolTipText(this.props.newStatusValue, validations);
        let markup  = <div className={'confirm-status-changed-modal'}>
            <ModalBox className='ui-modal-box' onClose={this.props.onRejectStatusChange} showCrossIcon={true}>
            <h1>
                <InlineIcon><CoAlertIcon /></InlineIcon>
                {
                    this.props.statusFieldPreviousValue === 'DESIGN' ?
                    'Switch to Revision Control' :
                    `Moving into ${this.props.newStatusValue.toLowerCase()}`
                }
            </h1>
            <div className='warning-text'>
                {
                    this.props.statusFieldPreviousValue === 'DESIGN' ?
                    <p>
                        This component will now be under revision control. All your changes will be tracked going forward. Use Duro's Change Order feature to review and approve new revisions in the web client.
                    </p>
                    :
                    <section>
                        <span>
                            This component will now be marked as In {capitalizeString(this.props.newStatusValue)}.
                        </span>
                        <p>
                            This can not be undone.
                        </p>
                    </section>
                }
                <div className='inputs-section'>
                    <div>
                        <label className='muted-text'>
                            REVISION
                        </label>
                        <span className='revision-value'>
                            {this.props.revisionInput.value}
                        </span>
                        {/*<input
                            type='text'
                            name='revision'
                            autoFocus={true}
                            value={this.props.revisionInput.value}
                            className={this.props.revisionInput.class}
                            data-tip={this.props.revisionInput.message}
                            onChange={this.props.revisionInputChange}
                            data-for='part-edit-page'
                            data-place='right'
                            data-type='error'
                        />

                        <InlineIcon
                            tooltip={toolTip}
                            tooltipPlace='right'
                            className='info-icon'
                        >
                            <BulkUpdateInfoIcon/>
                        </InlineIcon>*/}

                    </div>

                    <div>
                        <label className='muted-text'>
                            NEW STATUS
                        </label>

                        <span>
                            {this.props.newStatusValue}
                        </span>
                    </div>
                </div>
            </div>
            <div className='bottom-section'>
                <div className='button-sections'>
                  <button
                      type="button"
                      onClick={this.props.onRejectStatusChange}
                      >
                    Cancel
                  </button>

                  <button
                      type="button"
                      onClick={this.props.onAcceptStatusChange}
                      disabled={this.props.revisionInput.valid ? false : true}
                      className={this.props.revisionInput.valid ? 'filled' : 'disabled'}>
                    continue
                  </button>

                </div>
            </div>
            </ModalBox>
        </div>
        return markup;
    }
}
