import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>CONFIRMED</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="4-MANAGE-CONNECTIONS-FULL" transform="translate(-1063.000000, -235.000000)">
                <g id="MENU" transform="translate(1041.000000, 123.000000)">
                    <g id="ONSHAPE" transform="translate(23.000000, 111.000000)">
                        <g id="CONFIRMED" transform="translate(0.000000, 2.000000)">
                            <g id="Green">
                                <circle id="Oval" stroke="#3CD1B5" fill="#3CD1B5" cx="7.5" cy="7.5" r="7.5"></circle>
                                <polyline id="Stroke-1" stroke="#18171D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="4.28571429 8.86363636 5.89285714 10.3977273 11.7857143 4.77272727"></polyline>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default SvgIcon
