import React, { Component } from 'react';
import {generateKey} from '../../../../helpers/components-helper';

class PartStudio extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            partStudios: this.props.partStudios,
            partStudiosMarkup: this.getPartStudiosMarkup()
        };
    }

    componentDidUpdate(prevProps, prevState)
    {
        let {partStudiosMarkup} = this.state;
        if(prevProps.partStudios !== this.props.partStudios)
        {
            partStudiosMarkup = this.getPartStudiosMarkup();
            this.setState({partStudiosMarkup});
        }
    }

    getPartStudiosMarkup()
    {
        let {partStudios} = this.props;
        let options  = [];
        let rowClass = "config-part-2";
        let partId   = null;
        let partStudiosMarkup = () =>
                                {
                                    partStudios.forEach((partStudio) =>
                                    {
                                        if(partStudio.hasOwnProperty('excludeFromBom') && !partStudio.excludeFromBom)
                                        {
                                            if(partId !== partStudio.partId)
                                            {
                                                rowClass = rowClass === 'config-part-1' ? 'config-part-2' : 'config-part-1';
                                                partId = partStudio.partId;
                                            }
                                            options.push(<li key={generateKey()} className={rowClass}>
                                                {this.props.generateNavMarkup(partStudio, 'separator-1')}
                                            </li>);
                                        }
                                    });
                                    return options;
                                };

        return (<React.Fragment>
                    <ul className='assembly-ul-list-inner'>
                        {partStudiosMarkup()}
                    </ul>
                </React.Fragment>);
    }

    render()
    {
        let {partStudiosMarkup} = this.state;
        return partStudiosMarkup;
    }
}

export default PartStudio;
