import './index.scss';
import React, { Component }  from 'react';
import ReactTooltip          from 'react-tooltip';
import ScrollArea            from "react-scrollbar";
import TableRow from './table-row';
import InlineIcon from "../../ui/icon/inline-icon.jsx";
import {generateKey} from '../../../helpers/components-helper';

class Table extends Component
{
    constructor(props, context) {
        super(props, context);
        this.state = {
            closeClass: '',
            checkedComponents: [],
            components : this.props.components,
            isMasterCheckboxChecked: true
        };
        this.checkAllRows = this.checkAllRows.bind(this);
    }

    toggleIndividualCheck(position, event, component, isChecked)
    {
        let { components } = this.state;
        let checked = event.target.checked;
        components.forEach((cmp) => {
            if (cmp.properties.cpn.value === component.properties.cpn.value)
            {
                cmp.isCheckedOnStatusTable = checked;
            }
        });
    }

    handleCheckboxShiftKey(i, lastChecked, components, checkedComponents, setChecked)
    {
        let current = i;
        let start = -1;
        let end  = -1;
        if(lastChecked < current && lastChecked !== -1)
        {
            start = lastChecked
            end = current
        }
        if(lastChecked > current && lastChecked !== -1)
        {
            start = current
            end = lastChecked
        }
        if(start >= 0 && end >= 0)
        {
            components = this.sortedComponents(components);
            for(let j = start; j <= end; j++)
            {
                let pushComponent = !components[j].isCheckedOnStatusTable && setChecked;
                components[j].isCheckedOnStatusTable = setChecked;
                if(pushComponent) checkedComponents.push(components[j]);
            }
            components[i].isCheckedOnStatusTable = setChecked;
            if(!setChecked)
            {
                checkedComponents = [];
                for(let index = 0; index <= components.length - 1; index++)
                {
                    if(components[index].isCheckedOnStatusTable) checkedComponents.push(components[index]);
                }
            }
        }
        return { components, checkedComponents};
    }

    checkAllRows()
    {
        let {isMasterCheckboxChecked, components} = this.state;
        for(let cmp of components)
        {
            cmp.isCheckedOnStatusTable = !isMasterCheckboxChecked;
        }
        this.setState({components, isMasterCheckboxChecked: !isMasterCheckboxChecked});
    }

    sortedComponents = (components) =>
    {
        let resultantArray = [...components].sort((cmpA,cmpB) =>
            {
                if(cmpA.displayOrder > cmpB.displayOrder)
                {
                    return 1;
                }
                else
                {
                    return -1;
                }
            });
        return resultantArray;
    };

    render()
    {
        let {components} = this.props;
        const tBody = this.sortedComponents(components).map((component, i) =>
                    (
                        <TableRow
                            key={generateKey()}
                            position={i}
                            onInputChange={this.onInputChange}
                            component={component}
                            toggleIndividualCheck={this.props.onRowSelect}
                            onStatusChange={this.props.onStatusChange}
                            remove={this.props.remove}
                            revisionScheme={this.props.revisionScheme}
                        />
                    ));

        let markup = <div className={this.props.wrapperClass}>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <label className={`checkbox-holder`}>
                                        <input onChange={this.checkAllRows} checked={this.state.isMasterCheckboxChecked} type='checkbox' />
                                        <span className='checkmark'></span>
                                        </label>
                                    </th>
                                    <th>CPN</th>
                                    <th>NAME</th>
                                    <th>REVISION</th>
                                    <th>STATUS</th>
                                    <th>DESCRIPTION</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {tBody}
                            </tbody>
                        </table>
                    </div>
        return markup;
    }
}

export default Table;
