import { put, call, takeLatest, cps, all, cancelled, select } from 'redux-saga/effects';
import { authRefreshtokenOnshapeService }                     from '../services/authenticationService';
import * as duroActions                                       from '../actions/duro'
import * as userActions                                       from '../actions/user';
import * as baseUrlActions                                    from '../actions/baseUrl';
import { signInService, getCompanyConfiguration }             from '../services/userService';
import { removeLocalStorageItem, setLocalStorageItem }        from '../helpers/components-helper';

export function* getCompanyBaseURL({ payload })
{
    try
    {
        yield put(baseUrlActions.signInLoading(true));
        let response = yield cps(getCompanyConfiguration, payload);
        setLocalStorageItem("baseUrl", response.baseUrl);
        setLocalStorageItem("clientBaseUrl", response.clientBaseUrl);
        yield put(baseUrlActions.signInLoading(false));
    }
    catch(error)
    {
        removeLocalStorageItem("baseUrl");
        removeLocalStorageItem("clientBaseUrl");
        yield put(baseUrlActions.signInLoading(false));
        console.log('----------------in saga error----------------------', error);
    }
}

export default function* watchAuthProcess()
{
  yield takeLatest(baseUrlActions.getBaseUrl().type, getCompanyBaseURL);
}
