import * as React from "react";

function AssemblyConfigurationIconDark(props) {
  return (
    <svg width="66px" height="61px" viewBox="0 0 66 61" >
      <defs>
        <path
          d="M0 0h1v25H0V0zm7 0h1v25H7V0zm7 0h1v25h-1V0zm7 0h1v25h-1V0zm7 0h1v25h-1V0zm7 0h1v25h-1V0z"
          id="a"
        />
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path d="M20 42.45v15.03M44 18h19" stroke="#888" fillRule="nonzero" />
        <path
          stroke="#888"
          fillRule="nonzero"
          d="M51.6019891 7L63 18.0319424 63 58 20.2867147 58 8 46.1078961 8 7z"
        />
        <g
          transform="translate(7 6)"
          fillOpacity={0.25}
          fillRule="nonzero"
          stroke="#888"
        >
          <path
            d="M1.644 1.433L12.934 12h25.831a.1.1 0 00.075-.167L29.18 1H1.816a.25.25 0 00-.171.433z"
            fill="#F4F4F4"
          />
          <path
            fill="#888"
            d="M13 12L38.9733938 12 38.9733938 36.5027068 13 36.5027068z"
          />
          <path
            d="M1.052 1.073v25.2l11.875 10.35.133-24.342L1.052 1.073z"
            fill="#F4F4F4"
          />
        </g>
        <g fillRule="nonzero" transform="translate(1 1)">
          <path fill="#E1E1E1" d="M1 0H36V25H1z" />
          <path
            d="M36 5.99V7.03H1V5.99h35zM36 0v1.042H1V0h35zm0 11.98v1.04H1v-1.04h35zm0 5.989v1.041H1V17.97h35zm0 5.99V25H1v-1.042h35z"
            stroke="#51D1B4"
            strokeWidth={0.5}
            fill="#3CD1B5"
          />
          <g strokeWidth={0.5}>
            <use stroke="#979797" fill="#3CD1B5" xlinkHref="#a" />
            <use stroke="#979797" xlinkHref="#a" />
            <use stroke="#979797" xlinkHref="#a" />
            <use stroke="#979797" xlinkHref="#a" />
            <use stroke="#979797" xlinkHref="#a" />
            <use stroke="#51D1B4" xlinkHref="#a" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AssemblyConfigurationIconDark;
