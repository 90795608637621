import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import { RingLoader } from 'react-spinners';
import { spinnerStyles, sizeUnit, size, color } from '../../helpers/spinnerStyles';

const BackdropLoader = (props) => 
{
	const {isLoading} = props;
	return ( 
		<LoadingOverlay
	        active={isLoading}
	        spinner={<RingLoader
	                    css={spinnerStyles}
	                    sizeUnit={sizeUnit}
	                    size={size}
	                    color={color}
	                    loading={true}
	                    />}
	        styles={{
	            wrapper: {
	            width: '100%',
	            height: '100%',
	            overflow: ''
	            }
	            }}
        >
        {props.children}
        </LoadingOverlay>
        );
}

export default BackdropLoader;