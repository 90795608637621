import { createStore, applyMiddleware }             from 'redux';
import createSagaMiddleware                         from 'redux-saga';
import rootReducer                                  from '../reducers';
import authSaga                                     from '../sagas/oauthSaga';
import documentSaga                                 from '../sagas/documentSaga';
import assembliesSaga                               from '../sagas/assembliesSaga';
import duroSaga                                     from '../sagas/duroSaga';
import signInSaga                                   from '../sagas/signIn';
import baseUrlSaga                                  from '../sagas/baseUrl';
import componentSaga                                from '../sagas/componentSaga';
import {createLogger}                               from 'redux-logger';
import Config                                       from '../../../config';

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middleware =
    [
        sagaMiddleware
    ]
    if(Config.ENV === 'local')
    {
        const loggerMiddleware = createLogger();
        middleware.push(loggerMiddleware);
    }
    let data = {
        ...createStore(rootReducer, applyMiddleware(...middleware)),
        authSaga: sagaMiddleware.run(authSaga),
        assebmlySage: sagaMiddleware.run(assembliesSaga),
        documentSaga: sagaMiddleware.run(documentSaga),
        duroSaga: sagaMiddleware.run(duroSaga),
        componentSaga : sagaMiddleware.run(componentSaga),
        signInSaga    : sagaMiddleware.run(signInSaga),
        baseUrlSaga   : sagaMiddleware.run(baseUrlSaga)
    };
    window._store = data.getState();
    return data;
};

export default configureStore;
