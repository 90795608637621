import                                               './index.scss';
import React, { Component }                     from 'react';
import {connect}                                from 'react-redux';
import * as assemblyActions                     from '../../../actions/assemblies';
import InlineIcon                               from '../icon/inline-icon.jsx';
import * as componentActions                    from '../../../actions/component';
import {getPropertyObj, getWorkSpaceId, getComponentOnshapeId, extractCpn, getConfigurationString, getParsedItemFromSessionStorage} from '../../../helpers/components-helper';
import PullRequestModal                         from '../pull-request-modal/index.jsx';
import LeftSideBarHeader                        from '../left-side-bar-header/index.jsx';
import { RingLoader }                           from 'react-spinners';
import { spinnerStyles, sizeUnit, size, color } from '../../../helpers/spinnerStyles';
import ScrollArea                               from "react-scrollbar";
import ReactTooltip                             from 'react-tooltip';
import AssemblyTree                             from '../assembly-tree';
import UpdateStatusModal from '../../update-status-modal';
import Utils from '../../../utils';
import ShowError from "../show-error";

let displayOrder = 0;

class ItemAssembly extends Component
{
    constructor(props, context) {
        super(props, context);
        this.getChildAssembly                = this.getChildAssembly.bind(this)
        this.redirectToItem                  = this.redirectToItem.bind(this)
        this.markSelectedComponent           = this.markSelectedComponent.bind(this)
        this.togglePullRequestModal          = this.togglePullRequestModal.bind(this)
        this.fillRemoveCheckedComponent      = this.fillRemoveCheckedComponent.bind(this)
        this.handlerDoubleClick              = this.handlerDoubleClick.bind(this)
        this.toggleItemChecked               = this.toggleItemChecked.bind(this)
        this.afterPullRequestSubmit          = this.afterPullRequestSubmit.bind(this)
        this.updateCpns                      = this.updateCpns.bind(this)
        this.refreshAssembly                 = this.refreshAssembly.bind(this)
        this.setQuantityProperty             = this.setQuantityProperty.bind(this);
        this.toggleUpdateStatusModal         = this.toggleUpdateStatusModal.bind(this);
        this.refreshRightSideBar             = this.refreshRightSideBar.bind(this);
        this.fetchAssemblyTree               = this.fetchAssemblyTree.bind(this);
        this.onCloseModal                    = this.onCloseModal.bind(this);
        this.state =
        {
            parts               : this.props.parts,
            nestedChildren      : [],
            subAssemblies       : this.props.subAssemblies,
            list                : this.props.mainAssembly,
            selectedComponent   : {},
            showPullRequestModal: false,
            checkedComponents   : [],
            fetchingProperties  : this.props.fetchingProperties,
            baseComponentList   : [],
            checkedComponentsBackup   : [],
            refreshModal        : this.props.refreshModal,
            partStudio          : this.props.partStudio,
            bulkStatusUpdateBtn : { class: 'disabled' },
            showUpdateStatusModal : false,
            showErrorModal : false,
            errorData: [],
            componentsForUpdateStatusModal: [],
            lastClickedItem: null
        }
    }

    calculateOccurrences(cmp, assembly)
    {
        let {elementId, documentId, instances} = assembly;
        if(elementId === cmp.parent.elementId && documentId === cmp.parent.documentId && instances)
        {
            for(let instance of instances)
            {
                let hasSameElementId = instance.documentId === cmp.documentId && instance.elementId === cmp.elementId && !instance.suppressed && instance.fullConfiguration === cmp.fullConfiguration;
                if((instance.type === 'Part' && hasSameElementId && instance.partId === cmp.partId) ||
                    (instance.type === 'Assembly' && hasSameElementId ))
                {
                    cmp.quantity++;
                }
            }
        }
    }

    setQuantityProperty(assembly)
    {
        for(let children of assembly.nestedChildren)
        {
            children.quantity = 0;
            if(children.parent)
            {
                this.calculateOccurrences(children, this.props.mainAssembly);
                if(children.quantity === 0)
                {
                    for(let assembly of this.props.subAssemblies)
                    {
                        this.calculateOccurrences(children, assembly);
                    }
                }
            }

            if(children.type === 'Assembly')
            {
                if(!children.nestedChildren)
                {
                    children = this.getChildAssembly(children, false);
                }
                this.setQuantityProperty(children);
            }
        }
    }

    componentDidMount()
    {
        this.fetchAssemblyTree();
    }

    fetchAssemblyTree()
    {
        this.props.dispatch(assemblyActions.assemblyLoading(false));
        this.updateComponentProperties(this.state.list || this.state.partStudio);
        ReactTooltip.rebuild();
        if(this.props.refreshAssembly)
        {
            let lastOpenedAssembly      = getParsedItemFromSessionStorage('lastOpenedAssembly');
            let type                    = lastOpenedAssembly && lastOpenedAssembly.type ? lastOpenedAssembly.type.toLowerCase() : 'assembly';
            let configuration           = lastOpenedAssembly && lastOpenedAssembly.configurationString;
            if(type === 'assembly')
            {
                this.props.getAssemblyDefination(lastOpenedAssembly.elementId, true, null, configuration);
            }
            else if(type && type.replace(/ /g, '').toLowerCase() === 'partstudio')
            {
                this.props.getPartsInPartStudio(lastOpenedAssembly, true);
            }
        }
    }

    componentDidUpdate()
    {
        ReactTooltip.rebuild();
        let {refreshAssembly, mainAssembly, partStudio} = this.props;
        let {list, checkedComponents} = this.state;

        if(refreshAssembly)
        {
            this.fetchAssemblyTree();
        }
        let isRedirecting = localStorage.getItem('refreshAssembly');

        if(isRedirecting === 'true')
        {
            checkedComponents = [];
        }

        if(mainAssembly && mainAssembly !== list)
        {
            this.setState({list: this.props.mainAssembly, partStudio: null, checkedComponents});
        }
        else if(partStudio && partStudio !== this.state.partStudio)
        {
            this.setState({partStudio: partStudio, list: null, checkedComponents});
        }
    }

    updateComponentProperties(item)
    {
        if(Array.isArray(item))
        {
            for(let i = 0; i < item.length; i++)
            {
                this.updateComponentProperties(item[i]);
            }
        }
        else
        {
            let cpn       = {value: ''};
            let category  = {value: ''};
            let duroId    = {value: ''};
            let lastModified = {value: ''};
            if(item.componentDuro)
            {
                const {componentDuro} = item;
                if(componentDuro)
                {
                    cpn.value        = componentDuro.cpn;
                    category.value   = componentDuro.category;
                    duroId.value     = componentDuro._id;
                    lastModified.value = componentDuro.lastModified;
                }
            }

            if(item.properties)
            {
                const {properties} = item;
                cpn         = properties.cpn;
                category    = properties.category;
                duroId      = properties.duroId;
                lastModified = properties.lastModified;
            }
            item.properties  = {cpn, category, duroId, lastModified};
            if(item.type === 'Assembly')
            {
                if(!item.nestedChildren)
                {
                    item = this.getChildAssembly(item, false);
                }
                for(let children of item.nestedChildren)
                {
                    this.updateComponentProperties(children);
                }
            }
        }
    }

    getMetaData(item, cb)
    {
        let queryParams = this.props.queryParams;
        let {checkedComponents} = this.state;
        const handleResponse = ((error, response) =>
        {
            let {fetchingProperties} = this.state;
            const {rightSidebarOnShapeData, rightSidebarComponentDuro}    = this.props;
            const updateFetchingPropertiesArray = (id) =>
            {
                fetchingProperties.some((currrentItem, index) =>
                {
                    if(currrentItem === id)
                    {
                        fetchingProperties.splice(index, 1);
                        return true;
                    }
                });
            }

            const id  = item.type === "Part" ? item.partId : item.elementId;
            if(error)
            {
                updateFetchingPropertiesArray(id);
            }
            else
            {
                const {componentDuro, onShapeData} = response;
                const cpn           = componentDuro ? componentDuro.cpn : '';
                const cpnObj        = {name: 'cpn', value: cpn};
                const category      = componentDuro ? componentDuro.category : null;
                const categoryObj   = {name: 'category', value: category};
                const duroId        = {value: componentDuro ? componentDuro._id: null};
                const lastModifiedObj = {name: 'lastModified', value: componentDuro.lastModified};
                item.properties     = {cpn: cpnObj, category: categoryObj, duroId, lastModified: lastModifiedObj};
                let checkedComponentsItem  = checkedComponents.find(cmp => item.id === cmp.id);
                let {list} = this.state;
                if(checkedComponentsItem)
                {
                    checkedComponentsItem.properties = item.properties;
                }
                updateFetchingPropertiesArray(id);
                const rightSidebarComponentCpn  = rightSidebarComponentDuro.cpn;
                if(rightSidebarOnShapeData && rightSidebarComponentDuro && cpn.value !== rightSidebarComponentCpn)
                {
                    if(item.type === 'Part' && rightSidebarOnShapeData.partId && rightSidebarOnShapeData.partId === item.partId && rightSidebarOnShapeData.elementId === item.elementId)
                    {
                        const payload = {partId: item.partId, elementId: item.elementId, documentVersion: onShapeData.documentVersion, documentId: onShapeData.documentId, workspaceId: this.getWorkSpaceId(onShapeData.workspaceId)};
                        this.props.dispatch(componentActions.getComponent(payload));
                    }
                    else if(item.type === 'Assembly' && rightSidebarOnShapeData.elementId && rightSidebarOnShapeData.elementId === item.elementId)
                    {
                        const payload = { elementId: item.elementId, documentId: onShapeData.documentId, documentVersion: onShapeData.documentVersion, workspaceId: this.getWorkSpaceId(onShapeData.workspaceId)};
                        this.props.dispatch(assemblyActions.getAssemblyMetaData(payload));
                    }
                }
            }
            if(fetchingProperties.length === 0)
            {
                let {list} = this.state;
                if(cb)
                {
                    cb()
                }
                else
                {
                    this.props.dispatch(assemblyActions.assemblyLoading(false));
                    this.setState({list: this.state.list, fetchingProperties});
                }
            }
        });


        if(item.type === 'Assembly')
        {
            let {fetchingProperties} = this.state;
            fetchingProperties.push(item.elementId);

            this.setState({fetchingProperties}, () =>
            {
                const payload = { elementId: item.elementId, documentId: item.documentId ? item.documentId : queryParams.documentId, workspaceId: this.getWorkSpaceId(item.workspaceId), documentVersion: item.documentVersion, successCb: handleResponse}
                this.props.dispatch(assemblyActions.getAssemblyMetaData(payload));
                if(!item.nestedChildren)
                {
                    item = this.getChildAssembly(item, false);
                }
                item.nestedChildren.forEach((component) =>
                {
                    this.getMetaData(component, cb);
                });
            });
        }
        else if(item.type === 'Part')
        {
            let {fetchingProperties} = this.state;
            fetchingProperties.push(item.partId);
            this.setState({fetchingProperties}, () =>
            {
                const payload = { partId: item.partId, elementId: item.elementId, documentId: item.documentId ? item.documentId : queryParams.documentId, workspaceId: this.getWorkSpaceId(item.workspaceId), documentVersion: item.documentVersion, successCb: handleResponse}
                this.props.dispatch(componentActions.getComponent(payload));
            });
        }
    }

    refreshAssembly()
    {
        let {list} = this.state;
        const afterRefresh = () =>
        {
            this.setState({list}, () => this.props.afterRefreshAssembly(list));
        };

        this.getMetaData(list, afterRefresh);
    }

    filterDeletedAndSuppressedInstances(children)
    {
      if(children && children.length > 0)
      {
          let response = [];
          for(let i = 0; i < children.length; i++)
          {
            let childrenType     = children[i].type && children[i].type.toLowerCase();
            let needsToBeSkipped = childrenType && (childrenType === 'part' && !children[i].partId) || (childrenType === 'assembly' && children[i].status === 'DeletedElement');

            needsToBeSkipped = needsToBeSkipped || children[i].suppressed;
            if(needsToBeSkipped)
            {
              continue;
            }
            response.push(children[i]);
          }
          return response;
      }
      return children;
    }

    getChildAssembly(component, setState=true)
    {
        let {list}            = this.state;
        let {subAssemblies}   = this.props;
        component.workspaceId = this.props.queryParams && this.props.queryParams.workspaceId ? this.props.queryParams.workspaceId : "";
        if(!component.nestedChildren)
        {
            let reqAssembly   = subAssemblies.filter(function(currentValue)
            {
               return component.elementId === currentValue.elementId;
            });
            if(reqAssembly[0] && reqAssembly[0].instances)
            {
                reqAssembly[0].instances.forEach((assembly) =>
                {
                    assembly.iconClass = '';
                    assembly.listClass = 'close';
                });
            }
            component.nestedChildren = reqAssembly[0] && reqAssembly[0].instances ? this.filterDeletedAndSuppressedInstances(reqAssembly[0].instances) : null;
            let cpnWithoutVariant = Utils.getCpnWithoutVariant(extractCpn(component));
            let parent = {cpn: cpnWithoutVariant, configurationString: getConfigurationString(component), elementId: component.elementId, documentId: component.documentId};
            component.nestedChildren.forEach((children) => {
                children.parent = parent;
                // children.parents = [component.elementId];
            });
            component.nestedChildren = JSON.parse(JSON.stringify(component.nestedChildren));
        }
        if(setState)
        {
            if (!component.iconClass)
                component.iconClass = 'open'
            else
                component.iconClass = component.iconClass == 'open' ? '' : 'open'

            if (!component.listClass)
                component.listClass = 'open'
            else
                component.listClass = component.listClass == 'open' ? 'close' : 'open'
            this.setState({ list });
        }
        else
        {
            component.listClass = 'close';
            return component;
        }
    }

    redirectToItem(component)
    {
        const {dispatch, queryParams} = this.props;
        component.workspaceId = this.props.queryParams && this.props.queryParams.workspaceId ? this.props.queryParams.workspaceId : "";

        let {elementId, documentId, documentVersion, configurationString, partId, fullConfiguration, isStandardContent} = component;
        if(component.type === "Assembly")
        {
            let payload = { elementId, isRedirecting: true, documentId, documentVersion, workspaceId: getWorkSpaceId(component.workspaceId, queryParams), configurationString: getConfigurationString(component)};
            dispatch(assemblyActions.getAssemblyMetaData(payload));
        }
        else if(component.type === "Part")
        {
            let payload = { partId, elementId, isRedirecting: true, documentId, documentVersion, workspaceId: getWorkSpaceId(component.workspaceId, queryParams), configurationString: configurationString ? configurationString : fullConfiguration, linkDocumentId: component.parent && component.parent.documentId, isStandardContent};
            dispatch(componentActions.getComponent(payload));
        }

        if(document.querySelector('.assembly-ul-list-inner .green'))
        {
            document.querySelector('.assembly-ul-list-inner .green').classList.remove('green')
        }
        this.markSelectedComponent(component)
    }

    markSelectedComponent(component)
    {
        let state = this.state
        if(state.selectedComponent)
        {
            state.selectedComponent.selectedClass = ''
        }
        state.selectedComponent = component
        component.selectedClass = 'selected'
        state.buttonHighlight = false
        this.setState(state);
    }

    togglePullRequestModal()
    {
        let {showPullRequestModal, checkedComponents, baseComponentList, list, checkedComponentsBackup} = this.state;
        if(!showPullRequestModal)
        {
            baseComponentList        = JSON.parse(JSON.stringify(list));
            checkedComponentsBackup  = JSON.parse(JSON.stringify(checkedComponents));
            checkedComponents        = this.filterCheckedComponents();
        }
        else
        {
            list               = baseComponentList;
            baseComponentList  = [];
            checkedComponents = checkedComponentsBackup;
        }
        displayOrder = 0;
        this.setState({showPullRequestModal : !showPullRequestModal, checkedComponents, list, baseComponentList, checkedComponentsBackup});
    }

    filterCheckedComponents()
    {
        const isInArray = (array, component) =>
        {
            const type = component.type;
            let exist  = false;
            array.forEach((currrentItem) =>
            {

                if((type === 'Assembly' && currrentItem.elementId === component.elementId) || (type === 'Part' && currrentItem.partId === component.partId && currrentItem.elementId === component.elementId))
                {
                    // currrentItem.parents.push(component.parents[0]);
                    if(type === 'Part' && currrentItem.partId === component.partId && currrentItem.elementId === component.elementId && currrentItem.fullConfiguration && component.fullConfiguration && currrentItem.fullConfiguration !== component.fullConfiguration)
                    {
                        exist = false;
                    }
                    else
                    {
                        exist = true;
                    }
                }
            });
            return exist;
        }

        const {checkedComponents} = this.state;
        let alreadyAddedItems     = [];
        const filteredComponents  = checkedComponents.filter((component) =>
                                    {
                                        const res = isInArray(alreadyAddedItems, component);
                                        if(!res)
                                        {
                                            alreadyAddedItems.push(component);
                                            return component;
                                        }

                                    });


        const setQuantityProperty = () =>
        {
            for(let cmp of filteredComponents)
            {
                cmp.quantity = 0;
                if(cmp.parent)
                {
                    this.calculateOccurrences(cmp, this.props.mainAssembly);
                    if(cmp.quantity === 0)
                    {
                        for(let assembly of this.props.subAssemblies)
                        {
                            this.calculateOccurrences(cmp, assembly);
                        }
                    }
                }
            }
        }
        setQuantityProperty();
        return filteredComponents;
    }


    removeIfComponentIsInCheckedArray(item, checkedComponents)
    {
        const id = item.id ? item.id : getComponentOnshapeId(item);
        checkedComponents.forEach((currrentItem, index) =>
        {
            let idToBeCompared = getComponentOnshapeId(currrentItem);
            if((currrentItem.id && currrentItem.id === id) || idToBeCompared === id)
            {
                if(item.configurationString && currrentItem.configurationString === item.configurationString)
                {
                    checkedComponents.splice(index, 1);
                }
                else if(!item.configurationString)
                {
                    checkedComponents.splice(index, 1);
                }
            }
        });
    }

    // The same logic was inside toggleItemChecked method.
    // (item.partNumber && item.partNumber === cmp.partNumber)
    // is used almost same way but with a different context inside 'find' function
    fillRemoveCheckedComponent(item) {
        let { checkedComponents } = this.state;

        if(item.isChecked)
        {
            let matchingCmp = checkedComponents.find(cmp => ((item.id && item.id === cmp.id) || (item.partNumber && item.partNumber === cmp.partNumber)));

            if(matchingCmp && matchingCmp.configurationString)
            {
                matchingCmp = checkedComponents.find(cmp => (item.partNumber && item.partNumber === cmp.partNumber && item.configurationString && cmp.configurationString && item.configurationString === cmp.configurationString));
            }
            if(!matchingCmp)
            {
                checkedComponents.push(item);
            }
        }
        else
        {
            this.removeIfComponentIsInCheckedArray(item, checkedComponents);
        }
    }

    handlerDoubleClick(item) {
        let { checkedComponents, bulkStatusUpdateBtn } = this.state

        if (item.isChecked) {
            item.isChecked = false

            item.nestedChildren.forEach((children) =>
            {
                this.toggleItemChecked(children, false, false)
            })
        } else {
            item.isChecked = !item.isChecked

        }

        this.fillRemoveCheckedComponent(item)

        let justAsembly = checkedComponents.filter(el => el.type === "Assembly")
        bulkStatusUpdateBtn.class = this.setBulkStatusUpdateBtnClass(justAsembly)
        this.setState({checkedComponents: justAsembly})
    }

    toggleItemChecked(item, value, isForChild=false, isShiftClick=false)
    {
        let {list, checkedComponents, bulkStatusUpdateBtn} = this.state;

        if (isShiftClick && !isForChild) {
            this.handleShiftSelect(item, value);
            return;
        }

        item.isChecked = value;
        if(item.type === "Assembly")
        {
            if(!item.nestedChildren)
            {
                item = this.getChildAssembly(item, false);
            }
            item.nestedChildren.forEach((children) =>
            {
                let isLinkedComponent = children.documentVersion && !children.isStandardContent;
                if(isLinkedComponent)
                {
                    this.toggleItemChecked(children, false, true);
                }
                else
                {
                    this.toggleItemChecked(children, value, true);
                }
            });
        }

        this.fillRemoveCheckedComponent(item)

        if(!isForChild)
        {
            bulkStatusUpdateBtn.class = this.setBulkStatusUpdateBtnClass(checkedComponents);
            this.setState({ lastClickedItem: item, list, checkedComponents, bulkStatusUpdateBtn });
        }
    }

    handleShiftSelect(item, value) {
        const { lastClickedItem } = this.state;

        if (!lastClickedItem) {
            this.toggleItemChecked(item, value, false, false);
            return;
        }

        const allItems = this.getAllItems();
        const lastIndex = allItems.findIndex(i => i.uniqueId === lastClickedItem.uniqueId);
        const currentIndex = allItems.findIndex(i => i.uniqueId === item.uniqueId);

        if (lastIndex === -1 || currentIndex === -1) return;

        const start = Math.min(lastIndex, currentIndex);
        const end = Math.max(lastIndex, currentIndex);

        // Get the last item in the range
        const lastItem = allItems[currentIndex];

        // Create a Set of ancestor IDs for quick lookup
        const ancestorIds = new Set();
        let currentNode = lastItem;
        while (currentNode && currentNode.parent) {
            const parentId = this.getItemId(currentNode.parent);
            ancestorIds.add(parentId);
            // Find the parent node in allItems
            currentNode = allItems.find(item => this.getItemId(item) === parentId);
        }

        // Process range items
        allItems.slice(start, end + 1).forEach(rangeItem => {
            if (rangeItem.documentVersion && !rangeItem.isStandardContent) {
                return;
            }

            // Check if this item is an ancestor of the last item
            const isAncestor = ancestorIds.has(this.getItemId(rangeItem));

            // Prevent recursive selection for ancestors
            if (isAncestor && rangeItem.type === "Assembly") {
                rangeItem.isChecked = value;
                this.fillRemoveCheckedComponent(rangeItem);
            } else {
                // Normal selection for all other items
                this.toggleItemChecked(rangeItem, value, false, false);
            }
        });

        this.setState({ lastClickedItem: item });
    }

    getItemId(item) {
        if (!item) return null;
        return item.type === 'Part' ?
            `${item.partId}-${item.elementId}` :
            item.elementId;
    }

    getAllItems() {
        const items = [];
        let uniqueCounter = 0;

        const createUniqueId = (node, parentId = '') => {
            const prefix = parentId ? `${parentId}/` : '';
            const nodeType = node.type === 'Part' ? 'P' : 'A';
            const instanceMarker = node.isInstance ? 'i' : '';
            return `${prefix}${nodeType}${instanceMarker}-${uniqueCounter++}`;
        };

        const traverse = (node, parentId = '', depth = 0) => {
            if (!node) return;

            node.uniqueId = createUniqueId(node, parentId);

            items.push(node);

            if (node.type === 'Assembly') {
                // Only traverse nestedChildren if this is not an instance
                if (!node.isInstance && node.nestedChildren) {
                    node.nestedChildren.forEach(child => {
                        child.isInstance = false;
                        traverse(child, node.uniqueId, depth + 1);
                    });
                }
                // Only traverse instances if we don't have nestedChildren
                else if (!node.nestedChildren && node.instances) {
                    node.instances.forEach((instance, idx) => {
                        instance.isInstance = true;
                        traverse(instance, node.uniqueId, depth + 1);
                    });
                }
            }
        };

        const root = this.state.list || this.state.partStudio;
        if (Array.isArray(root)) {
            root.forEach((item, idx) => {
                item.isInstance = false;
                traverse(item, `root${idx}`, 0);
            });
        } else {
            root.isInstance = false;
            traverse(root, 'root', 0);
        }

        return items;
    }

    setBulkStatusUpdateBtnClass(checkedComponents)
    {
        let btnClass = 'disabled';
        for(let cmp of checkedComponents)
        {
            if(cmp.properties && cmp.properties.cpn.value)
            {
                btnClass = '';
            }
            else
            {
                btnClass = 'disabled';
                break;
            }
        }
        return btnClass;
    }

    afterPullRequestSubmit(components)
    {
        let {checkedComponents, list, partStudio} = this.state;
        if(partStudio && !list)
        {
            for(let part of partStudio)
            {
                this.updateCpns(part, components);
            }
        }
        else
        {
            this.updateCpns(list, components);
        }

        checkedComponents = [];
        this.setState({showPullRequestModal: false, list, checkedComponents});
        this.props.setAssemblyRefreshFlags(true);
    }

    updateCpns(component, checkedComponents, parent)
    {
        component.isChecked = false;
        let {rightSidebarOnShapeData, queryParams} = this.props;
        const matchingCmp = checkedComponents.find(cmp =>
        {
            if(component.type === 'Assembly')
            {
                return cmp.elementId === component.elementId;
            }
            else if(component.type === 'Part')
            {
                let exist = cmp.partId === component.partId && cmp.elementId === component.elementId;
                if(exist && cmp.fullConfiguration && component.fullConfiguration && cmp.fullConfiguration !== component.fullConfiguration)
                {
                    return false;
                }
                return exist;
            }
        });

        if(matchingCmp && matchingCmp.status !== 'contain-errors')
        {
            component.properties.cpn.value = matchingCmp.properties.cpn.value;
            component.properties.category.value = matchingCmp.properties.category.value;
            component.properties.duroId.value = matchingCmp.properties.duroId.value;
            component.properties.lastModified.value = matchingCmp.properties.lastModified.value;

            if(rightSidebarOnShapeData)
            {
                let payload = null;
                if(matchingCmp.type === 'Part' && rightSidebarOnShapeData.partId && rightSidebarOnShapeData.partId === matchingCmp.partId && rightSidebarOnShapeData.elementId === matchingCmp.elementId)
                {
                    payload = { partId: matchingCmp.partId, elementId: matchingCmp.elementId, documentId: matchingCmp.documentId,
                                workspaceId: getWorkSpaceId(component.workspaceId, queryParams), documentVersion: matchingCmp.documentVersion, isStandardContent: matchingCmp.isStandardContent, configurationString: getConfigurationString(matchingCmp)};
                }
                else if(matchingCmp.type === 'Assembly' && rightSidebarOnShapeData.elementId && rightSidebarOnShapeData.elementId === matchingCmp.elementId)
                {
                    payload = { elementId: matchingCmp.elementId, documentId: matchingCmp.documentId,workspaceId: getWorkSpaceId(component.workspaceId, queryParams), documentVersion: matchingCmp.documentVersion, configurationString: matchingCmp.configurationString};
                }

                if(payload)
                {
                    this.refreshRightSideBar(payload);
                }
            }
        }

        let parentCpn = extractCpn(parent);
        if(parent && parentCpn)
        {
            component.parent.cpn = parentCpn;
        }

        if(component.type === 'Assembly')
        {
            component.nestedChildren.forEach((cmp) =>
            {
                this.updateCpns(cmp, checkedComponents, component);
            });
        }
    }

    refreshRightSideBar(payload)
    {
        if(payload.partId)
        {
            this.props.dispatch(componentActions.getComponent(payload));
        }
        else
        {
            this.props.dispatch(assemblyActions.getAssemblyMetaData(payload));
        }
    }

    toggleUpdateStatusModal(cmpList, hasError=false, errorData=[])
    {
        let {showUpdateStatusModal, checkedComponents, componentsForUpdateStatusModal} = this.state;
        let {rightSidebarComponentDuro, rightSidebarOnShapeData, queryParams} = this.props;
        let errorMessage = "";
        if(!showUpdateStatusModal)
        {
            componentsForUpdateStatusModal = this.filterUniqueElements(checkedComponents);
        }

        this.setState({showUpdateStatusModal: !showUpdateStatusModal, componentsForUpdateStatusModal, showErrorModal: hasError, errorData});
        if(cmpList.length > 0 && rightSidebarComponentDuro && showUpdateStatusModal)
        {
            let rightSideElement  = cmpList.find(cmp => rightSidebarComponentDuro._id === cmp._id);
            if(rightSideElement)
            {
                let payload = null;
                if(rightSidebarOnShapeData.type === 'Part')
                {
                    payload = { partId: rightSidebarOnShapeData.partId, elementId: rightSidebarOnShapeData.elementId, documentId: rightSidebarOnShapeData.documentId, workspaceId: getWorkSpaceId(rightSidebarOnShapeData.workspaceId, queryParams), documentVersion: rightSidebarOnShapeData.documentVersion};
                }
                else if(rightSidebarOnShapeData.type === 'Assembly')
                {
                    payload = { elementId: rightSidebarOnShapeData.elementId, documentId: rightSidebarOnShapeData.documentId,
                              workspaceId: getWorkSpaceId(rightSidebarOnShapeData.workspaceId, queryParams), documentVersion: rightSidebarOnShapeData.documentVersion};
                }

                if(payload)
                {
                    this.refreshRightSideBar(payload);
                }
            }
        }
    }

    filterUniqueElements(array)
    {
        let response = [];
        for(let cmp of array)
        {
            let isAlreadyPushed  = response.find(res => res.properties.cpn && res.properties.cpn.value === cmp.properties.cpn && cmp.properties.cpn.value);
            if(!isAlreadyPushed)
            {
                response.push(cmp);
            }
        }
        return response;
    }

    onCloseModal()
    {
        this.setState({showErrorModal: false, errorData: []});
    }

    render()
    {
        let {list, showPullRequestModal, checkedComponents, fetchingProperties, refreshModal, partStudio, bulkStatusUpdateBtn, showUpdateStatusModal, componentsForUpdateStatusModal, showErrorModal, errorData} = this.state;
        let {assemblyInnerLoading, refreshAssembly, dispatch, subAssemblies, parts, queryParams, mainAssembly, bulkUpdate, assemblies, refreshDocument} = this.props;
        const isPullPartBtnDisabled = checkedComponents.length;

        if (assemblyInnerLoading || (!list && !partStudio))
        {
            return (<div className='inner-assembly-loading'>
                        <RingLoader
                            css={spinnerStyles}
                            sizeUnit={sizeUnit}
                            size={size}
                            color={color}
                            loading={true}
                        />
                    </div>);
        }
        else
        {
            return (
                    <div className='nav-block'>
                        <div className='side-nar-list'>
                            {
                              (mainAssembly || partStudio) &&
                              <LeftSideBarHeader
                                isPullPartBtnDisabled={!isPullPartBtnDisabled}
                                togglePullRequestModal={this.togglePullRequestModal}
                                showPullRequestModal={showPullRequestModal}
                                refreshAssembly={this.refreshAssembly}
                                isWarningModalVisible={refreshModal.visibility}
                                toggleUpdateStatusModal={this.toggleUpdateStatusModal}
                                bulkStatusUpdateBtn={bulkStatusUpdateBtn}
                              />
                            }
                            <ScrollArea
                                className="area"
                                contentClassName="content"
                            >
                                <div className='assembly-ul-list'>
                                    <AssemblyTree
                                        list={mainAssembly}
                                        partStudio={partStudio}
                                        displayOrder={displayOrder}
                                        handlerDoubleClick={this.handlerDoubleClick}
                                        toggleItemChecked={this.toggleItemChecked}
                                        redirectToItem={this.redirectToItem}
                                        dispatch={dispatch}
                                        bulkUpdate={bulkUpdate}
                                        setQuantityProperty={this.setQuantityProperty}
                                        getChildAssembly={this.getChildAssembly}
                                        showPullRequestModal={showPullRequestModal}
                                        queryParams={queryParams}
                                        fetchingProperties={fetchingProperties}
                                        refreshAssembly={refreshAssembly}
                                    />
                                </div>
                            </ScrollArea>
                        </div>
                        {
                            showPullRequestModal &&
                            <PullRequestModal
                                checkedComponents={checkedComponents}
                                onClose={this.togglePullRequestModal}
                                dispatch={dispatch}
                                getChildAssembly={this.getChildAssembly}
                                parts={parts || partStudio}
                                subAssemblies={subAssemblies}
                                afterPullRequestSubmit={this.afterPullRequestSubmit}
                                queryParams={queryParams}
                                assemblies={assemblies}
                            />
                        }
                        {
                            showUpdateStatusModal &&
                            <UpdateStatusModal
                                components={componentsForUpdateStatusModal}
                                dispatch={dispatch}
                                toggleUpdateStatusModal={this.toggleUpdateStatusModal}
                                queryParams={queryParams}
                            />
                        }
                        {
                            showErrorModal &&
                            <ShowError errorMessage={"Someone has recently modified these Components. Please refresh the page to get the latest changes."} hideErrorModal={this.onCloseModal} errorData={errorData}/>
                        }
                    </div>
                );
        }
    }
}

const mapStateToProps = ({componentReducer}) => ({
    rightSidebarComponentDuro: componentReducer.componentDuro,
    rightSidebarOnShapeData: componentReducer.OnShapeData,
});

export default connect(mapStateToProps)(ItemAssembly);
