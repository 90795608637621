// It is just a middleware same like thunk to communicate outside world i-e APIs
// Every Action comes first to redux-saga as it is running/ listening the these actions & connected to store with reducers...
// In order to run our Saga, we need to:

//   create a Saga middleware with a list of Sagas to run...
//   connect the Saga middleware to the Redux store ...
import { put, takeLatest, cps, select }     from 'redux-saga/effects';
import { getAssemblyDefinition }            from '../services/duroService';
import * as duroActions                     from '../actions/duro';
import * as oauthActions                    from '../actions/oauth';
import { getQueryParams } from './selectors/component-selectors';
import { getMainAssemblyId } from './selectors/assemblies-selector';

export function* duroSaga({ payload }) {
    try
    {
        payload.queryParams = yield select(getQueryParams);
        yield put(duroActions.duroLoading(true));
        let response = yield cps(getAssemblyDefinition, payload);
        if(response.error || response.status === 404 || response.status === 400)
        {
            payload.elementId = yield select(getMainAssemblyId);
            payload.configurationString = '';
            response = yield cps(getAssemblyDefinition, payload);
            if(payload.successCb)
            {
                payload.successCb(null, response, payload.shouldResyncBom, 'default');
            }
            else
            {
                yield put(duroActions.getDataSuccess(response));
            }
        }
        else if(payload.successCb)
        {
            if(response.error)
            {
                payload.successCb(response);
            }
            else
            {
                payload.successCb(null, response);
            }
        }
        else
        {
            yield put(duroActions.getDataSuccess(response));
        }
    } catch (error) {
        // it instructs the middleware to dispatch an action to the Store
        yield put(duroActions.getDataError(error));
    }
}

export default function* watchAuthProcess() {
    yield takeLatest(duroActions.getAssemblyDefination().type, duroSaga);
}
