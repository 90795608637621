// It is just a middleware same like thunk to communicate outside world i-e APIs
// Every Action comes first to redux-saga as it is running/ listening the these actions & connected to store with reducers...
// In order to run our Saga, we need to:

//   create a Saga middleware with a list of Sagas to run...
//   connect the Saga middleware to the Redux store ...

import { put, call, takeLatest, cps, all, select }                 from 'redux-saga/effects';
import { authTokenOnshapeService, authRefreshtokenOnshapeService } from '../services/authenticationService';
import { checkTokenStatus }                                        from '../helpers/serviceHelper';
import * as oauthActions                                           from '../actions/oauth';
import * as userActions                                            from '../actions/user';
import { getIsOnshapeCompanyAdmin } from '../services/userService';
import config from '../../../config';
import { getQueryParams } from './selectors/component-selectors';

export function* oauthTokenSaga({payload})
{
    try
    {
        yield cps(authTokenOnshapeService, payload, (error, response) => {
            if (payload.successCb && response) payload.successCb();
        });
    }
    catch(error)
    {
        yield console.log("ERROR!! in Access Token....");
    }
}

export function* oauthRefreshtokenSaga(contextSpecificSaga) {
    try {
        const response = yield cps(authRefreshtokenOnshapeService);
        if (response.error) {
            yield console.log('ERROR!! Refreshing token....');
        } else if (contextSpecificSaga) {
            yield put({ type: contextSpecificSaga.payload });
        } else {
            yield put(oauthActions.onshapeTokenSuccess());
        }
    } catch (error) {
        yield console.log('ERROR!! Refreshing token....');
    }
}

export function* oauthIsOnshapeCompanyAdminSaga() {
    try {
        let queryParams= yield select(getQueryParams);
        const isAdmin = yield cps(getIsOnshapeCompanyAdmin, {companyId: queryParams.companyId});
        yield put(oauthActions.setIsOnshapeCompanyAdmin(isAdmin));
    } catch (error) {
        if (config.ENV === 'local') {
            console.error('oauthIsOnshapeCompanyAdminSaga', error);
        }
    }
}

export default function* watchAuthProcess() {
    yield all([
        takeLatest(oauthActions.getOnshapeToken().type, oauthTokenSaga),
        takeLatest(oauthActions.getOnshapeRefreshToken().type, oauthRefreshtokenSaga),
        takeLatest(oauthActions.getIsOnshapeCompanyAdmin().type, oauthIsOnshapeCompanyAdminSaga),
    ]);
}
