export const consts = {
    pusher: {
        channelName: "plugin.onshape.notifications",
        events: {
            documentChanged: "document.onshape.changed",
            syncEnd: "element.sync.completed",
            syncStart: "element.sync.started",
            webhookRegistered: "webhook.registration.success",
            webhookRegistrationFailed: "webhook.registration.failed",
        },
    },
};

export const WebhookRegistrationFailCause = {
    NOT_ADMIN: "NOT_ADMIN",
    ONSHAPE_API_ERROR: "ONSHAPE_API_ERROR",
    STORE_FAILURE: "STORE_FAILURE",
    UNKNOWN: "UNKNOWN",
};
