import React, { Component }      from "react"

const SvgIcon = () =>
{
	return <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="2-UNSYNCED-PART-READ-ONLY" transform="translate(-1791.000000, -50.000000)" stroke="#FFFFFF">
            <g id="TOP" transform="translate(1436.000000, 41.000000)">
                <g id="EDIT" transform="translate(355.000000, 9.000000)">
                    <g id="Group-8" transform="translate(0.947368, 0.947368)">
                        <polyline id="Stroke-4" strokeWidth="1.25" points="10.3650632 2.88928421 1.41527368 11.8381263 0.363694737 15.5754947 4.10106316 14.5239158 13.0499053 5.57412632"></polyline>
                        <path d="M13.0503789,5.57412632 L14.5093263,4.11517895 L15.2056421,3.41886316 C15.6272211,2.99728421 15.6272211,2.31517895 15.2056421,1.89265263 L14.0460632,0.734021053 C13.6244842,0.311494737 12.9423789,0.311494737 12.5208,0.734021053 L11.8244842,1.43033684 L10.3655368,2.88928421" id="Stroke-6" strokeWidth="1.25"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
