import Utils                        from "../utils";
import axios                        from 'axios';
import { getJWToken }               from './jwt-token-service';
import Defaults                     from '../../../config';
import { getLocalStorageItem }      from '../helpers/components-helper';

class authService extends Utils.EventEmitter
{
    init()
    {
        this.handleAuthentication();
    }

    handleAuthentication = () =>
    {
        this.isSignedIn()
            .then((user) =>
            {
                this.emit('onAutoLogin', user);
            })
            .catch(err =>
            {
                this.emit('onUnauthorize', err);
            });
    };

    isSignedIn = () =>
    {
        return new Promise((resolve, reject) =>
        {
            const jwToken = getJWToken();
            let modifiedBaseUrl = getLocalStorageItem("baseUrl");
            if(!jwToken)
            {
                reject('JWT token not found');
            }
            else
            {
                const url = `${modifiedBaseUrl && Defaults.ENV !== 'local' ? modifiedBaseUrl : Defaults.API_ORIGIN}/onshape/getUserByToken`;
                const headers =
                {
                    headers     :
                    {
                        "Content-Type": "application/json",
                        "Authorization": `BEARER ${jwToken}`
                    }
                }
                axios.post(url, {}, headers)
                .then( (response) =>
                {
                    if(Defaults.ENV === 'production')
                    {
                        let {data} = response.data;
                        try
                        {
                            let user = data;
                            window.FS.identify(user._id, {
                                displayName: `${user.firstName} ${user.lastName}`,
                                email: user.email
                            });
                        }
                        catch(err)
                        {
                            console.log(err);
                        }
                     }
                    resolve(response.data);
                })
                .catch((error) =>
                {
                    reject(error);
                });
            }
        });
    };
}

const instance = new authService();

export default instance;
