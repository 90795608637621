import                                "./index.scss"
import React, { useEffect }                     from "react"
import ReactTooltip from "react-tooltip"

const InlineIcon = (props) =>
{
  useEffect(()=> {
    props.tooltip && ReactTooltip.rebuild();
  },[])
  return  <div
                name={props.name ? props.name : ""}
                key={Math.random()}
                onClick={props.onClick ? props.onClick : null}
                className={"ui-icon " + (props.className || '')}
                data-tip={props.tooltip ? props.tooltip : ""}
                data-type={props.type ? props.type : ""}
                data-place={props.tooltipPlace ? props.tooltipPlace : 'bottom'}
                data-for={props.dataId ? props.dataId : null}
                >
                {props.children}
            </div>
}


export default InlineIcon
