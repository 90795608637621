import { createAction } from 'redux-actions';

// Duro ACTION CREATORS
export const duroLoading 						= createAction('DURO_LOADING');
export const getAssemblyDefination              = createAction('GET_ASSEMBLY_DEFINATION');
export const getDataSuccess 				    = createAction('GET_DATA_SUCCESS');
export const getDataError 					    = createAction('GET_DATA_ERROR');
export const setAssemblyState 			        = createAction('SET_ITEM_ASSEMBLY_STATE');
export const setAssemblyList 				    = createAction('SET_ASSEMBLY_LIST');
export const resetState 				 	    = createAction('RESET_STATE');
export const setChildrenVisible 		        = createAction('SET_CHILDREN_VISIBLE');
export const getAndSetChildAssembly             = createAction('GET_AND_SET_CHILD_ASSEMBLY');
export const setWebhookModal                    = createAction('SET_WEBHOOK_MODAL');
