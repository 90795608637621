import React, { Component }      from "react"

const SvgIcon = () =>
{
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
    <title>arrow-row</title>
    <g id="Layer_2" data-name="Layer 2">
        <g id="SVGs">
            <polygon className="" points="17.5 26.74 0 8.26 35 8.26 17.5 26.74"/>
            <rect className="cls-2" width="35" height="35"/>
        </g>
    </g>
	</svg>
}

export default SvgIcon
