import "./index.scss"
import React from "react"
import ModalBox from "../ui/warning-modal-box";
import UpdateStatusIconSrc from '../../assets/icons/update-status-icon';
import revsSrc from '../../assets/icons/logo';
import FlagIconSrc from '../../assets/icons/flag-icon';
import {toOptions, getStatusRank, getComponentName, isDefaultRevisionType, getWorkSpaceId, getConfigurationString} from "../../helpers/components-helper";
import InlineIcon from "../ui/icon/inline-icon.jsx";
import Link from "../ui/link";
import HelpIcon from "../../assets/icons/help-icon";
import Spinner from "../ui/spinner";
import SubmitBtn from "../ui/spinner-button";
import Schemas from "../../modules/schemas";
import Status from "./status.jsx";
import ScrollArea from "react-scrollbar";
import * as componentActions from '../../actions/component';
import Table from './table';
import validations, { validateField, normalizeValue } from '../../modules/validations';
import ShowError from "../ui/show-error";
import Utils from "../../utils";

export class UpdateStatusModal extends React.Component
{
    constructor(props, context)
    {
        super(props, context)

        let isRevisionTypeDefault = isDefaultRevisionType();

        this.revisionScheme = isRevisionTypeDefault ? "default-rev-scheme" : "custom-rev-scheme"
        this.state =
        {
            parentRevision          : { value: '', class:'disabled', message: '', valid: true},
            bulkStatusValueForChild : 'Choose Status',
            list                    : [],
            resetBtn                : { class: 'disabled', text: 'Reset'},
            header                  : { class: '', text: 'Change Status', icon: 'change-status'},
            continueBtn             : { class: '', text: 'Continue', stopLoading: true },
            statusList              : [],
            bulkRevisionApplyBtn    : { class: 'disabled', text: 'Apply', stopLoading: true },
            innerLoading            : false,
            childRevisionFlag       : false,
            bulkRevisionForChild : { value: '', class:'', message: '', valid: true},
            parentStatusValue       : this.props.parentNewStatus || '',
            showErrorModal          : false
        }
        this.baseState                  = JSON.parse(JSON.stringify(this.state));
        this.createInputs               = this.createInputs.bind(this);
        this.remove                     = this.remove.bind(this);
        this.updateAll                  = this.updateAll.bind(this);
        this.createDataForUpdate        = this.createDataForUpdate.bind(this);
        this.resetState                 = this.resetState.bind(this);
        this.submit                     = this.submit.bind(this);
        this.applyBulkRevison           = this.applyBulkRevison.bind(this);
        this.onBulkRevisionChange       = this.onBulkRevisionChange.bind(this);
        this.onRowSelect                = this.onRowSelect.bind(this);
        this.validateChildInputs        = this.validateChildInputs.bind(this);
        this.setHeader                  = this.setHeader.bind(this);
        this.onStatusChange             = this.onStatusChange.bind(this);
        this.onParentStatusChange       = this.onParentStatusChange.bind(this);
        this.onBulkStatusChange         = this.onBulkStatusChange.bind(this);
        this.onParentRevisionChange     = this.onParentRevisionChange.bind(this);
        this.onCloseModal               = this.onCloseModal.bind(this);
    }

    setApplyBtnClass(state)
    {
        let disabledClass = ""
        let {list, bulkRevisionForChild, bulkStatusValueForChild} = this.state;
        disabledClass = this.isPresentCheckedRow(list) && (bulkStatusValueForChild.toLowerCase() !== "choose status" && bulkRevisionForChild.valid || bulkRevisionForChild.value && bulkRevisionForChild.valid) ? "" : "disabled"
        return disabledClass
    }

    onBulkStatusChange(value)
    {
        let state = this.state;
        state.bulkStatusValueForChild = value;

        if (state.bulkStatusValueForChild.toLowerCase() !== "choose status")
        {
            let validationPayload = {
                status: value,
                revSchemeType: window.__revSchemeType,
                libraryType: window.__libraryType,
                isClient: true
            }

            if (state.bulkRevisionForChild.value)
            {
                validateField(state.bulkRevisionForChild, validations.product.revision, state.bulkRevisionForChild.value, validationPayload)
                state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state)
            }
            else
            {
                state.bulkRevisionForChild.valid = true
                state.bulkRevisionForChild.class = ""
                state.bulkRevisionForChild.message = ""
                state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state)
            }
        }
        else
        {
            state.bulkRevisionForChild.valid = true
            state.bulkRevisionForChild.class = ""
            state.bulkRevisionForChild.message = ""
            state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state)
        }
        let _this = this;
        setTimeout(() =>{
            _this.setState(state);
        }, 100);
    }

    onBulkRevisionChange(event)
    {
        let state = this.state;
        let target = event.target;
        let value = target.value.toUpperCase();

        let validationPayload = {
            status: state.bulkStatusValueForChild,
            revSchemeType: window.__revSchemeType,
            libraryType: window.__libraryType,
            isClient: true
        }

        if (value)
        {
            validateField(state.bulkRevisionForChild, validations.product.revision, value, validationPayload)
            state.bulkRevisionForChild.value = value;
            if (state.bulkRevisionForChild.valid)
            {
                state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state);
            }
            else
            {
                state.bulkRevisionApplyBtn.class = "disabled"
            }
        }
        else
        {
            state.bulkRevisionForChild.valid = true
            state.bulkRevisionForChild.class = ""
            state.bulkRevisionForChild.value = ""
            state.bulkRevisionForChild.message = ""
            state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state)
        }
        this.setState(state);
    }

    onParentRevisionChange(event)
    {
        let value = event.target.value;
        let state = this.state;
        let revisions = [];
        state.parentRevision.value = value;
        validateField(state.parentRevision, validations.component.revision, value.toUpperCase(), {status: state.parentStatusValue, revisions, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true});
        this.setHeader(state, false, true);
        this.setState(state);
    }

    onParentStatusChange(value, item)
    {
        let state = this.state
        state.parentStatusValue = value;

        let currentRevision = "";
        state.parentRevision.value    = state.list.length > 0 ? normalizeValue(validations.component.revision, this.props.parentNewRevision, {isClient: true, status: value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, revActionType: null, currentRevision}).revision : ''

        let revisions = [];
        validateField(state.parentRevision, validations.component.revision, state.parentRevision.value.toUpperCase(), {status: state.parentStatusValue, revisions, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true});
        state.parentRevision.class = '';
        if(!state.parentRevision.valid)
        {
            state.continueBtn.class  = 'disabled';
            state.parentRevision.class = 'invalid';
        }
        else
        {
            state.parentRevision.class = '';
        }
        let _this = this;
        setTimeout(() => {
            _this.setState(state);
        }, 100);
    }

    onStatusChange(value, item, index)
    {
        let state = this.state;
        item.nextStatus = value;
        state.list[index] = item;

        let currentRevision = Utils.getPreviousRevision(item);
        let revisions = [];
        let validationPayload = {
            isClient: true,
            status: value,
            revSchemeType: window.__revSchemeType,
            libraryType: window.__libraryType,
            revActionType: null,
            currentRevision,
            revisions,
        };

        if (getStatusRank(item.nextStatus) > getStatusRank(item.status))
        {
            item.nextRevisionInput.value = validations.component.revision.normalize(validationPayload, item.revision).revision;
            validateField(item.nextRevisionInput, validations.component.revision, item.nextRevisionInput.value, validationPayload);
        }
        else
        {
            item.nextRevisionInput  = { value: "", valid: true, message: "", class: ""};
        }
        let _this = this;
        setTimeout(() => {
            _this.setState(state);
        }, 100);
    }

    setHeader(state, errorFound, checkingForErrors=false)
    {
        if(state.list.length === 0)
        {
            state.continueBtn.class = 'disabled';
            state.continueBtn.text  = 'Continue';
            state.header.icon       = 'change-status';
            state.header.text       = 'Change Status';
            state.resetBtn.class    = 'disabled';
            state.header.class      = 'grey';
        }

        if (checkingForErrors)
        {
            state.header.icon       = '';
            state.header.class      = 'grey';
            state.header.text       = 'Checking for errors...';
            state.continueBtn.class = "disabled";
            state.resetBtn.class    = "disabled";
        }
        else if(errorFound)
        {
            state.header.icon       = '';
            state.header.class      = 'contain-errors';
            state.header.text       = 'Errors Found';
            state.continueBtn.class = "disabled";
            state.resetBtn.class    = "";

        }
        else
        {
            state.continueBtn.text  = 'Continue';
            state.header.class      = '';
            state.header.icon       = 'change-status';
            state.header.text       = 'Change Status';
            state.continueBtn.class = '';
            state.resetBtn.class    = "";
        }
    }

    onSelectAllRows(checked)
    {
        let state = this.state
        let { list } = state;
        list.forEach((item, i) =>
        {
            let isValid = !item.co && (item.vendor === "duro" || !this.props.editPage && item.alias === "prd")
            if (isValid)
            {
                item.checked = checked;
            }
        });
        state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state);
        this.setState(state);
    }

    isPresentCheckedRow(list)
    {
        let isPresentCheckedRow = false
        for (let i=0; i < list.length; i++) {
            let revisionManaged = Utils.checkRevisionManaged(list[i]);
            if (list[i].isCheckedOnStatusTable && revisionManaged)
            {
                isPresentCheckedRow = true;
                break;
            }
        }
        return isPresentCheckedRow;
    }

    onRowSelect(event, component)
    {
        let { list, bulkRevisionApplyBtn } = this.state;
        let checked = event.target.checked;
        list.forEach((item) => {
            if (item.cpn === component.cpn)
            {
                item.isCheckedOnStatusTable = checked;
            }
        })
        bulkRevisionApplyBtn.class = this.setApplyBtnClass(this.state);
        this.setState({bulkRevisionApplyBtn, list});
    }

    componentDidUpdate() {
        if (this.props.editPage)
        {
            let childTable = document.querySelector("#update-revision-holder .revision-table")
            if (childTable)
            {
                let height = childTable.clientHeight
                let childTableContainer = document.querySelector("#update-revision-holder")
                if (height > 340 && childTableContainer)
                {
                    childTableContainer.classList.add("apply-scrol")
                }
            }
        }

        let el = document.querySelector('#revisionTable [name="remove"]')
        if (el && this.state.list.length === 0)
        {
            el.classList.add('remove-with-no-row-footer');
        }
    }

    componentWillMount()
    {
        let {components, dispatch} = this.props;
        this.state.innerLoading = true;
        this.setState(this.state);

        let cmps = [];
        components.forEach((item) => {
            cmps.push(item.properties.duroId.value);
        });

        const successCb = (err, res) =>
        {
            if(!res)
            {
                this.state.list = [];
            }
            else
            {
                this.mapOnshapeKeys(res, components);
                this.state.list = res;
                this.setState(this.state, this.createInputs());
            }
        };
        dispatch(componentActions.getCmpsInBulk({components: cmps, products: [], successCb}));
    }

    mapOnshapeKeys(response, components)
    {
        if(response)
        {
            for(let res of response)
            {
                let matchingOnshapeCmp = components.find((cmp) => cmp.properties && cmp.properties.duroId.value == res._id);
                if(matchingOnshapeCmp)
                {
                    res.documentId = matchingOnshapeCmp.documentId;
                    res.elementId  = matchingOnshapeCmp.elementId;
                    res.partId     = matchingOnshapeCmp.partId;
                    res.configurationString = getConfigurationString(matchingOnshapeCmp);
                    res.type       = res.partId ? 'Part' : 'Assembly';
                    res.workspaceId= matchingOnshapeCmp.workspaceId;
                    res.documentVersion = matchingOnshapeCmp.documentVersion;
                    res.isStandardContent = matchingOnshapeCmp.isStandardContent;
                }
            }
        }
    }

    createInputs()
    {
        let state = this.state;
        let list  = state.list;

        list.map((item) => {
            item.isValid            = true;
            item.nextRevisionInput  = { value: "", valid: true, message: "", class: ""};
            item.nextStatus         = item.status;
            item.name               = item.name;
            item.status             = item.status;
            item.alreadyExist       =  false;
            item.previousCat        = item.category ? item.category : '';
            item.previousCpn        = item.cpn ? item.cpn : '';
            item.isCheckedOnStatusTable = true;
        });
        state.list                = list;
        this.baseState.list       = JSON.parse(JSON.stringify(state.list));
        state.innerLoading        = false;
        this.setState(state);
    }

    remove(itemId)
    {
        let list = this.state.list;
        let state = this.state;
        let deletedItemIndex;
        let deletedItem;
        list.forEach((item, i) => {
            if(itemId === item._id)
            {
                deletedItem      = item;
                deletedItemIndex = i;
            }
        })

        list.splice(deletedItemIndex, 1);
        if(this.baseState && this.baseState.list)
        {
            this.baseState.list.splice(deletedItemIndex, 1);
        }
        if(state.list.length === 0)
        {
            state.resetBtn.class = 'disabled';
        }

        if(state.list.length === 0 && this.props.editPage)
        {
            state.continueBtn.class = '';
            state.resetBtn.class    = '';
            state.header.class      = '';
        }

        let errorFoundInParent = this.validateParentInputs(state);
        let errorFoundInChild = this.validateChildInputs(state);
        this.setHeader(state, errorFoundInChild || errorFoundInParent);
        this.setState(state)
    }

    createDataForUpdate(list)
    {
        let data = []
        let state = this.state
        data = list.map((item) =>
        {
            if (this.props.editPage)
            {
                if (state.childRevisionFlag && (getStatusRank(item.nextStatus) > getStatusRank(item.status)))
                {
                    let payloadDate =
                    {
                        _id: item._id,
                        status: item.nextStatus,
                        revision: item.nextRevisionInput.value,
                        modified: true,
                        nextCoRevision: item.nextCoRevision,
                        skipSources: true,
                        alias: item.alias
                    }
                    return payloadDate
                }
            }
            else
            {
                if (getStatusRank(item.nextStatus) != getStatusRank(item.status))
                {
                    let payloadData =
                    {
                        _id: item._id,
                        cpn: item.cpn,
                        status: item.nextStatus,
                        revision: item.nextRevisionInput.value,
                        modified: true,
                        nextCoRevision: item.nextCoRevision,
                        skipSources: true,
                        alias: item.alias,
                        needToUpdateOnShape: item.revision !== item.nextRevisionInput.value,
                        documentId: item.documentId,
                        workspaceId: getWorkSpaceId(item.workspaceId, this.props.queryParams),
                        elementId: item.elementId,
                        partId: item.partId,
                        configurationString: item.configurationString,
                        type: item.type,
                        documentVersion: item.documentVersion,
                        isStandardContent: item.isStandardContent,
                        onShapeCompanyId: this.props.queryParams.companyId,
                        linkDocumentId: this.props.queryParams.documentId,
                        lastModified: item.lastModified
                    };
                    return payloadData;
                }
            }
        });
        data = data.filter(function( element ) {
            return element !== undefined;
        });

        return data
    }

    updateAll()
    {
        let state           = this.state;
        let components      = state.list;
        let cmpData         = this.createDataForUpdate(components);
        if(cmpData && cmpData.length > 0)
        {
            const successCb = (err, res) =>
            {
                if(err)
                {
                    //todo handle errors while fetching data
                }
                else
                {
                    let componentsWithError = res && res.filter((item) => item.hasError);
                    let isError = componentsWithError.length > 0 ? true : false;
                    this.props.toggleUpdateStatusModal(components, isError, componentsWithError);
                }
            };
            let payload = { components: cmpData, responseKeys: ["_id", "cpn"], successCb };
            this.props.dispatch(componentActions.updateCmpsInBulk(payload));
        }
    }

    submit()
    {
        this.state.continueBtn.stopLoading = false;
        this.setState(this.state, this.updateAll());
    }

    resetState()
    {
        this.state.resetBtn.stopLoading = false;
        let parentRevisionValue     = this.state.parentRevision.value;
        let bulkStatusValueForChild = this.state.bulkStatusValueForChild;
        let bulkRevisionForChild    = this.state.bulkRevisionForChild;

        this.setState(this.state);
        let shouldClickOnMasterCheckbox = this.isPresentCheckedRow(this.state.list) ? true : false;
        this.baseState = JSON.parse(JSON.stringify(this.baseState));
        let state      = JSON.parse(JSON.stringify(this.baseState));
        let list       = state.list;

        list.map((item) => {
            item.isValid            = true;
            item.nextRevisionInput  = { value: "", valid: true, message: "", class: "" };
            item.nextStatus         = item.status;
        });
        state.list                    = list;
        state.continueBtn.stopLoading = true;
        state.resetBtn.class          = 'disabled';
        state.resetBtn.stopLoading    = true;
        state.parentRevision.value    = parentRevisionValue;
        state.bulkStatusValueForChild = bulkStatusValueForChild;
        state.bulkRevisionForChild    = bulkRevisionForChild;
        this.setState(state);

        let masterCheckbox = document.querySelector('#update-revision-holder .index-table thead .index-checkbox label');
        if (shouldClickOnMasterCheckbox && masterCheckbox)
        {
            masterCheckbox.click();
        }
    }

    applyBulkRevison()
    {
        let state = this.state;
        let {bulkRevisionForChild} = state;
        let errorFoundInChild = false;
        state.bulkRevisionApplyBtn.class = "disabled";
        state.bulkRevisionApplyBtn.stopLoading = false;
        this.setHeader(state, false, true);
        this.setState(state);

        let bulkStatusRank = getStatusRank(state.bulkStatusValueForChild);
        let _this = this;

        //Add some delay to display loading
        setTimeout(() => {

            state.list.forEach((item) =>
            {
                const previousStatus = Utils.getPreviousStatus(item);
                const previousRevision = Utils.getPreviousRevision(item);
                let itemStatusRank = getStatusRank(item.status)
                if (item.revisionManaged && item.isCheckedOnStatusTable && state.bulkStatusValueForChild.toLowerCase() !== "choose status")
                {
                    item.nextStatus = state.bulkStatusValueForChild;
                }
                if (item.revisionManaged && item.isCheckedOnStatusTable && (getStatusRank(previousStatus) <= getStatusRank(item.nextStatus)))
                {
                    let {revisions} = item;
                    let revisionInput = item.nextRevisionInput;
                    let validationPayload = {
                        status: item.nextStatus,
                        revisions,
                        revSchemeType: window.__revSchemeType,
                        libraryType: window.__libraryType,
                        isClient: true,
                        previousRevision
                    }

                    if (bulkRevisionForChild.value)
                    {
                        item.nextRevisionInput.value = bulkRevisionForChild.value;
                    }
                    else
                    {
                        item.nextRevisionInput.value = validations.component.revision.normalize(validationPayload, previousRevision).revision
                    }
                    validateField(revisionInput, validations.component.revision, item.nextRevisionInput.value.toUpperCase(), validationPayload);
                }
            })
            state.bulkRevisionApplyBtn.stopLoading = true
            let errorFoundInParent = _this.validateParentInputs(state);
            let errorFoundInChild  = _this.validateChildInputs(state);
            _this.setHeader(state, errorFoundInChild || errorFoundInParent);
            _this.setState(state);
        }, 100);
    }

    validateParentInputs(state)
    {
        let errorFound = false;
        if (this.props.editPage && !state.parentRevision.valid)
        {
            errorFound = true;
        }
        return errorFound;
    }

    validateChildInputs(state)
    {
        let errorFound = false;
        let item;
        if (state.list && state.list.length > 0)
        {
            for (let i=0; i < state.list.length; i++) {
                item = state.list[i]
                let nextStatusRank = getStatusRank(item.nextStatus)
                let currentStatusRank = getStatusRank(item.previousStatus)
                let revisionInput = item.nextRevisionInput;
                if (!errorFound && (!revisionInput.valid || !item.isValid || nextStatusRank < currentStatusRank))
                {
                    errorFound = true;
                    break;
                }
            }
        }
        return errorFound;
    }

    onCloseModal()
    {
        this.setState({showErrorModal: false});
    }

    render()
    {
        let state    = this.state;
        let {list, header, innerLoading, showErrorModal} = state;
        let bulkStatus = state.parentStatusValue ? state.parentStatusValue.charAt(0) + state.parentStatusValue.slice(1).toLowerCase() : null
        let markup   =
            <div className = {"update-status-screen-modal "}>
                <ModalBox onClose={this.props.toggleUpdateStatusModal} bodyClass="update-revision-bd" modalId='update-status-modal' showCrossIcon={true}>
                    <div className="update-status-screen">
                        <div className="form-holder">
                            <header className={`lable-mapped modal-header ${header.class}`}>
                                <div className="header-heading">
                                    <InlineIcon className='header-update-status-icon'>
                                        {
                                            header.icon === "change-status" ?  <UpdateStatusIconSrc/> : <FlagIconSrc/>
                                        }
                                    </InlineIcon>
                                    <h3>{header.text}</h3>
                                </div>

                                <div className="btn-holder">
                                    <div className="btn-holder-inner">
                                        <SubmitBtn
                                            onClick={this.resetState}
                                            className={' btn-header ' + state.resetBtn.class + " reset-btn"}
                                            btnText={state.resetBtn.text}
                                            isDisabled={state.resetBtn.class === 'disabled'}
                                            stopLoading={state.resetBtn.stopLoading}
                                        />
                                        <SubmitBtn
                                            onClick={this.submit}
                                            className={" btn-header" + (state.continueBtn.class === 'disabled' ? " disabled" : " active") + (state.continueBtn.stopLoading ? '' : ' pl-15')}
                                            btnText={state.continueBtn.text}
                                            isDisabled={state.continueBtn.class === 'disabled'}
                                            stopLoading={state.continueBtn.stopLoading}
                                        />
                                    </div>
                                </div>
                            </header>

                            <div className="update-status-screen-content-holder">
                                <div className="modal-heading">
                                    <h1>Confirm status change</h1>
                                       <a
                                        href={'https://duro.zendesk.com/hc/en-us/articles/360036511512-Changing-Status-and-Revision'}
                                        target="_blank"
                                        className='ui-link'
                                        >
                                            <InlineIcon>
                                                <HelpIcon/>
                                            </InlineIcon>
                                       </a>
                                </div>

                                <div className="heading-details">
                                    <p>Status changes cannot be undone. Please review the updates below before continuing.</p>
                                </div>

                                <div className="set-bulk-revision-holder" >
                                    <span className="label-holder">Bulk Actions</span>
                                    <div className="bulk-action-select">
                                        <span className={'field-select'}>
                                            <Status
                                                onStatusChange={this.onBulkStatusChange}
                                                className={`bulk-status select-status ${this.props.editPage ? state.childRevisionFlag ? "" : "disabled" : ""} ${this.isPresentCheckedRow(state.list) ? "" : "disabled-status"}`}
                                                name="bulk-status-for-child"
                                                status={state.bulkStatusValueForChild}
                                                options={toOptions(Schemas.component.status.list(), true)}
                                                />
                                        </span>
                                    </div>
                                    <SubmitBtn
                                        onClick={this.applyBulkRevison}
                                        className={state.bulkRevisionApplyBtn.class}
                                        btnText={state.bulkRevisionApplyBtn.text}
                                        isDisabled={state.bulkRevisionApplyBtn.class === 'disabled'}
                                        stopLoading={state.bulkRevisionApplyBtn.stopLoading}
                                    />
                                </div>
                                {
                                    innerLoading ?
                                    <Spinner/> :
                                     <div id="update-revision-holder" className=''>
                                        <ScrollArea
                                            contentClassName="content"
                                            className="update-status-scroll-area"
                                        >
                                            {list && <Table components={list} onRowSelect={this.onRowSelect} onStatusChange={this.onStatusChange} wrapperClass='update-status-modal-table' remove={this.remove} revisionScheme={this.revisionScheme}/>}
                                        </ScrollArea>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </ModalBox>
            </div>
        return markup;
    }
}

export default UpdateStatusModal;
