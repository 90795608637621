import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="34px" height="43px" viewBox="0 0 34 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="BULK-SELECTION-0" transform="translate(-1402.000000, -24.000000)" fillRule="nonzero">
            <g id="Group-2" transform="translate(1381.000000, -2.000000)">
                <g id="NAV" transform="translate(0.000000, 25.000000)">
                    <g transform="translate(0.000000, 1.000000)" id="LOGO">
                        <g transform="translate(21.000000, 0.000000)">
                            <rect id="Rectangle-5" fill="#201F27" x="0" y="0" width="34" height="43"></rect>
                            <path d="M15.5035629,26.0268567 C15.5035629,27.1491261 16.1413302,27.4148224 16.6437055,27.4148224 L17,27.4148224 L17,31.3804387 C16.4263948,31.8002485 15.7516573,32.0160037 15.0653207,31.9990749 C12.6710214,32.0228686 11,30.5754186 11,26.2687593 L11,20.7565525 C11,16.4498932 12.6710214,15.0024432 15.0546318,15.0024432 C15.7465879,14.9735012 16.4274363,15.2023749 16.9893112,15.6528043 L16.9893112,19.6184206 L16.6330166,19.6184206 C16.1306413,19.6184206 15.4928741,19.8841169 15.4928741,21.0024207 L15.5035629,26.0268567 Z M23,32 L18,32 L18,10 L22.9881329,10 L23,32 Z" id="Shape" fill="#3CD1B5"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
