import './index.scss';
import React, { useState, useRef } from 'react';
import Link from '../link';
import InlineIcon from '../icon/inline-icon';
import { isDescendant, generateKey } from '../../../helpers/components-helper';

const Menu = (props) => {
    const [open, setOpenState] = useState(false);
    const inputRef = useRef(null);

    const close = (event) => {
        if (event) {
            const ref = inputRef.current;
            const targ = event.target;
            if (ref === targ || isDescendant(ref, targ)) return;
        }
        document.body.removeEventListener('click', close);
        setOpenState(false);
    };

    const onTriggerClicked = () => {
        if (open) return close();
        document.body.addEventListener('click', close);
        setOpenState(true);
        return true;
    };

    const onOptionClick = (event, option) => {
        event.preventDefault();
        if (option.onClick) {
            return option.onClick(event, option);
        }
        if (option.target_blank) window.open(option.to);
        else props.history.push({ pathname: option.to, state: option.state });
        return true;
    };

    const options = [];

    props.options.forEach((option, i) => {
        const block = (
            <Link
                className={option.disabled ? 'disabled' : ''}
                key={i}
                to={option.to}
                onClick={(e) => onOptionClick(e, option)}
            >
                <div className={`option ${option.labelWapperClass || ''}`}>
                    {option.icon && <InlineIcon>{option.icon}</InlineIcon>}
                    <div className={option.disabled ? 'muted-text' : 'label'}>{option.label}</div>
                </div>
            </Link>
        );

        options.push(block);
    });

    const trigger = !props.label ? props.children : <div className="label">{props.label}</div>;

    const clazz = `menu ${props.className}${open ? ' open' : ''}`;

    const markup = (
        <div className={clazz}>
            <div
                className="custom_trigger"
                ref={inputRef}
                onClick={!props.disabledClickEvent ? onTriggerClicked : null}
            >
                {trigger}
            </div>
            <div className="sub-menu">
                {props.headerText ? <div className="heading-holder">{props.headerText}</div> : null}
                {options}
            </div>
        </div>
    );

    return markup;
};

export default Menu;
