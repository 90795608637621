import React                     from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import ReactTooltip              from 'react-tooltip';
import OauthCodeRequest          from '../components/oauth/authcode-request/index.jsx';
import OauthRedirect             from "../components/oauth/redirect/index.jsx";
import AccessDenied              from "../components/access-denied/index.jsx";
import Main                      from "../components/main/index.jsx";
import SignIn                    from "../components/signin/index.jsx";
import SignInWithSSO             from "../components/signin-with-sso/index.jsx";
import history                   from "../history.jsx";
import Defaults                  from "../../../config";
import Auth                      from "../auth/index.jsx";
import Config                    from '../../../config';

function App(props)
{

    return (
        <Auth history={history}>
            <Router history={history}>
                <div className="App">
                  <Switch>
                    <Route path='/'
                      exact={true}
                      component={ Main }
                    />
                    <Route path='/signin'
                      exact={true}
                      component={ SignIn }
                    />
                    <Route path='/signin-with-sso'
                      exact={true}
                      component={ SignInWithSSO }
                    />
                    <Route
                      exact path="/oauthCodeRequest"
                      component={ () => <OauthCodeRequest authURL={`https://${Config.AUTH_URL}/oauth/authorize?response_type=code&client_id=${Defaults.ONSHAPE_CLIENT_ID}&redirect_uri=${Config.REDIRECT_URI}`} /> }
                      />
                    <Route path='/oauthRedirect' component={OauthRedirect} />
                    <Route path='/accessDenied' component={AccessDenied} />
                  </Switch>
                </div>
                <ReactTooltip effect="solid"/>
                <ReactTooltip id='part-edit-page' effect="solid"/>
                <ReactTooltip id='edit-icon-tooltip' effect="solid" className="edit-icon"/>
          </Router>
        </Auth>
    );
}

export default App;
