import                                               './index.scss';
import React, { Component }                     from 'react';
import { connect }                              from 'react-redux';
import * as componentActions                    from '../../../actions/component';
import ReactTooltip                             from 'react-tooltip';
import { getPropertyObj,
        generateKey,
        createLinkUrl,
        checkToolTipAppearance,
        getImageOfSpecificSize
        }                                       from '../../../helpers/components-helper';
import {assemblyCategories, partCategories}     from '../../../modules/categories';
import ScrollArea                               from "react-scrollbar";
import OpenLinkIconSrc                          from '../../../assets/icons/open-link-icon.jsx';
import InlineIcon                               from '../../ui/icon/inline-icon';
import ModifiedTriangleSrc                      from '../../../assets/icons/modified-triangle-icon.jsx';
import validations, {validateField}             from '../../../modules/validations';
import StatusUpdateModalForNonAssembly          from '../common/update-status-modal-for-non-assembly';
import LazyInput                                from '../../ui/lazy-input/input';
import MassField                                from '../common/mass-field';
import Utils                                    from '../../../utils';
import { syncComponentThumbnail }               from "../../../services/componentService";
import ApplicabilityField                       from '../common/applicability-field';


class ComponentEdit extends Component
{

    constructor(props, context)
    {
        super(props, context);
        let {componentDuro}  = this.props.componentReducer;
        this.onInputChange   = this.onInputChange.bind(this);
        this.setStatusState  = this.setStatusState.bind(this);
        this.onRejectStatusChange  = this.onRejectStatusChange.bind(this);
        this.revisionInputChange   = this.revisionInputChange.bind(this);
        this.onAcceptStatusChange  = this.onAcceptStatusChange.bind(this);
        this.setInputFieldClassForDisabledInputs = this.setInputFieldClassForDisabledInputs.bind(this);
        this.state           = {
                                  isSynced: !(Object.keys(componentDuro).length === 0 && componentDuro.constructor === Object),
                                    statusList    : ["DESIGN", "PROTOTYPE", "PRODUCTION", "OBSOLETE"],
                                    trackStatusChanged: false,
                                    revisionInput: { valid: false },
                               }
    }

    componentWillMount()
    {
        ReactTooltip.hide();
    }

    componentDidMount()
    {
        ReactTooltip.rebuild();
    }

    setStatusState(previousStatus, statusValue)
    {
        if(!previousStatus) return false;
        
        const {statusList}       = this.state;
        let previousStatusIndex    = 0;
        let statusIndex          = 0;
        statusList.forEach((status, i) => {
            if(status === previousStatus) 
                previousStatusIndex = i;
            if(status === statusValue)
                statusIndex = i;
        });

        if(statusIndex >= previousStatusIndex) {
            return false;
        }
        return true;
    }

    onInputChange(event, i)
    {
        let name  = event.target.name;
        let state = this.state;
        let value = event.target.value;
        let {inputs, actualComponentDuro, OnShapeData} = this.props.componentReducer;
        let index = -1;
        let type  = event.target.getAttribute('fieldType');
        let input = null;
        if(OnShapeData.isStandardContent && name !== 'procurement') return;
        if(type && type === 'spec')
        {
            name  = type;
            index = event.target.getAttribute('index');
        }
        let dispatch = true;
        switch(name)
        {
            case 'eid':
                input = inputs.eid;
            break;

            case 'name':
                input = inputs.eid;
            break;

            case 'category':
                input = inputs.category;
            break;

            case 'status':
                state.trackStatusChanged = true;
                dispatch                 = false;
                if (event.target.value === 'DESIGN')
                {
                    state.trackStatusChanged = false
                    this.props.dispatch(componentActions.updateEditFormInputState({ value, name }));
                    this.props.dispatch(componentActions.updateEditFormInputState({value: (actualComponentDuro.status === 'DESIGN' ? actualComponentDuro.revision : component.revision),name: "revision" }));
                }
                state.statusFieldPreviousValue = inputs.status.value;
                state.newStatusValue           = event.target.value;
                let revActionType              = state.newStatusValue === "PROTOTYPE" ? "minor" : "major";
                let previousRevision            = Utils.getPreviousRevision(actualComponentDuro);
                let {revisions}                = actualComponentDuro;
                let validationPayload = {status: state.newStatusValue, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true, revisions, revActionType, previousRevision};
                let revValue = validations.component.revision.normalize(validationPayload, inputs.revision.value).revision;
                validateField(state.revisionInput, validations.component.revision, revValue.toUpperCase(), validationPayload);
                this.setState(state);
            break;

            case 'revision':
                input = inputs.revision;
            break;

            case 'description':
                input = inputs.description;
                input.isTouched = true;
            break;

            case 'procurement':
                input = inputs.procurement;
            break;
        }
        if(dispatch)
        {
            this.props.dispatch(componentActions.updateEditFormInputState({ input, value, name, index }));
        }
    }

    onAcceptStatusChange()
    {
        const {dispatch} = this.props;
        let state        = this.state
        dispatch(componentActions.updateEditFormInputState({ value: state.newStatusValue, name: 'status' }));
        dispatch(componentActions.updateEditFormInputState({ value: state.revisionInput.value, name: 'revision' }));
        state.trackStatusChanged = false;
        this.setState(state);
    }

    onRejectStatusChange()
    {
        this.setState({trackStatusChanged: false});
    }

    revisionInputChange(event)
    {
        let value = event.target.value;
        let name  = event.target.name;
        let state = this.state;
        let {revisions} = this.props.componentReducer.actualComponentDuro;
        let status = state.newStatusValue || "DESIGN";
        let libraryType = window.__libraryType;
        let revSchemeType = window.__revSchemeType;
        let isClient = true;
        let revValue = value.toUpperCase();
        validateField(state.revisionInput, validations.component.revision, revValue, {status, revisions, libraryType, revSchemeType, isClient});
        this.setState(state);
    }

    setInputFieldClassForDisabledInputs(onShapeData, inputs)
    {
        let NotRevisionManaged = getPropertyObj(onShapeData.properties, 'Not revision managed');
        let disabledClass      = (onShapeData.isStandardContent || (NotRevisionManaged && NotRevisionManaged.value)) ? 'disabled' : '';
        let fieldsNotToBeDisabled = ['procurement'];
        Object.keys(inputs).forEach((key) =>
        {
            if(key === 'specs' && !fieldsNotToBeDisabled.includes(key))
            {
                let specs = inputs[key];
                for(let spec of specs)
                {
                    spec.class = disabledClass;
                }
            }
            else if(!fieldsNotToBeDisabled.includes(key))
            {
                inputs[key].class = disabledClass;
            }
        });
    }

    render()
    {
        let {massObj, componentReducer, queryParams}                              = this.props;
        const {OnShapeData, componentDuro, inputs, actualComponentDuro}           = componentReducer;
        const {isSynced, statusFieldPreviousValue, newStatusValue, revisionInput} = this.state;
        let specs         = [];
        let lastModifiedBy, lastModified, eid, description, minQty, unitPrice, leadTime, status, category, procurement, docsLength = 0, manufacturers = 0, link = null;
        this.setInputFieldClassForDisabledInputs(OnShapeData, inputs);
        const categories  = OnShapeData.partId ? partCategories() : assemblyCategories();
        if(isSynced)
        {
            category        = componentDuro.category ? componentDuro.category : "Fabricated Other"
            specs           = inputs.specs ? inputs.specs.map((spec, i) =>
                            {
                                return <li key={spec._id} className="tab-footer-value">
                                            <span className='label specs-key'>
                                                { spec.key }
                                            </span>
                                            <span className='spec-input-field'>
                                                <LazyInput
                                                    className={`duro-input ${spec.class}`}
                                                    onMouseOver={(e) => checkToolTipAppearance(e, spec.message)}
                                                    data-tip=''
                                                    fieldtype="spec"
                                                    name={spec.key}
                                                    index={i}
                                                    type="text"
                                                    onChange={this.onInputChange}
                                                    value={spec.value}/>
                                            </span>
                                        </li>
                                        }) : null
            lastModified    = componentDuro.lastModified ? this.props.formatDate(componentDuro.lastModified) : null;
            lastModifiedBy  = componentDuro.lastModifiedBy ? componentDuro.lastModifiedBy.firstName + " " + componentDuro.lastModifiedBy.lastName : '';
            inputs.revision.value    = componentDuro.revision;
            // inputs.description.value = componentDuro.description;
            minQty          = componentDuro.primarySource ? componentDuro.primarySource.minQuantity : '';
            unitPrice       = componentDuro.primarySource ? componentDuro.primarySource.unitPrice   : '';
            leadTime        = componentDuro.primarySource && componentDuro.primarySource.leadTime.value ? componentDuro.primarySource.leadTime.value + componentDuro.primarySource.leadTime.unit : '';
            inputs.status.value = componentDuro.status;
            inputs.category.value = category;
            inputs.procurement.value = componentDuro.procurement;
            docsLength      = componentDuro.documents ? componentDuro.documents.length : 0;
            link            = createLinkUrl(`component/view/${componentDuro._id}`, 'switchEnvToGeneral=true');
            manufacturers   = componentDuro.manufacturers ? componentDuro.manufacturers.length : 0;
        }
        inputs.description.value = inputs.description.isTouched ? inputs.description.value : getPropertyObj(OnShapeData.properties, 'Description').value;
        inputs.eid.value = componentDuro.eid;
        const sourcingClass     = !isSynced ||  !manufacturers || manufacturers === 0 ? 'grayed-out' : '';
        const releaseDocClass   = !isSynced ||  !docsLength || docsLength === 0 ? 'grayed-out' : '';
        const previousStatus = Utils.getPreviousStatus(actualComponentDuro);
        const imageLink = OnShapeData && OnShapeData.thumbnail ? getImageOfSpecificSize(OnShapeData.thumbnail, '300x170').href : null;
        const createOptions = () =>
        {
            let options = [];
            categories.forEach((cat) =>
            {
                options.push(
                            <option
                                key={generateKey()}
                                value={cat.name}
                                disabled={cat.name === category}
                            >
                                {cat.name}
                            </option>
                           );
            });
            return options;
        }

        return (
                <div className='edit-form'>
                   {
                        this.state.trackStatusChanged &&
                           <StatusUpdateModalForNonAssembly
                               statusFieldPreviousValue={statusFieldPreviousValue}
                               newStatusValue={newStatusValue}
                               revisionInput={revisionInput}
                               onRejectStatusChange={this.onRejectStatusChange}
                               onAcceptStatusChange={this.onAcceptStatusChange}
                               revisionInputChange={this.revisionInputChange}
                           />
                   }
                    <form onSubmit={this.props.onSubmit}>
                        <div className="tab-content-holder">
                            <div className="tab-content-image">
                                <img
                                    src={imageLink}
                                    alt=''
                                />
                            </div>
                            <div className="tab-content-detail">
                                <span className="tab-content-value">
                                    <span className='label'>CPN</span>
                                    <span className='property-value'> {isSynced ? Utils.getCpnValue(componentDuro) : Utils.getCpnTag()}</span>
                                </span>
                                <span className={`tab-content-value disabled`}>
                                    <span className='label'>CAT</span>
                                    <span className='property-value'>
                                        {category}
                                        {/*<select onChange={this.onInputChange} className='duro-input' value={category} name='category-select'>
                                            {createOptions()}
                                        </select>*/}
                                    </span>
                                </span>
                                <span className={`tab-content-value`}>
                                    <span className='label'>EID</span>
                                    <span className='property-value'>
                                        {inputs.eid.value ? inputs.eid.value : ''}
                                    </span>
                                </span>
                                <span className={`tab-content-value ${isSynced ? '' : 'disabled'}`}>
                                    <span className='label'>REV</span>
                                    <span className='property-value'>
                                        {inputs.revision.value}
                                        {
                                            componentDuro && componentDuro.modified ?
                                                <span className="modified">
                                                    <InlineIcon
                                                        tooltip={"Component has been modified"}
                                                        tooltipPlace="right"
                                                    >
                                                      <ModifiedTriangleSrc />
                                                    </InlineIcon>
                                                </span>
                                                :
                                                null
                                        }
                                        {/*<input
                                            className={`${inputs.revision.class} duro-input`}
                                            onChange={this.onInputChange}
                                            type="text"
                                            value={inputs.revision.value ? inputs.revision.value : ''}
                                            name="revision"
                                            data-tip={inputs.revision.message}
                                            data-type="error"
                                            data-place="left"
                                        />*/}
                                    </span>
                                </span>
                                <span className={`tab-content-value status-content ${inputs.status.class}`}>
                                    <span className='label'>STATUS</span>
                                    <span className="">
                                        {/*<span className={inputs.status.value ? `status-label ${inputs.status.value}` : '' }>
                                            {inputs.status.value}
                                        </span>*/}
                                        {<select
                                            name="status"
                                            onChange={(event) => this.onInputChange(event)}
                                            className={`duro-input`}
                                            value={inputs.status.value}
                                            >
                                            <option
                                                value="DESIGN"
                                                disabled={this.setStatusState(previousStatus, 'DESIGN')}
                                            >
                                                DESIGN
                                            </option>
                                            <option
                                                value="PROTOTYPE"
                                                disabled={this.setStatusState(previousStatus, 'PROTOTYPE')}
                                                >
                                                PROTOTYPE
                                            </option>
                                            <option
                                                value="PRODUCTION"
                                                disabled={this.setStatusState(previousStatus, 'PRODUCTION')}
                                            >
                                                PRODUCTION
                                            </option>
                                            <option
                                                value="OBSOLETE"
                                                disabled={this.setStatusState(previousStatus, 'OBSOLETE')}
                                                >
                                                OBSOLETE
                                             </option>
                                        </select>}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="tab-content-footer">
                            <div className='section-creator-created'>
                                <div className="tab-footer-value">
                                    <span className='label'>LAST MODIFIED</span>
                                    <span data-for='part-edit-page'
                                          data-tip=''
                                          className='truncate-text property-value'
                                          onMouseOver={(e) => checkToolTipAppearance(e, lastModified)}
                                          >
                                        {lastModified ? lastModified : ''}</span>
                                </div>
                                <div className="tab-footer-value">
                                    <span className='label'>MODIFIED BY</span>
                                    {<span
                                        data-for='part-edit-page'
                                        data-tip=''
                                        className='truncate-text property-value'
                                        onMouseOver={(e) => checkToolTipAppearance(e, lastModifiedBy)}
                                        >
                                        {lastModifiedBy ? lastModifiedBy : ''}</span>}
                                </div>
                            </div>

                            <div className='section-release-sourcing'>
                                <div className='tab-footer-value release-doc'>
                                    <span className={`release-doc-label ${releaseDocClass} label`}>
                                        LATEST RELEASE
                                    </span>
                                    <span className={`property-value`}>
                                            {docsLength} Document(s)
                                    </span>
                                </div>
                                <div className='tab-footer-value release-doc'>
                                    <span className={`release-doc-label ${sourcingClass} label`}>
                                        SOURCING
                                    </span>
                                    <span className={`property-value`}>
                                        {manufacturers} Source(s)
                                    </span>
                                </div>
                            </div>
                            <div className='section-procurement'>
                                <div className={`tab-footer-value procurement-radio-btns ${isSynced ? '' : 'disabled'}`}>
                                    <span className='label'>PROCUREMENT:</span>
                                    <div className='custom-radio'>
                                        <div className='radio'>
                                            <input
                                               type="radio"
                                               value="Make"
                                               name="procurement"
                                               onChange={(event) => this.onInputChange(event)}
                                               checked={inputs.procurement.value === 'Make' ? true : false}
                                               className='radio-input'
                                               />
                                            <label htmlFor="radio1" />
                                            <span className="procurement-radio-labels property-value" htmlFor="radio1">
                                                Make
                                            </span>
                                        </div>
                                        <div className='radio'>
                                            <input
                                                type="radio"
                                                value="Buy"
                                                onChange={(event) => this.onInputChange(event)}
                                                name="procurement"
                                                checked={inputs.procurement.value === 'Buy' ? true : false}
                                                className='radio-input'
                                            />
                                            <label htmlFor="radio2" />
                                             <span className="procurement-radio-labels property-value" htmlFor="radio2">
                                                Buy
                                            </span>
                                        </div>
                                        <div className='radio'>
                                            <input
                                                type="radio"
                                                value=''
                                                onChange={(event) => this.onInputChange(event)}
                                                name="procurement"
                                                checked={inputs.procurement.value === '' ? true : false}
                                                className='radio-input'
                                            />
                                            <label htmlFor="radio3" />
                                            <span className="procurement-radio-labels property-value" htmlFor="radio3">
                                                N/A
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='section-description-specs'>
                                <div className="tab-footer-value description-footer-value">
                                    <span className='label'>DESCRIPTION</span>
                                    <div  className='description-input-field'>
                                        <input
                                            className={`${inputs.description.class} duro-input`}
                                            name="description"
                                            onChange={(event) => this.onInputChange(event)}
                                            type="text"
                                            value={inputs.description.value ? inputs.description.value : ''}
                                        />
                                    </div>
                                </div>
                                <div className='tab-footer-value specs-div'>
                                    {
                                        Utils.isApplicabilityFieldEnabled() && <ApplicabilityField applicability={getPropertyObj(OnShapeData.properties, 'Applicability')}/>
                                    }
                                    {
                                        specs &&
                                            <div>
                                                <ScrollArea
                                                className="specs"
                                                contentClassName="content"
                                                >
                                                    <div className=''>
                                                        <ul className="specs-scroll">
                                                        {specs}
                                                        </ul>
                                                    </div>
                                                </ScrollArea>
                                            </div>
                                    }
                                </div>
                                <div className='tab-footer-value'>
                                    <MassField massObj={massObj} innerClass='property-value' view={'edit'} queryParams={queryParams}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
        );
  }
}

const mapStateToProps = state =>
({
  componentReducer: state.componentReducer
});

export default connect(mapStateToProps)(ComponentEdit);
