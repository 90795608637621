import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RingLoader } from 'react-spinners';
import AccessDenied from '../../access-denied/index.jsx';
import Main from '../../main/index.jsx';
import * as oauthActions from '../../../actions/oauth';
import { spinnerStyles, sizeUnit, size, color } from '../../../helpers/spinnerStyles';

class OauthRedirect extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { ...props };
    }

    componentDidMount() {
        const handleSuccess = () =>
        {
            localStorage.setItem('loadMain', true);
            this.props.history.push({pathname: '/'});
        }
        this.props.dispatch(oauthActions.getOnshapeToken({access_code: this.state.location.search.split("?")[1], successCb: handleSuccess}));
    }

    render() {
        const mainLoading = localStorage.getItem('loadMain') === 'false';
        return (
            <div className="middle">
                { mainLoading ? (
                    <div className="loader-holder">
                        <RingLoader
                            css={spinnerStyles}
                            sizeUnit={sizeUnit}
                            size={size}
                            color={color}
                            loading={mainLoading}
                        />
                    </div>
                ) : (
                    <Main />
                )}
            </div>
        );
    }
}

const mapStateToProps = ({oauthReducer}) => ({
    mainLoading: oauthReducer.mainLoading,
});

export default connect(mapStateToProps)(OauthRedirect);
