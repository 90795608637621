import React from 'react';

const SvgIcon = () => (
    <svg
        width="18px"
        height="17px"
        viewBox="0 0 18 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="PLACEMENT-NEW-ICONS" transform="translate(-7.000000, -101.000000)" stroke="#3CD1B5">
                <g id="NAV" transform="translate(-22.000000, 43.000000)">
                    <g id="Assembly" transform="translate(29.000000, 58.000000)">
                        <g
                            id="Group"
                            transform="translate(9.000000, 8.500000) scale(-1, 1)
                            rotate(-180.000000) translate(-9.000000, -8.500000) "
                        >
                            <g
                                id="ASSEMBLY-LIGHT-BLUE"
                                transform="translate(9.000000, 8.358750) scale(-1, 1)
                                rotate(-180.000000) translate(-9.000000, -8.358750) "
                            >
                                <path d="M4.42622951,11.467674 L4.42622951,15.949932" id="Path-7" fillRule="nonzero" />
                                <path d="M11.5081967,4.1754386 L17.1147541,4.1754386" id="Path-6" fillRule="nonzero" />
                                <polygon
                                    id="Path"
                                    fillRule="nonzero"
                                    points="13.7514066 0.894736842 17.1147541
                                    4.18496529 17.1147541 16.1052632 4.51083386
                                    16.1052632 0.885245902 12.5584953 0.885245902
                                    0.894736842"
                                />
                                <g id="Group" fillRule="evenodd" transform="translate(0.590164, 0.596491)">
                                    <path
                                        d="M0.804889565,0.729854087 L3.81648509,3.57894737
                                        L3.81648509,3.57894737 L11.2832263,3.57894737
                                        C11.3384548,3.57894737 11.3832263,3.53417584
                                        11.3832263,3.47894737 C11.3832263,3.45458566
                                        11.3743332,3.43106183 11.3582169,3.41279286
                                        L8.61065205,0.298245614 L8.61065205,0.298245614
                                         L0.976698652,0.298245614 C0.838627465,0.298245614
                                         0.726698652,0.410174427 0.726698652,0.548245614
                                         C0.726698652,0.616954522 0.754977085,0.682634828
                                         0.804889565,0.729854087 Z"
                                        id="Path-2"
                                        fillRule="nonzero"
                                    />
                                    <polygon
                                        id="Path-3"
                                        fillRule="nonzero"
                                        points="3.83606557 3.57894737 11.5003457 3.57894737
                                        11.5003457 10.8867722 3.83606557 10.8867722"
                                    />
                                    <path
                                        d="M0.662789801,1.13583733 L0.662789801,7.67762948
                                        L3.46619837,10.1471705 L3.50056499,3.81282175
                                        L0.662789801,1.13583733 Z"
                                        id="Rectangle"
                                        fillRule="nonzero"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default SvgIcon;
