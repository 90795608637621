import Utils from "../utils";
import serviceCalls                 from "./serviceCalls"

export const signInService = ({payload}, cb) =>
{
    serviceCalls.post("/auth/signin", payload, (err, res) =>
    {
        if (err)
        {
            cb(null, err);
        }
        else
        {
            cb(null, res.data);
        }
    });
}

export const checkDomainExist = (data, cb) =>
    {
        serviceCalls.post("/is-domain-exist", data, (err, res) => {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }


export const getCurrentUser = (cb) =>
{
    try
    {
        serviceCalls.post("/onshape/getUserByToken", {}, (err, res) =>
        {
            if (err)
            {
                cb(null, err);
            }
            else
            {
                cb(null, res.data);
            }
        });
    }
    catch(error)
    {
        cb(error);
    }
};

export const getCompanyConfiguration = (payload, cb) =>
{
    serviceCalls.get(`/onshape/getCompanyConfiguration/${payload}`).then((response) =>
    {
        cb(null, response.data);
    }).catch((error) =>
    {
        cb(error);
    });
}

export const getIsOnshapeCompanyAdmin = (payload, cb) => {
    serviceCalls
        .get(`/onshape/getIsOnshapeCompanyAdmin${Utils.queryStringFromObject(payload)}`)
        .then((response) => cb(null, response.data))
        .catch((error) => cb(error));
};
